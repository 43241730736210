import { useCallback, useEffect, useRef } from 'react';
import { useRequest, useSetState } from 'ahooks';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { App, Button, Flex, Form, FormProps, Input, PaginationProps, Popconfirm, Spin, Typography } from 'antd';
import { ProCard, ProTable, ProTableProps } from '@ant-design/pro-components';
import { get, trim } from 'lodash';

/**
 * Components
 */
import ComponentModalFormCustom from './components/modal-form-custom';
import Permission from '@/components/permission';

/**
 * APIs
 */
import {
    airdropAccListApi,
    deleteAirAccByIdApi,
    queryAirAccByUidApi,
    AirdropAccApiErrorCodeMsg,
} from '@/services/airdrop-account';

/**
 * Types
 */
import type { AirdropAccItem } from '@/services/airdrop-account';

type TableParams = {
    user_id: string;
};

type State = {
    dataSource: AirdropAccItem[];
    entity: AirdropAccItem | null;
    open: boolean;
};

const PagePostAirdropRootAccount: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    const [formSearch] = Form.useForm();

    const searchRef = useRef<HTMLDivElement>(null);

    /**
     * States
     */
    const intl: IntlShape = useIntl();
    const [state, setState] = useSetState<State>({
        dataSource: [],
        entity: null,
        open: false,
    });
    const [cellLoading, setCellLoading] = useSetState<Record<string, boolean>>({});

    /**
     * Paginations
     */
    const [pagination, setPagination] = useSetState<PaginationProps>({
        current: 1,
        pageSize: 20,
        onChange: (page, pageSize) => {
            setPagination({
                current: page,
                pageSize,
            });
        },
    });

    /**
     * Payloads
     */
    const [payload, setPayload] = useSetState<TableParams>({
        user_id: '',
    });

    /**
     * Requests
     */
    const {
        loading: loadingSuperUsersRequest,
        refresh: refreshSuperUsersRequest,
        run: runSuperUsersRequest,
    } = useRequest(
        (): any => {
            if (payload.user_id) {
                return queryAirAccByUidApi(payload.user_id);
            } else {
                return airdropAccListApi({
                    page: pagination.current,
                    limit: pagination.pageSize,
                });
            }
        },
        {
            manual: true,
            onSuccess: (data) => {
                const reps = get(data, 'data', { code: 0, data: {}, msg: '' });
                if (reps.code !== 0) {
                    // 搜索user_id 后端报错，表示没有搜索到用户
                    if (payload.user_id) {
                        setState({
                            dataSource: [],
                        });

                        setPagination({
                            total: 0,
                        });
                        return;
                    }
                    return message.error(reps.msg);
                }
                let total = 0;
                let dataSource = [];

                // 单个user 搜索出来的结果
                if (payload.user_id) {
                    const accInfo = get(reps, 'data');
                    if (accInfo) {
                        total = 1;
                        dataSource.push(accInfo);
                    }
                } else {
                    const respData = get(reps, 'data', { list: [], meta: {} });
                    total = get(respData, 'meta.total', 0);
                    dataSource = get(respData, 'list', []);
                }

                setState({
                    dataSource: dataSource as unknown as AirdropAccItem[],
                });

                setPagination({
                    total: total,
                });
            },
        },
    );

    /**
     * Callback
     */

    const closeModalForm = useCallback((reload: boolean = false) => {
        setState({
            open: false,
            entity: null,
        });
        if (reload) {
            runSuperUsersRequest();
        }
    }, []);

    /**
     * ChildrenProps
     */
    const formSearchProps: FormProps<TableParams> = {
        form: formSearch,
        initialValues: {
            type: 'unassigned',
        },
        layout: 'inline',
        onFinish: (values) => {
            values.user_id = trim(values.user_id);
            setPagination({
                current: 1,
            });
            setPayload({
                ...values,
            });
        },
    };

    const proTableProps: ProTableProps<AirdropAccItem, any> = {
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                title: 'No.',
                width: 50,
                fixed: 'left',
                hideInSearch: true,
                render: (_, __, index) => index + 1,
            },
            {
                dataIndex: 'user_id',
                title: 'UID',
                fieldProps: {
                    placeholder: intl.formatMessage({ id: 'su.f.pleaseInputUID' }),
                    width: 200,
                },
                formItemProps: {
                    label: false,
                    name: 'user_id',
                },
                width: 200,
            },
            {
                dataIndex: '_airdropRange',
                title: <FormattedMessage id="su.t.c.airdropCountRange" />,
                hideInSearch: true,
                render: (_, entity) => {
                    return `${entity.airdrop_min} - ${entity.airdrop_max}`;
                },
            },
            {
                dataIndex: '_periodRange',
                title: <FormattedMessage id="su.t.c.airdropPeriodRange" />,
                hideInSearch: true,
                render: (_, entity) => {
                    return `${entity.period_min} - ${entity.period_max}`;
                },
                width: 200,
            },
            {
                dataIndex: 'operator',
                width: 120,
                title: <FormattedMessage id="t.c.operatedBy" />,
                hideInSearch: true,
            },
            {
                dataIndex: 'updated_at',
                width: 200,
                valueType: 'dateTime',
                title: <FormattedMessage id="t.c.operatedAt" />,
                hideInSearch: true,
            },
            {
                dataIndex: '_menu',
                valueType: 'option',
                width: 200,
                fixed: 'right',
                title: <FormattedMessage id="t.c.action" />,
                hideInSearch: true,
                render: (_, entity) => [
                    <Permission permission="Client-Config_Super-Users_Edit" key="edit">
                        <a
                            key="edit"
                            onClick={() => {
                                setState({
                                    entity,
                                    open: true,
                                });
                            }}
                        >
                            <FormattedMessage id="b.edit" />
                        </a>
                    </Permission>,
                    <Permission permission="Client-Config_Super-Users_Delete" key="delete">
                        <Spin spinning={!!cellLoading[entity.id]} size="small">
                            <Popconfirm
                                key="delete"
                                title={intl.formatMessage({ id: 'c.areyousure' })}
                                onConfirm={() => {
                                    setCellLoading({ [entity.id]: true });
                                    deleteAirAccByIdApi(entity.id)
                                        .then((res) => {
                                            if (get(res, 'data.code') === 0) {
                                                message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
                                                runSuperUsersRequest();
                                            } else {
                                                const errorCodeKey = AirdropAccApiErrorCodeMsg[get(res, 'data.code')];
                                                if (errorCodeKey) {
                                                    message.error(intl.formatMessage({ id: errorCodeKey }));
                                                } else {
                                                    message.error(get(res, 'data.msg', 'Error'));
                                                }
                                            }
                                            setCellLoading({ [entity.id]: false });
                                        })
                                        .catch(() => {
                                            setCellLoading({ [entity.id]: false });
                                        });
                                }}
                            >
                                <Typography.Link type="danger">
                                    <FormattedMessage id="b.delete" />
                                </Typography.Link>
                            </Popconfirm>
                        </Spin>
                    </Permission>,
                ],
            },
        ],
        dataSource: state.dataSource,
        search: false,
        loading: loadingSuperUsersRequest,
        options: {
            reload: refreshSuperUsersRequest,
        },
        pagination,
        toolBarRender: () => [
            <Permission permission="Client-Config_Super-Users_Add" key="add">
                <Button
                    type="primary"
                    onClick={() => {
                        setState({
                            entity: null,
                            open: true,
                        });
                    }}
                >
                    <FormattedMessage id="b.add" />
                </Button>
            </Permission>,
        ],
        rowKey: 'id',
        scroll: {
            y: `calc(100vh - 280px - ${searchRef.current?.clientHeight || 64}px - 8px)`,
            x: 1170,
        },
    };

    /**
     * Effects
     */
    useEffect(() => {
        runSuperUsersRequest();
    }, [pagination.current, pagination.pageSize, payload]);

    return (
        <div>
            <ProCard className="mb-16" ref={searchRef}>
                <Form {...formSearchProps}>
                    <Flex gap={16} wrap={true}>
                        <Form.Item name="user_id" style={{ marginRight: 0 }}>
                            <Input allowClear={true} placeholder="UID" style={{ width: 200 }} />
                        </Form.Item>

                        <Button
                            loading={loadingSuperUsersRequest}
                            type="primary"
                            onClick={() => {
                                formSearch.submit();
                            }}
                        >
                            <FormattedMessage id="b.search" />
                        </Button>

                        <Button
                            onClick={() => {
                                formSearch.resetFields();
                            }}
                        >
                            <FormattedMessage id="b.reset" />
                        </Button>
                    </Flex>
                </Form>
            </ProCard>
            <ProTable {...proTableProps} />

            <ComponentModalFormCustom open={state.open} entity={state.entity} closeModelForm={closeModalForm} />
        </div>
    );
};

export default PagePostAirdropRootAccount;
