import axios from './index';
// import localAxios from 'axios';

// const axios = localAxios.create({
//     baseURL: 'http://127.0.0.1:4523/m1/4939924-0-default',
// });

/**
 * APIs
 */
// 获取辩论直播列表
export const gLive = (params: LiveRoomParams) => {
    return axios<PageResType<LiveRoomResult>>('/v1/live_rooms', {
        method: 'get',
        params,
    });
};

// 删除辩论直播
export const dLive = (data: DeleteLiveRoomParams) => {
    return axios<PageResType<null>>('/v1/live_rooms/', {
        method: 'delete',
        data,
    });
};

// 关闭辩论直播
export const closeLive = (data: DeleteLiveRoomParams) => {
    return axios<PageResType<null>>('/v1/live_rooms/close', {
        method: 'put',
        data,
    });
};

/**
 * Types
 */
import type { PageResType } from '@repo/typings/common';
import type { IdType } from './creator-academy';

/**
 * 直播间，直播间
 */
export type LiveRoomResult = {
    /**
     * 操作时间
     */
    admin_updated_at?: string;
    /**
     * 反方座位总数量
     */
    against_seat_count: number;
    /**
     * 反方坐席人数
     */
    against_seats: number;
    /**
     * 反方观点
     */
    against_text: string;
    /**
     * 赛制 1:1v1 2:2v2 3:3v3 4:4v4
     */
    business_type: number;
    /**
     * 封面图
     */
    cover: string;
    /**
     * 创建时间
     */
    created_at: string;
    /**
     * 删除时间
     */
    deleted_at?: string;
    /**
     * 直播结束时间
     */
    end_at: string;
    /**
     * 正方座位总数量
     */
    for_seat_count: number;
    /**
     * 正方坐席人数
     */
    for_seats: number;
    /**
     * 正方观点
     */
    for_text: string;
    /**
     * 操作人
     */
    operator_account?: OperatorAccount;
    /**
     * 房间编号
     */
    room_id: number;
    /**
     * 房间号
     */
    room_no: string;
    /**
     * 直播开始时间
     */
    start_at: string;
    /**
     * 辩题
     */
    title: string;
    /**
     * 直播类型： DEBATE
     */
    type: string;
    /**
     * 更新时间
     */
    updated_at: string;
    /**
     * 观众数量
     */
    user_count: number;
    /**
     * 主持人编号
     */
    user_id: number;
    /**
     * 版本号
     */
    version: number;
    /**
     * 反对数量
     */
    vote_against_count: number;
    /**
     * 赞成数量
     */
    vote_for_count: number;

    status: 'pending' | 'live' | 'end';
};

export type LiveRoomParams = {
    /**
     * 发布结束时间
     */
    create_end?: string;
    /**
     * 发布开始时间
     */
    create_start?: string;
    /**
     * 是否删除
     */
    is_deleted?: 'true' | 'false';
    limit?: number;
    /**
     * 操作人后台账号 ID
     */
    operator_account_id?: string;
    order_by?: string;
    order_sort?: string;
    page?: number;
    /**
     * 房间 ID
     */
    room_id?: string;
    /**
     * 话题
     */
    title?: string;
    /**
     * 操作时间
     */
    updated_end?: string;
    /**
     * 操作时间
     */
    updated_start?: string;
    /**
     * 创建者 UID
     */
    user_id?: string;

    create_date?: any[];
    operation_date?: any[];
    fe_is_deleted?: string;
};

export type DeleteLiveRoomParams = {
    /**
     * 直播间 ID
     */
    room_id: IdType;
    /**
     * 用户 ID
     */
    user_id: IdType;
};
/**
 * 操作人
 *
 * OperatorAccount
 */
export interface OperatorAccount {
    /**
     * 创建时间
     */
    created_at: Date;
    /**
     * 删除时间
     */
    deleted_at?: Date | null;
    /**
     * ID
     */
    id: string;
    /**
     * 账号名
     */
    name: string;
    /**
     * 更新时间
     */
    updated_at: Date;
}

/**
 * POST
 */
export type PostResult = {
    /**
     * 所有评论的总数量
     */
    all_comment_count: string;
    content: PostContent;
    created_at: string;
    /**
     * 删除时间，判断删除状态
     */
    deleted_at?: string;
    /**
     * 不喜欢的数量
     */
    dislike_count: string;
    ext: { [key: string]: any };
    /**
     * 收藏次数
     */
    favorite_count: string;
    /**
     * '文件位掩码:1=图片, 2=视频, 4=音频',
     */
    file_media: string;
    finish_at: string;
    /**
     * 转发次数
     */
    forward_count: string;
    labels: { [key: string]: any };
    /**
     * 点赞数量
     */
    like_count: string;
    /**
     * 帖子 ID
     */
    post_id: string;
    /**
     * DEBATE | NORMAL | FORMAL_DEBATE | POST_TYPE | NORMAKL
     */
    post_type: string;
    /**
     * 引用总数
     */
    quote_count: string;
    /**
     * 引用帖子ID
     */
    quote_post_id: string;
    /**
     * 引用帖子的创建用户
     */
    quote_user_id: string;
    /**
     * 回帖数量
     */
    repost_count: string;
    /**
     * 根评论的数量
     */
    root_comment_count: string;
    /**
     * 分享次数
     */
    share_count: string;
    /**
     * 帖子来源，默认为用户发布,
     */
    source: string;
    /**
     * 状态，OK | FORMAL_DEBATE_EXPIRED | FORMAL_DEBATE_FINISHED
     */
    status: string;
    updated_at: string;
    /**
     * 用户 ID
     */
    user_id: string;
    /**
     * 版本号
     */
    version: string;
};

/**
 * POST_CONTENT
 */
export interface PostContent {
    body?: string;
    debate?: Debate;
    filePlatform?: string;
    formal_debate?: FormalDebate;
    title?: string;
    /**
     * 上传的物料，video/* | image/*
     */
    uploaded_materials?: string[];
    audio?: Audio;
}

export interface Debate {
    /**
     * 反方
     */
    against_text: string;
    /**
     * 过期时间
     */
    expired_at: number;
    /**
     * 正方
     */
    for_text: string;
    title: string;
}

export interface FormalDebate {
    con_text: string;
    debaters_per_side: number;
    end_at: number;
    pro_text: string;
    start_at: number;
}

export type Audio = {
    /**
     * 时长，160
     */
    audio_duration?: string;
    text?: string;
    /**
     * audio/79639217504129071/2024/10/11/u9CsyMgWXKbmYReJGFoRc3.mp3
     */
    url: string;
};

export type PostParams = {
    /**
     * 正文
     */
    content?: string;
    /**
     * 发布结束时间
     */
    create_end?: string;
    /**
     * 发布开始时间
     */
    create_start?: string;
    /**
     * 辩题
     */
    debate_title?: string;
    /**
     * 是否删除
     */
    is_deleted?: 'true' | 'false';
    limit?: number;
    order_by?: string;
    order_sort?: string;
    page?: number;
    /**
     * 帖子ID
     */
    post_id?: string;
    /**
     * 类型
     */
    types?: any[];
    /**
     * 发布者UID
     */
    user_id?: string;
    /**
     * 操作开始时间
     */
    updated_start?: string;
    /**
     * 操作结束时间
     */
    updated_end?: string;
};

export type FePostParams = PostParams & {
    create_date?: any;
    fe_is_deleted?: string;
    operation_date?: any;
};

export type DeletePostParams = {
    user_id: IdType;
    post_id: IdType;
};

/**
 * 帖子评论
 */
export type PostRepliesResult = PostResult & {
    comment_id: IdType;
};

export type DeletePostRepliesParams = {
    user_id: IdType;
    comment_id: IdType;
    post_id: IdType;
};
