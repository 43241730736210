import React from 'react';
import { Space, Image } from 'antd';

/**
 * Components
 */
import VideoPlayer from '@repo/ui/video-player';

/**
 * Constants
 */
const WEB2_ASSETS_URL = import.meta.env.VITE_WEB2_ASSETS || '';

/**
 * Props
 */
type Props = {
    list: string[];
};

const ComponentTableCellMedia: React.FC<Props> = ({ list }) => {
    if (!list) {
        return '-';
    }
    const children: React.ReactNode[] = [];
    list.forEach((src: string, index: number) => {
        if (!src) {
            return;
        }
        if (src.startsWith('video')) {
            children.push(<VideoPlayer src={`${WEB2_ASSETS_URL}/${src}`} key={index} />);
        } else if (src.startsWith('image')) {
            children.push(<Image src={`${WEB2_ASSETS_URL}/${src}`} width={80} key={index} />);
        }
    });
    if (!children.length) {
        return '-';
    }
    return <Space wrap={true}>{children}</Space>;
};

export default ComponentTableCellMedia;
