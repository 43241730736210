import axios from './index';

/**
 * APIs
 */
// table list
export const userListApi = (query: Record<string, any>) => {
    return axios<PageResDataType<User>>('/v2/users', {
        method: 'get',
        params: query,
    });
};

export const userDetailApi = (uid: string) => {
    return axios<ResType<User>>(`/v2/users/${uid}`, {
        method: 'get',
    });
};

// 导出用户数据列表
export const userExport = (params: UserDatasExportParams) => {
    return axios('/v2/users/export/async', {
        method: 'get',
        params,
    });
};

// 获取用户限制状态
export const userTnsApi = (user_id: string) => {
    return axios<ResType<UserTns>>(`/v1/tns/users/${user_id}`, {
        method: 'get',
    });
};

// 获取用户操作记录
export const userTnsLogs = (params: UserTnsLogsReq) => {
    return axios<PageResType<UserTnsLogs>>('/v1/tns/users/logs', {
        method: 'get',
        params,
    });
};

// 禁言接口
export const userMute = (user_id: string, data: UserTnsAction) => {
    return axios(`/v1/tns/users/${user_id}/mute`, {
        method: 'put',
        data,
    });
};

// 封号接口
export const userBan = (user_id: string, data: UserTnsAction) => {
    return axios(`/v1/tns/users/${user_id}/ban`, {
        method: 'put',
        data,
    });
};

// 解除禁言接口
export const userUnmute = (user_id: string, data: UserTnsAction) => {
    return axios(`/v1/tns/users/${user_id}/unmute`, {
        method: 'put',
        data,
    });
};

// 解除封号接口
export const userUnban = (user_id: string, data: UserTnsAction) => {
    return axios(`/v1/tns/users/${user_id}/unban`, {
        method: 'put',
        data,
    });
};

// 重置用户个人资料
export const userProfileReset = (user_id: string, data: UserProfileResetParams) => {
    return axios(`/v1/tns/users/${user_id}/reset`, {
        method: 'put',
        data,
    });
};

// 获取用户身份验证历史
export const userIdentityVerificationHistory = (params: UserIdentityVerificationHistoryParams) => {
    return axios<PageResType<UserIdentityVerificationHistory>>('/v1/users/verification', {
        method: 'get',
        params,
    });
};

// 导出用户身份验证历史
export const userIdentityVerificationHistoryExport = (params: UserIdentityVerificationHistoryParams) => {
    return axios('/v1/users/verification/export', {
        method: 'get',
        params,
    });
};

/**
 * Types
 */
import type { PageResDataType, PageResType, ResType } from '@repo/typings/common';

export type UserInfoAccount = {
    area_code: string;
    city: string;
    country: string;
    country_code: string;
    email: string;
    invitation_code: string;
    mobile: string;
    register_by: string;
};

export type UserPost = {
    debate_count: string;
    normal_count: string;
    total_count: string;
};

export type UserRelation = {
    block_count: string;
    following_count: string;
    follower_count: string;
    friend_count: string;
    inviter_id: string;
};

// user item
export type User = {
    id: string;
    avatar: string;
    avatar_is_nft: boolean;
    bio: string;
    gender: string;
    language: string;
    nickname: string;
    username: string;
    name?: string;
    created_at: string;
    updated_at: string;
    is_banned: boolean;
    is_muted: boolean;
    time_unban: number;
    time_unmute: number;
    info_account: UserInfoAccount;
    stat_post: UserPost;
    stat_relation: UserRelation;
    deleted_at: string | null;
};

export type UserListParams = {
    id?: string;
    username?: string;
    nickname?: string;
    inviter_id?: string;
    created_start?: string;
    created_end?: string;
    page?: number;
    limit?: number;
};

export type UserDatasExportParams = {
    id?: string;
    username?: string;
    nickname?: string;
    inviter_id?: string;
    created_start?: string;
    created_end?: string;
};

export type UserQuery = {
    uid?: string;
    name?: string;
};

/**
 *
 */
export type UserTnsOperator = {
    name: string;
    id: string;
    created_at: string;
    deleted_at: string;
    updated_at: string;
};

/**
 * 用户封禁信息
 */
export type UserTns = {
    id: string;
    created_at?: string;
    updated_at?: string;
    user_id: string;
    mute_status: boolean;
    unmute_at?: string;
    ban_status: boolean;
    unban_at?: string;
};

/**
 * 用户封禁记录
 */
export type UserTnsLogs = {
    id: string;
    user_id: string;
    type: UserAction;
    period: number;
    period_unit: 'minute' | 'hour' | 'day';
    remark: string;
    operator_account?: UserTnsOperator;
    created_at: string;
    updated_at: string;
    userInfo: User;
    content: string[];
};

/**
 * 用户封禁记录查询条件
 */
export type UserTnsLogsReq = {
    page?: number;
    limit?: number;
    order_by?: string;
    order_sort?: string;
    user_id?: string;
    type?: UserAction;
    types?: UserAction[];
    operator_account_id?: string;
    operation_start?: string;
    operation_end?: string;
};

/**
 * 禁言/封号接口
 */
export type UserTnsAction = {
    period?: number;
    period_unit?: 'minute' | 'hour' | 'day';
    remark: string;
};

// user action
export type UserAction = 'temp_mute' | 'perma_mute' | 'temp_ban' | 'perma_ban' | 'unmute' | 'unban' | 'profile';

export type UserIdentityVerificationStatus = 'Verified' | 'Unverified';

export type UserIdentityVerificationHistoryParams = {
    page?: number;
    limit?: number;
    uid?: string;
    username?: string;
    nickname?: string;
    identity_status?: UserIdentityVerificationStatus;
    liveness2_status?: UserIdentityVerificationStatus;
    liveness_start?: string;
    liveness_end?: string;
    identity_start?: string;
    identity_end?: string;
    liveness2_start?: string;
    liveness2_end?: string;
};

export type UserIdentityVerificationHistory = {
    uid: string;
    user_info: {
        id: string;
        username: string;
        nickname: string;
    };
    type: string;
    status: string;
    identity_status: UserIdentityVerificationStatus;
    identity_at: string;
    liveness_at: string;
    liveness2_status: UserIdentityVerificationStatus;
    liveness2_at: string;
};

export enum UserActionType {
    TEMP_MUTE = 'temp_mute',
    PERMA_MUTE = 'perma_mute',
    TEMP_BAN = 'temp_ban',
    PERMA_BAN = 'perma_ban',
    UNMUTE = 'unmute',
    UNBAN = 'unban',
}

export enum UserActionName {
    TEMP_MUTE = '临时禁言',
    PERMA_MUTE = '永久禁言',
    TEMP_BAN = '临时封号',
    PERMA_BAN = '永久封号',
    UNMUTE = '解除禁言',
    UNBAN = '解除封号',
}

export const userActionOptions = [
    {
        label: UserActionName.TEMP_MUTE,
        value: UserActionType.TEMP_MUTE,
    },
    {
        label: UserActionName.PERMA_MUTE,
        value: UserActionType.PERMA_MUTE,
    },
    {
        label: UserActionName.TEMP_BAN,
        value: UserActionType.TEMP_BAN,
    },
    {
        label: UserActionName.PERMA_BAN,
        value: UserActionType.PERMA_BAN,
    },
    {
        label: UserActionName.UNMUTE,
        value: UserActionType.UNMUTE,
    },
    {
        label: UserActionName.UNBAN,
        value: UserActionType.UNBAN,
    },
];

export type UserProfileResetParams = {
    items: UserPrefileResetItem[];
    remark?: string;
};

export enum UserPrefileResetItem {
    Avatar = 'avatar',
    Bio = 'bio',
    Name = 'name',
    Nickname = 'nickname',
}
