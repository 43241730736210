import { ProCard, ProTable } from '@ant-design/pro-components';
import { useCountDown, useRequest, useSetState } from 'ahooks';
import { App, Button, DatePicker, Drawer, Flex, Form, Select, Typography } from 'antd';
import { get, isEmpty, omitBy } from 'lodash';
import { useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import SearchUser from '@/components/search-user';

/**
 * APIs
 */
import { ambassadorApplicationsRecords, ambassadorApplicationsRecordsExport } from '@/services/ambassadors';
import { operators } from '@/services/operator';

/**
 * Components
 */
import ComponentDetail from './components/detail';

/**
 * Types
 */
import type { ActionType, ProTableProps } from '@ant-design/pro-components';
import type { DrawerProps, FormProps, PaginationProps } from 'antd';
import type { AmbassadorApplicationsParams, AmbassadorApplicationsResult } from '@/services/ambassadors';
import type { OperatorsResponse } from '@/services/operator';

type State = {
    dataSource: AmbassadorApplicationsResult[];
    entity: AmbassadorApplicationsResult | null;
    openDetail: boolean;
    operators: OperatorsResponse[];
    target: number;
};

const PageAmbassadorsApplicationsReviewRecords: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    const [formSearch] = Form.useForm();

    const { formatMessage } = useIntl();

    const searchRef = useRef<HTMLDivElement>(null);

    const tableRef = useRef<ActionType>();

    /**
     * States
     */
    const [state, setState] = useSetState<State>({
        dataSource: [],
        entity: null,
        openDetail: false,
        operators: [],
        target: 0,
    });

    const [countdown] = useCountDown({
        targetDate: state.target,
    });

    /**
     * Paginations
     */
    const [pagination, setPagination] = useSetState<PaginationProps>({
        current: 1,
        pageSize: 20,
        onChange: (page, pageSize) => {
            setPagination({
                current: page,
                pageSize,
            });
        },
    });

    /**
     * Payloads
     */
    const [payload, setPayload] = useSetState<AmbassadorApplicationsParams>({
        statuses: ['approved', 'rejected'],
    });

    /**
     * Requests
     */
    useRequest(operators, {
        defaultParams: [{ limit: 9999, model: '1', page: 1 }],
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setState({
                operators: data.list,
            });
        },
    });

    const {
        loading: loadingAmbassadorApplications,
        refresh: refreshAmbassadorApplications,
        run: runAmbassadorApplications,
    } = useRequest(ambassadorApplicationsRecords, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setPagination({
                current: data.meta.currentPage,
                pageSize: data.meta.perPage,
                total: data.meta.total,
            });

            setState({
                dataSource: data.list,
            });
        },
    });

    /**
     * ChildrenProps
     */
    const drawerDetailProps: DrawerProps = {
        maskClosable: false,
        open: state.openDetail,
        size: 'large',
        title: <FormattedMessage id="b.details" />,
        onClose: () => {
            setState({
                openDetail: false,
            });
        },
    };

    const formSearchProps: FormProps = {
        form: formSearch,
        layout: 'inline',
        onFinish: (values) => {
            if (values.operation_date) {
                values.operation_end = values.operation_date[1].endOf('d').format();
                values.operation_start = values.operation_date[0].startOf('d').format();
                values.operation_date = undefined;
            }

            values.statuses = values.statuses ? [values.statuses] : ['approved', 'rejected'];

            setPagination({
                current: 1,
                pageSize: 20,
            });

            setPayload({
                account_id: undefined,
                apply_end: undefined,
                apply_start: undefined,
                operation_end: undefined,
                operation_start: undefined,
                operator_account_id: undefined,
                order_by: undefined,
                order_sort: undefined,
                parent_user_id: undefined,
                request_id: undefined,
                statuses: ['approved', 'rejected'],
                types: undefined,
                nickname: undefined,
                user_id: undefined,
                username: undefined,

                ...omitBy(values, isEmpty),
            });
        },
    };

    const proTableProps: ProTableProps<AmbassadorApplicationsResult, any> = {
        actionRef: tableRef,
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                fixed: 'left',
                title: 'No.',
                valueType: 'index',
                width: 50,
            },
            {
                dataIndex: 'user_id',
                title: <FormattedMessage id="t.c.user" />,
                width: 200,
                render: (_, entity) => {
                    const { name = '-', nickname = '-' } = entity.user_info || {};

                    return (
                        <>
                            <div>{nickname}</div>

                            <Typography.Text copyable={{ text: entity.user_id, tooltips: 'Copy UID' }}>
                                @{name}
                            </Typography.Text>
                        </>
                    );
                },
            },
            {
                dataIndex: '1',
                ellipsis: true,
                title: <FormattedMessage id="t.c.fullName" />,
                width: 200,
                render: (_, entity) => {
                    const { first_name = '', last_name = '' } = get(entity, 'request.ambassador_info', {});

                    return `${first_name} ${last_name}`;
                },
            },
            {
                dataIndex: ['request', 'email'],
                ellipsis: true,
                title: <FormattedMessage id="t.c.email" />,
                width: 200,
            },
            {
                dataIndex: ['request', 'social_media_presence', 'link'],
                ellipsis: true,
                title: <FormattedMessage id="t.c.link" />,
                width: 200,
                renderText: (text) => {
                    if (!text) return '-';
                    return (
                        <a href={text?.startsWith('http') ? text : `http://${text}`} target="_blank">
                            {text}
                        </a>
                    );
                },
            },
            {
                dataIndex: ['request', 'social_media_presence', 'fan_count'],
                title: <FormattedMessage id="p.ambassadors.t.c.followers" />,
                width: 120,
            },
            {
                dataIndex: 'status',
                title: <FormattedMessage id="p.ambassadors.t.c.reviewResult" />,
                valueEnum: {
                    pending: <FormattedMessage id="p.ambassadors.t.c.pending" />,
                    approved: <FormattedMessage id="b.approve" />,
                    rejected: <FormattedMessage id="b.reject" />,
                },
                width: 120,
            },
            {
                dataIndex: 'reject_reason',
                title: <FormattedMessage id="p.ambassadors.privatePool.rejectReason" />,
            },
            {
                dataIndex: ['operator_account', 'name'],
                ellipsis: true,
                title: <FormattedMessage id="t.c.operatedBy" />,
                width: 120,
            },
            {
                dataIndex: 'updated_at',
                title: <FormattedMessage id="t.c.operatedAt" />,
                valueType: 'dateTime',
                width: 200,
            },
            {
                fixed: 'right',
                key: 'option',
                title: <FormattedMessage id="t.c.action" />,
                valueType: 'option',
                width: 200,
                render: (_, entity) => [
                    <a
                        key="detail"
                        onClick={() => {
                            setState({
                                entity,
                                openDetail: true,
                            });
                        }}
                    >
                        <FormattedMessage id="b.details" />
                    </a>,
                ],
            },
        ],
        dataSource: state.dataSource,
        loading: loadingAmbassadorApplications,
        options: {
            reload: refreshAmbassadorApplications,
        },
        pagination,
        rowKey: 'id',
        scroll: {
            x: 1810,
            y: `calc(100vh - 224px - ${searchRef.current?.clientHeight || 64}px - 16px - 48px)`,
        },
        search: false,
    };

    /**
     * Effects
     */
    useEffect(() => {
        runAmbassadorApplications({
            ...payload,
            limit: pagination.pageSize,
            page: pagination.current,
        });
    }, [pagination.current, pagination.pageSize, payload]);

    return (
        <>
            <ProCard className="mb-16" ref={searchRef}>
                <Form {...formSearchProps}>
                    <Flex gap={16} wrap={true}>
                        <SearchUser form={formSearch} />

                        <Form.Item name="statuses" style={{ marginRight: 0 }}>
                            <Select
                                allowClear={true}
                                optionFilterProp="label"
                                options={[
                                    {
                                        label: <FormattedMessage id="b.approve" />,
                                        value: 'approved',
                                    },
                                    {
                                        label: <FormattedMessage id="b.reject" />,
                                        value: 'rejected',
                                    },
                                ]}
                                placeholder={<FormattedMessage id="p.ambassadors.t.c.reviewResult" />}
                                showSearch={true}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="operator_account_id" style={{ marginRight: 0 }}>
                            <Select
                                allowClear={true}
                                optionFilterProp="label"
                                options={state.operators.map((operator) => ({
                                    label: operator.account.name,
                                    value: operator.account.id,
                                }))}
                                placeholder={<FormattedMessage id="t.c.operatedBy" />}
                                showSearch={true}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item
                            label={formatMessage({ id: 't.c.operatedAt' })}
                            name="operation_date"
                            style={{ marginRight: 0 }}
                        >
                            <DatePicker.RangePicker allowClear={true} />
                        </Form.Item>

                        <Button
                            loading={loadingAmbassadorApplications}
                            type="primary"
                            onClick={() => {
                                formSearch.submit();
                            }}
                        >
                            <FormattedMessage id="b.search" />
                        </Button>

                        <Button
                            onClick={() => {
                                formSearch.resetFields();
                            }}
                        >
                            <FormattedMessage id="b.reset" />
                        </Button>

                        <Button
                            disabled={countdown !== 0}
                            onClick={() => {
                                formSearch.validateFields().then(async (values) => {
                                    if (values.operation_date) {
                                        values.operation_end = values.operation_date[1].endOf('d').format();
                                        values.operation_start = values.operation_date[0].startOf('d').format();
                                        values.operation_date = undefined;
                                    }

                                    values.statuses = values.statuses ? [values.statuses] : ['approved', 'rejected'];

                                    const { data } = await ambassadorApplicationsRecordsExport(omitBy(values, isEmpty));

                                    if (data.code !== 0) {
                                        return message.error(data.msg);
                                    }
                                    message.success(formatMessage({ id: 'm.exportTips' }));

                                    setState({
                                        target: Date.now() + 1000 * 10,
                                    });
                                });
                            }}
                        >
                            <FormattedMessage id="b.export" />
                            {countdown === 0 ? null : `(${Math.round(countdown / 1000)}s)`}
                        </Button>
                    </Flex>
                </Form>
            </ProCard>

            <ProTable {...proTableProps} />

            <Drawer {...drawerDetailProps}>
                <ComponentDetail dataSource={state.entity!} />
            </Drawer>
        </>
    );
};

export default PageAmbassadorsApplicationsReviewRecords;
