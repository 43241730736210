import axios from './index';

/**
 * APIs
 */
// 获取短视频列表
export const shorts = (params: ShortsParams) => {
    return axios<PageResDataType<ShortsItem>>('/ps/v1/shorts', {
        method: 'get',
        params,
    });
};

// 删除短视频
export const dShort = (data: DeleteShortParams) => {
    return axios<PageResType<ShortsItem>>('/v1/shorts', {
        method: 'delete',
        data,
    });
};

// 批量删除短视频
export const batchDeleteShort = (data: BatchDeleteShortParams) => {
    return axios<PageResType<ShortsItem>>('/v1/shorts/batch', {
        method: 'delete',
        data,
    });
};

// 获取短视频评论列表
export const gShortsReplies = (params: FeShortsRepliesParams) => {
    return axios<PageResType<ShortsRepliesResult>>('/v1/shorts/comments', {
        method: 'get',
        params,
    });
};

// 删除短视频评论
export const dShortReplies = (data: DeleteShortRepliesParams) => {
    return axios<PageResType<ShortsItem>>('/v1/shorts/comments', {
        method: 'delete',
        data,
    });
};

// 批量删除短视频评论
export const batchDeleteShortReplies = (data: BatchDeleteShortRepliesParams) => {
    return axios<PageResType<ShortsItem>>('/v1/shorts/comments/batch', {
        method: 'delete',
        data,
    });
};

/**
 * Types
 */
import type { PageResDataType, PageResType } from '@repo/typings/common';
import type { IdType } from './creator-academy';

/**
 * 短视频，短视频表
 */
export type ShortsItem = {
    id: IdType;
    /**
     * 火山云视频编号
     */
    byte_video_id?: string;
    /**
     * 收藏数量
     */
    collect_count: number;
    /**
     * 评论数量
     */
    comment_count: number;
    /**
     * 封面
     */
    cover: string;
    /**
     * 创建时间
     */
    created_at: string;
    /**
     * 删除时间，有表示已删除
     */
    deleted_at?: string;
    /**
     * 视频内容描述
     */
    description: string;
    /**
     * 高度
     */
    height: number;
    /**
     * 大尺寸视频地址
     */
    large_url: string;
    /**
     * 评分人数
     */
    rating_count: number;
    /**
     * 总评分
     */
    score: number;
    /**
     * 分享数量
     */
    share_count: number;
    /**
     * 小尺寸视频地址
     */
    small_url: string;
    /**
     * 视频原地址
     */
    source_url: string;
    /**
     * 视频状态
     */
    status: number;
    /**
     * 视频类型
     */
    type: number;
    /**
     * 更新时间
     */
    updated_at: string;
    /**
     * 用户编号
     */
    user_id: string;
    /**
     * 版本号
     */
    version: number;
    /**
     * 视频编号
     */
    video_id: string;
    /**
     * 宽度
     */
    width: number;
};

/**
 * 分页
 */
export interface Meta {
    /**
     * 当前页数
     */
    currentPage: number;
    /**
     * 最后一页
     */
    lastPage: number;
    /**
     * 下一页
     */
    next: number;
    /**
     * 每页条数
     */
    perPage: number;
    /**
     * 上一页
     */
    prev: number;
    /**
     * 总记录数
     */
    total: number;
}

export type ShortsParams = {
    /**
     * 字节视频 ID
     */
    byte_id?: string;
    /**
     * 创建时间结束
     */
    created_end?: string;
    /**
     * 创建时间开始
     */
    created_start?: string;
    /**
     * 辩题（模糊匹配，标题文本、正方文本、反方文本）
     */
    debate_fuzzy?: string;
    /**
     * 正文（模糊匹配）
     */
    description_fuzzy?: string;
    /**
     * 短视频 ID
     */
    id?: string;
    /**
     * 是否为辩论
     */
    is_debate?: string;
    /**
     * 是否被删除
     */
    is_deleted?: string;
    /**
     * 操作人 ID
     */
    operator_id?: string;
    /**
     * 操作人名称
     */
    operator_name?: string;
    /**
     * 操作人角色
     */
    operator_role?: string;
    /**
     * 游标
     */
    search_after?: string;
    /**
     * 每页条数
     */
    size?: string;
    /**
     * 排序
     */
    'sort[0][created_at]'?: string;
    /**
     * 状态
     */
    status?: string;
    /**
     * 类型
     */
    type?: string;
    /**
     * 更新时间开始
     */
    'updated_at[0]'?: string;
    /**
     * 更新时间结束
     */
    'updated_at[1]'?: string;

    'deleted_at[0]'?: string;

    'deleted_at[1]'?: string;
    /**
     * 用户 ID
     */
    user_id?: string;

    fe_created_date?: any;
    fe_updated_date?: any;
    fe_is_deleted?: any;
};

export type DeleteShortParams = {
    user_id: IdType;
    video_id: IdType;
};

/**
 * 短视频评论
 */

export type ShortsRepliesResult = ShortsItem & {
    comment_id: IdType;
};

export type FeShortsRepliesParams = {
    create_end?: string;
    create_start?: string;
    /**
     * 正文搜索
     */
    description?: string;
    /**
     * 是否删除
     */
    is_deleted?: 'true' | 'false';
    /**
     * 每页条数
     */
    limit?: string;
    order_by?: string;
    order_sort?: string;
    page?: string;
    user_id?: string;
    video_id?: string;
    updated_start?: string;
    updated_end?: string;
    title?: string;

    create_date?: any;
    operation_date?: any;
    fe_is_deleted?: string;
    _types?: string;
    types?: string[];
};

export type DeleteShortRepliesParams = {
    user_id: IdType;
    comment_id: IdType;
    video_id: IdType;
};

export type BatchDeleteShortParams = {
    videos: {
        /**
         * 用户 ID
         */
        user_id: string;
        /**
         * 帖子 ID
         */
        video_id: string;
    }[];
};

export type BatchDeleteShortRepliesParams = {
    comments: {
        /**
         * 评论 ID
         */
        comment_id: string;
        /**
         * 用户 ID
         */
        user_id: string;
        /**
         * 短视频 ID
         */
        video_id: string;
        [property: string]: any;
    }[];
};
