export default {
    cp: {
        t: {
            c: {
                publishAt: '发布时间',
                shortsId: '短视频ID',
            },
        },
        f: {
            type: '类型',
            deleted: '已删除',
            debateTopic: '辩论',
            video: '视频',
            audio: '音频',
            nft: 'NFT',
            image: '图片',
            videoCover: '视频封面',
        },
    },
};
