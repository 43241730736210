import { useEffect, useRef, useState } from 'react';
import { useRequest, useSetState } from 'ahooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { App, Button, DatePicker, Flex, Form, Input, Modal, Popconfirm, Select, Spin, Typography } from 'antd';
import { ProCard, ProTable } from '@ant-design/pro-components';
import dayjs from 'dayjs';
import { get, isEmpty, omitBy, trim } from 'lodash';

/**
 * Components
 */
import TableCellMedia from '@/components/table-cell/table-cell-media';
import ComponentTableCellAudio from '@/components/table-cell/table-cell-audio';
import ComponentSearchFormSelectOperator from '@/components/search-form-select-operator';
import Permission from '@/components/permission';

/**
 * APIs
 */
import { batchDeletePostReplies, dPostReplies, gPostsReplies } from '@/services/posts';

/**
 * Types
 */
import type { FormProps, PaginationProps } from 'antd';
import type { ActionType, ProTableProps } from '@ant-design/pro-components';
import type { FePostParams, PostParams, PostRepliesResult } from '@/services/posts';

// table 批量选择
type SeletRowState = {
    selects: string[];
    selectedRows: any[];
};
/**
 * Constants
 */
const getDefaultTodayDateTime = () => {
    const today = dayjs();
    return [today.startOf('d'), today.endOf('d')];
};

/**
 * State
 */
type State = {
    dataSource: PostRepliesResult[];
};

const PageContentPostsReplies: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    const [formSearch] = Form.useForm();

    const { formatMessage } = useIntl();

    const searchRef = useRef<HTMLDivElement>(null);

    const tableRef = useRef<ActionType>();

    const intl = useIntl();

    /**
     * States
     */
    const [state, setState] = useSetState<State>({
        dataSource: [],
    });

    const [tableCellViolateLoading, setTableCellViolateLoading] = useSetState<Record<string, boolean>>({});

    const [selectState, setSelectState] = useSetState<SeletRowState>({
        selects: [],
        selectedRows: [],
    });

    /**
     * Paginations
     */
    const [pagination, setPagination] = useSetState<PaginationProps>({
        current: 1,
        pageSize: 20,
        onChange: (page, pageSize) => {
            setPagination({
                current: page,
                pageSize,
            });
        },
    });

    /**
     * Payloads
     */
    const [payload, setPayload] = useState<PostParams>({
        create_start: getDefaultTodayDateTime()[0].format(),
        create_end: getDefaultTodayDateTime()[1].format(),
    });

    /**
     * Request
     */
    const { run: runBatchDelete, loading: loadingBatchDelete } = useRequest(batchDeletePostReplies, {
        manual: true,
        onSuccess: ({ data: { code, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            refreshPostsReplies();
        },
    });

    const {
        loading: loadingPostsReplies,
        refresh: refreshPostsReplies,
        run: runPostsReplies,
    } = useRequest(gPostsReplies, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setState({
                dataSource: data.list,
            });

            setSelectState({
                selects: [],
                selectedRows: [],
            });

            setPagination({
                total: data.meta.total,
            });
        },
    });
    /**
     * ChildrenProps
     */

    const formSearchProps: FormProps<FePostParams> = {
        form: formSearch,
        layout: 'inline',
        onFinish: (values) => {
            if (values.create_date) {
                values.create_start = values.create_date[0].format();
                values.create_end = values.create_date[1].format();
                values.create_date = undefined;
            }

            if (values.operation_date) {
                values.updated_start = values.operation_date[0].startOf('d').format();
                values.updated_end = values.operation_date[1].endOf('d').format();
                values.operation_date = undefined;
            }

            if (values.types) {
                values.types = [values.types];
            }

            if (values.fe_is_deleted) {
                values.is_deleted = values.fe_is_deleted === 'normal' ? 'false' : 'true';
            } else {
                values.is_deleted = undefined;
            }
            if (values.content) {
                values.content = trim(values.content);
            }
            if (values.debate_title) {
                values.debate_title = trim(values.debate_title);
            }
            values.fe_is_deleted = undefined;
            setPagination({
                current: 1,
            });
            setPayload({
                ...omitBy(values, isEmpty),
            });
        },
    };

    const proTableProps: ProTableProps<PostRepliesResult, any> = {
        actionRef: tableRef,
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                fixed: 'left',
                title: 'No.',
                width: 50,
                render: (_, __, index) => index + 1,
            },
            {
                dataIndex: 'comment_id',
                ellipsis: true,
                title: <FormattedMessage id="rm.t.c.commentId" />,
                width: 200,
            },
            {
                dataIndex: 'user_id',
                ellipsis: true,
                title: <FormattedMessage id="rm.t.c.postAccUid" />,
                width: 200,
            },
            {
                dataIndex: 'post_id',
                ellipsis: true,
                title: <FormattedMessage id="rm.t.c.formPostUid" />,
                width: 200,
            },
            {
                dataIndex: ['content', 'body'],
                ellipsis: true,
                title: <FormattedMessage id="rm.t.c.commentContent" />,
            },
            {
                dataIndex: '_imageAndVideo',
                title: <FormattedMessage id="rm.t.c.postImg" />,
                render: (_, entity) => <TableCellMedia list={get(entity, 'content.uploaded_materials', [])} />,
                width: 320,
            },
            {
                dataIndex: '_audio',
                title: <FormattedMessage id="rm.t.c.postAudio" />,
                render: (_, entity) => {
                    const audioList = get(entity, 'content.uploaded_materials', []).filter((v) => {
                        return v.startsWith('audio');
                    });
                    const audio = audioList.map((v) => {
                        return {
                            url: v,
                        };
                    });
                    if (!audio.length) {
                        return '-';
                    }
                    return <ComponentTableCellAudio srcs={audio} />;
                },
                width: 320,
            },
            {
                dataIndex: 'deleted_at',
                title: <FormattedMessage id="t.c.status" />,
                render: (_text, entity) =>
                    entity.deleted_at
                        ? intl.formatMessage({ id: 'cp.f.deleted' })
                        : intl.formatMessage({ id: 'rm.b.normal' }),
                width: 120,
            },
            {
                dataIndex: 'created_at',
                title: <FormattedMessage id="cp.t.c.publishAt" />,
                valueType: 'dateTime',
                width: 200,
            },
            {
                dataIndex: ['operator_account', 'name'],
                title: <FormattedMessage id="t.c.operatedBy" />,
                width: 200,
            },
            {
                dataIndex: 'admin_updated_at',
                title: <FormattedMessage id="t.c.operatedAt" />,
                valueType: 'dateTime',
                width: 200,
            },
            {
                dataIndex: '_menu',
                valueType: 'option',
                width: 200,
                fixed: 'right',
                title: <FormattedMessage id="common.operation" />,
                hideInSearch: true,
                render: (_, entity) => [
                    !entity.deleted_at && (
                        <Permission permission="Content_Posts-Comments_Delete" key="delete">
                            <Spin spinning={!!tableCellViolateLoading[entity.comment_id]} size="small">
                                <Popconfirm
                                    title={intl.formatMessage({ id: 'c.areyousure' })}
                                    onConfirm={() => {
                                        setTableCellViolateLoading({
                                            [entity.comment_id]: true,
                                        });
                                        dPostReplies({
                                            user_id: entity.user_id,
                                            comment_id: entity.comment_id,
                                            post_id: entity.post_id,
                                        })
                                            .then(({ data: { code, msg } }) => {
                                                if (code === 0) {
                                                    message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
                                                    refreshPostsReplies();
                                                } else {
                                                    message.error(msg || 'Error');
                                                }
                                                setTableCellViolateLoading({
                                                    [entity.comment_id]: false,
                                                });
                                            })
                                            .catch((e) => {
                                                message.error(get(e, 'response.data.msg', 'Error'));
                                                setTableCellViolateLoading({
                                                    [entity.comment_id]: false,
                                                });
                                            });
                                    }}
                                >
                                    <Typography.Link type="danger">
                                        <FormattedMessage id="b.delete" />
                                    </Typography.Link>
                                </Popconfirm>
                            </Spin>
                        </Permission>
                    ),
                ],
            },
        ],
        dataSource: state.dataSource,
        loading: loadingPostsReplies,
        options: {
            reload: refreshPostsReplies,
        },
        pagination,
        rowKey: 'comment_id',
        rowSelection: {
            columnWidth: 50,
            selectedRowKeys: selectState.selects,
            getCheckboxProps: (record) => {
                return {
                    disabled: loadingBatchDelete || !!record.deleted_at,
                };
            },
            onChange: (selectedRowKeys, selectedRows) => {
                setSelectState({
                    selects: selectedRowKeys as string[],
                    selectedRows,
                });
            },
        },
        scroll: {
            x: 2660,
            y: `calc(100vh - 280px - ${searchRef.current?.clientHeight}px - 8px)`,
        },
        tableAlertRender: false,
        toolBarRender: () => {
            if (!selectState.selects.length || loadingPostsReplies) {
                return [];
            }
            return [
                <Permission permission="Content_Posts-Comments_Delete" key="bulkDelete">
                    <Button
                        type="primary"
                        loading={loadingBatchDelete}
                        onClick={() => {
                            Modal.confirm({
                                title: intl.formatMessage({ id: 'c.areyousureBulk' }),
                                onOk: async () => {
                                    const comments = selectState.selectedRows.map((v) => {
                                        return {
                                            user_id: v.user_id,
                                            post_id: v.post_id,
                                            comment_id: v.comment_id,
                                        };
                                    });
                                    await runBatchDelete({ comments });
                                },
                            });
                        }}
                    >
                        <FormattedMessage id="b.bulkDelete" />
                    </Button>
                </Permission>,
            ];
        },
        search: false,
    };

    /**
     * Effects
     */
    useEffect(() => {
        runPostsReplies({
            ...payload,
            page: pagination.current as any,
            limit: pagination.pageSize as any,
        });
    }, [pagination.current, pagination.pageSize, payload]);

    return (
        <>
            <ProCard className="mb-16" ref={searchRef}>
                <Form {...formSearchProps}>
                    <Flex gap={16} wrap={true}>
                        <Form.Item name="comment_id" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'rm.t.c.commentId' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="user_id" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'rm.t.c.postAccUid' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="post_id" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'rm.t.c.formPostUid' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="content" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'rm.t.c.commentContent' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="types" style={{ marginRight: 0 }}>
                            <Select
                                allowClear={true}
                                showSearch={true}
                                optionFilterProp="label"
                                options={[
                                    {
                                        label: intl.formatMessage({ id: 'cp.f.image' }),
                                        value: 'image',
                                    },
                                    {
                                        label: intl.formatMessage({ id: 'cp.f.video' }),
                                        value: 'video',
                                    },
                                    {
                                        label: intl.formatMessage({ id: 'cp.f.audio' }),
                                        value: 'audio',
                                    },
                                    // {
                                    //     label: intl.formatMessage({ id: 'cp.f.nft' }),
                                    //     value: 'nft',
                                    // },
                                ]}
                                placeholder={<FormattedMessage id="cp.f.type" />}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="fe_is_deleted" style={{ marginRight: 0 }}>
                            <Select
                                allowClear={true}
                                showSearch={true}
                                optionFilterProp="label"
                                options={[
                                    {
                                        label: intl.formatMessage({ id: 'rm.b.normal' }),
                                        value: 'normal',
                                    },
                                    {
                                        label: intl.formatMessage({ id: 'cp.f.deleted' }),
                                        value: 'deleted',
                                    },
                                ]}
                                placeholder={<FormattedMessage id="t.c.status" />}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item
                            label={formatMessage({ id: 'cp.t.c.publishAt' })}
                            name="create_date"
                            style={{ marginRight: 0 }}
                            initialValue={getDefaultTodayDateTime()}
                        >
                            <DatePicker.RangePicker
                                onChange={(rangeDate) => {
                                    if (!rangeDate || !rangeDate[0] || !rangeDate[1]) {
                                        return;
                                    }
                                    const newStart = dayjs(rangeDate[0].format('YYYY-MM-DD HH:00:00'));
                                    const newDnd = dayjs(rangeDate[1].format('YYYY-MM-DD HH:59:59'));
                                    formSearch.setFieldValue('create_date', [newStart, newDnd]);
                                }}
                                showTime={{ format: 'HH' }}
                                allowClear={true}
                            />
                        </Form.Item>

                        <Form.Item name="operator_account_id" style={{ marginRight: 0 }}>
                            <ComponentSearchFormSelectOperator model="Content_Posts-Comments" />
                        </Form.Item>

                        <Form.Item
                            label={formatMessage({ id: 't.c.operatedAt' })}
                            name="operation_date"
                            style={{ marginRight: 0 }}
                        >
                            <DatePicker.RangePicker allowClear={true} />
                        </Form.Item>

                        <Button
                            loading={loadingPostsReplies}
                            type="primary"
                            onClick={() => {
                                formSearch.submit();
                            }}
                        >
                            <FormattedMessage id="b.search" />
                        </Button>

                        <Button
                            onClick={() => {
                                formSearch.resetFields();
                            }}
                        >
                            <FormattedMessage id="b.reset" />
                        </Button>
                    </Flex>
                </Form>
            </ProCard>

            <ProTable {...proTableProps} />
        </>
    );
};

export default PageContentPostsReplies;
