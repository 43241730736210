export default {
    p: {
        uivh: {
            columns: {
                user: '用户',
                livenessAt: '活体认证时间',
                identityStatus: 'ID认证状态',
                identityAt: 'ID认证时间',
                liveness2Status: '2nd认证状态',
                liveness2At: '2nd认证时间',
            },
            status: {
                verified: '已认证',
                unverified: '未认证',
            },
        },
    },
};
