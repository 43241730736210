export default {
    rm: {
        b: {
            normal: '正常',
        },
        t: {
            c: {
                postUid: '帖子ID',
                formPostUid: '所属帖子ID',
                commentId: '评论ID',
                commentContent: '评论内容',
                postAccUid: '发布者UID',
                content: '正文',
                postImg: '图片/视频',
                postAudio: '音频',
                debate: '辩题',
                debateUsers: '正方&反方',
            },
        },
    },
};
