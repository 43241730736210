export default {
    p: {
        plazaConfig: {
            t: {
                headerTitle: 'Plaza Lists',
            },
            tips: {
                unselected: 'Click the module on the left to configure content',
            },
            shorts: {
                f: {
                    idRepeat: '短视频 ID 重复',
                    idError: '短视频 ID 验证不通过',
                },
            },
        },
    },
};
