import { useRequest, useSetState } from 'ahooks';
import { App, Button, Drawer, Flex, Form, Input, Select } from 'antd';
import { get } from 'lodash';
import { useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

/**
 * APIs
 */
import { accounts } from '@/services/account';
import { checkUserId } from '@/services/common';

/**
 * Components
 */

/**
 * Types
 */
import type { ActionType } from '@ant-design/pro-components';
import type { DrawerProps, FormProps } from 'antd';
import type { AccountsResponse } from '@/services/account';
import {
    AmbassadorsGroupMembersCreateParams,
    postAmbassadorsGroupMembers,
    putAmbassadorsGroupMembers,
    type AmbassadorsGroupMembersResult,
} from '@/services/ambassadors-configurations';

type Props = {
    open: boolean;
    groupId: string;
    entity: AmbassadorsGroupMembersResult | null;
    setOpenFalse: (reload?: boolean) => void;
};

type State = {
    accounts: AccountsResponse[];
};

const PageAmbassadorsConfigurationsPrivatePoolConfig: React.FC<Props> = ({ open, entity, groupId, setOpenFalse }) => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    const [form] = Form.useForm();

    const { formatMessage } = useIntl();

    const tableRef = useRef<ActionType>();

    /**
     * States
     */

    const [state, setState] = useSetState<State>({
        accounts: [],
    });

    /**
     * Requests
     */
    useRequest(accounts, {
        defaultParams: [{ limit: 50000, page: 1, status: 1, withRole: true }],
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setState({
                accounts: data.list.filter((account) => {
                    const permissions: string[] = get(account, 'roles.0.permissions', []);

                    return (
                        permissions.includes('super_admin') ||
                        permissions.includes('Ambassadors_Applications_Private-Pool')
                    );
                }),
            });
        },
    });

    const { loading: loadingCheckUserId, runAsync: runAsyncCheckUserId } = useRequest(checkUserId, {
        manual: true,
    });

    const { loading: loadingPostAmbassadorsGroupMembers, run: runPostAmbassadorsGroupMembers } = useRequest(
        postAmbassadorsGroupMembers,
        {
            manual: true,
            onSuccess: ({ data: { code, msg } }) => {
                if (code !== 0) {
                    return message.error(msg);
                }

                // Close the drawer
                setOpenFalse(true);

                // Reload the table
                tableRef.current?.reload?.();
            },
        },
    );

    const { loading: loadingPutAmbassadorsGroupMembers, run: runPutAmbassadorsGroupMembers } = useRequest(
        putAmbassadorsGroupMembers,
        {
            manual: true,
            onSuccess: ({ data: { code, msg } }) => {
                if (code !== 0) {
                    return message.error(msg);
                }

                // Close the drawer
                setOpenFalse(true);

                // Reload the table
                tableRef.current?.reload?.();
            },
        },
    );

    /**
     * ChildrenProps
     */
    const drawerProps: DrawerProps = {
        footer: (
            <Flex gap={8} justify="flex-end">
                <Button
                    onClick={() => {
                        setOpenFalse();
                    }}
                >
                    <FormattedMessage id="b.cancel" />
                </Button>

                <Button
                    loading={
                        loadingCheckUserId || loadingPostAmbassadorsGroupMembers || loadingPutAmbassadorsGroupMembers
                    }
                    type="primary"
                    onClick={() => form.submit()}
                >
                    <FormattedMessage id="b.save" />
                </Button>
            </Flex>
        ),
        maskClosable: false,
        open: open,
        title: entity ? <FormattedMessage id="b.edit" /> : <FormattedMessage id="b.add" />,
        afterOpenChange: (open) => {
            if (!open) {
                form.resetFields();
            } else if (entity) {
                form.setFieldsValue({
                    id: entity.id,
                    user_id: entity.user_id,
                    account_id: entity.account.id,
                });
            }
        },
        onClose: () => {
            setOpenFalse();
        },
    };

    const formProps: FormProps = {
        form,
        layout: 'vertical',
        onFinish: (values) => {
            runAsyncCheckUserId(values.user_id).then(
                ({ data: { data } }) => {
                    // 用户 ID 验证失败
                    if (!data) {
                        message.error(formatMessage({ id: 'c.checkUserId' }));
                        return;
                    }

                    const submitData: AmbassadorsGroupMembersCreateParams = {
                        account_id: values.account_id,
                        user_id: values.user_id,
                    };

                    if (!entity) {
                        return runPostAmbassadorsGroupMembers(groupId, submitData);
                    }

                    if (entity) {
                        return runPutAmbassadorsGroupMembers(groupId, values.id, submitData);
                    }
                },
                (err) => {
                    message.error(err.message);
                },
            );
        },
    };

    return (
        <Drawer {...drawerProps}>
            <Form {...formProps}>
                <Form.Item hidden name="id">
                    <Input />
                </Form.Item>

                <Form.Item label={formatMessage({ id: 't.c.account' })} name="account_id" rules={[{ required: true }]}>
                    <Select
                        disabled={!!entity}
                        optionFilterProp="label"
                        options={state.accounts.map((account) => ({
                            label: account.name,
                            value: account.id,
                        }))}
                        showSearch={true}
                    />
                </Form.Item>

                <Form.Item
                    label={formatMessage({ id: 't.c.operationsUid' })}
                    name="user_id"
                    rules={[{ required: true }]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Drawer>
    );
};

export default PageAmbassadorsConfigurationsPrivatePoolConfig;
