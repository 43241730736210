import { useBoolean, useRequest } from 'ahooks';
import { get } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { App, Button, Drawer, Form, Input, Select, Space } from 'antd';

/**
 * APIs
 */

/**
 * Utils
 */
import { apiErrorMsg } from '@/utils/apiErrorMsg';

/**
 * Types
 */
import type { DrawerProps, FormProps } from 'antd';
import type { IntlShape } from 'react-intl';
import {
    AmbassadorsGroupMembersResult,
    gAmbassadorsGroups,
    moveAmbassadorsGroupMembers,
} from '@/services/ambassadors-configurations';
import { useEffect, useState } from 'react';

type Props = {
    open: boolean;
    groupId: string;
    entity: AmbassadorsGroupMembersResult | null;
    setOpenFalse: (reload?: boolean) => void;
};

const ComponentChangeGroupDrawer: React.FC<Props> = ({ open, entity, setOpenFalse }) => {
    /**
     * Hooks
     */
    const { message } = App.useApp();
    const intl: IntlShape = useIntl();

    const { formatMessage } = useIntl();

    /**
     * State
     */
    const [submitting, { setFalse: setSubmittingFlase, setTrue: setSubmittingTrue }] = useBoolean(false);
    const [form] = Form.useForm();
    const [groupList, setGroupList] = useState<{ label: string; value: string }[]>([]);

    /**
     * Requests
     */
    const { run: runGAmbassadorsGroups } = useRequest(gAmbassadorsGroups, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setGroupList(
                data.map((group) => {
                    return {
                        label: group.name,
                        value: group.id,
                    };
                }),
            );
        },
    });

    /**
     * DrawerProps
     */
    const drawerProps: DrawerProps = {
        footer: (
            <div style={{ textAlign: 'right' }}>
                <Space>
                    <Button type="default" onClick={() => setOpenFalse()}>
                        {intl.formatMessage({ id: 'b.cancel' })}
                    </Button>
                    <Button
                        type="primary"
                        loading={submitting}
                        onClick={() => {
                            form.validateFields()
                                .then(async (formData) => {
                                    setSubmittingTrue();
                                    try {
                                        const res = await moveAmbassadorsGroupMembers(entity!.id, {
                                            group_id: formData.group_id,
                                        });
                                        if (get(res, 'data.code') === 0) {
                                            message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
                                            setOpenFalse(true);
                                        } else {
                                            apiErrorMsg(message, intl, {}, res.data);
                                            setSubmittingFlase();
                                        }
                                    } catch (e) {
                                        console.log(e);
                                        setSubmittingFlase();
                                    }
                                })
                                .catch(() => {});
                        }}
                    >
                        {intl.formatMessage({ id: 'b.confirm' })}
                    </Button>
                </Space>
            </div>
        ),
        destroyOnClose: true,
        maskClosable: false,
        open: open,
        title: <FormattedMessage id="p.ambassadors.b.changeGroup" />,
        afterOpenChange: (open) => {
            if (!open) {
                setSubmittingFlase();
                form.resetFields();
            } else if (entity) {
                form.setFieldsValue({
                    group_id: '',
                    account_name: entity?.account.name,
                });
            }
        },
        onClose: () => {
            setOpenFalse();
        },
    };

    /**
     * ProformProps
     */
    const formProps: FormProps = {
        form: form,
        layout: 'vertical',
        validateTrigger: 'onBlur',
    };

    useEffect(() => {
        open && runGAmbassadorsGroups();
    }, [open]);

    return (
        <Drawer {...drawerProps}>
            <Form {...formProps}>
                <Form.Item label={formatMessage({ id: 't.c.account' })} name="account_name">
                    <Input disabled />
                </Form.Item>

                <Form.Item name="group_id" label="Group" rules={[{ required: true }]}>
                    <Select optionFilterProp="label" options={groupList} showSearch={true} />
                </Form.Item>
            </Form>
        </Drawer>
    );
};

export default ComponentChangeGroupDrawer;
