export default {
    ccc: {
        t: {
            c: {
                channelID: 'Channel ID',
                link: 'Link',
                code: 'Code',
                registrations: 'Registrations',
            },
        },
        s: {
            KOL: 'KOL',
            DEFAULT: 'Default',
            AMBASSADOR: 'Ambassador',
            PROJECT: 'Project',
            CHANNEL: 'Channel',
        },
    },
};
