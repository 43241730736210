import { useBoolean } from 'ahooks';
import { get } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { App, Button, Drawer, Form, Input, Segmented, Space } from 'antd';

/**
 * Utils
 */
import { apiErrorMsg } from '@/utils/apiErrorMsg';

/**
 * APIs
 */

/**
 * Constants
 */
const Language = ['EN', 'CHS', 'CHT', 'JPN', 'VIE', 'TRK', 'ESP', 'PT'];

/**
 * Types
 */
import type { DrawerProps, FormProps } from 'antd';
import type { IntlShape } from 'react-intl';
import { useState } from 'react';
import { cIMMessage } from '@/services/im-message';

type LanguageType = (typeof Language)[number];
type Props = {
    open: boolean;
    closeModelForm: (reload?: boolean) => void;
};

const ComponentModalFormCustom: React.FC<Props> = ({ open, closeModelForm }) => {
    /**
     * Hooks
     */
    const { message } = App.useApp();
    const intl: IntlShape = useIntl();

    /**
     * State
     */
    const [submitting, { setFalse: setSubmittingFlase, setTrue: setSubmittingTrue }] = useBoolean(false);
    const [form] = Form.useForm();
    const [showLanguage, setShowLanguage] = useState('EN');

    /**
     * DrawerProps
     */
    const drawerProps: DrawerProps = {
        size: 'large',
        footer: (
            <div style={{ textAlign: 'right' }}>
                <Space>
                    <Button type="default" onClick={() => closeModelForm()}>
                        {intl.formatMessage({ id: 'b.cancel' })}
                    </Button>
                    <Button
                        type="primary"
                        loading={submitting}
                        onClick={() => {
                            form.validateFields()
                                .then(async (formData) => {
                                    console.log(formData);
                                    const languages = [
                                        {
                                            language: 'EN',
                                            value: formData.title,
                                        },
                                    ].concat(
                                        Object.entries(formData.languages).map((v) => {
                                            return {
                                                language: v[0],
                                                value: (v[1] as any).title || '',
                                            };
                                        }),
                                    );
                                    setSubmittingTrue();
                                    try {
                                        const res = await cIMMessage({ languages });
                                        if (get(res, 'data.code') === 0) {
                                            message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
                                            closeModelForm(true);
                                        } else {
                                            apiErrorMsg(message, intl, {}, res.data);
                                            setSubmittingFlase();
                                        }
                                    } catch {
                                        setSubmittingFlase();
                                    }
                                })
                                .catch(() => {
                                    setShowLanguage('EN');
                                    form.setFieldValue('_language', 'EN');
                                });
                        }}
                    >
                        {intl.formatMessage({ id: 'b.confirm' })}
                    </Button>
                </Space>
            </div>
        ),
        destroyOnClose: true,
        maskClosable: false,
        open: open,
        title: <FormattedMessage id="b.add" />,
        afterOpenChange: (open) => {
            if (!open) {
                setSubmittingFlase();
                form.resetFields();
                setShowLanguage('EN');
            }
        },
        onClose: () => {
            closeModelForm();
        },
    };

    /**
     * ProformProps
     */
    const formProps: FormProps = {
        form: form,
        layout: 'vertical',
        validateTrigger: 'onBlur',
    };

    return (
        <Drawer {...drawerProps}>
            <Form {...formProps}>
                <Form.Item
                    name="_language"
                    label={intl.formatMessage({ id: 'tc.f.language' })}
                    rules={[{ required: true }]}
                    initialValue={'EN'}
                >
                    <Segmented<LanguageType>
                        options={Language}
                        value={showLanguage}
                        onChange={(value) => {
                            setShowLanguage(value);
                            form.setFieldValue('_language', value);
                        }}
                    />
                </Form.Item>

                {Language.map((v) => {
                    return (
                        <div key={v} style={{ display: showLanguage === v ? 'block' : 'none' }}>
                            <Form.Item
                                name={v === 'EN' ? 'title' : ['languages', v, 'title']}
                                label={intl.formatMessage({ id: 'm.IM_Messages' })}
                                rules={[{ required: 'EN' === v }]}
                            >
                                <Input.TextArea showCount maxLength={1000} autoSize={{ minRows: 4 }} />
                            </Form.Item>
                        </div>
                    );
                })}
            </Form>
        </Drawer>
    );
};

export default ComponentModalFormCustom;
