import axios from './index';
// import localAxios from 'axios';

// const axios = localAxios.create({
//     baseURL: 'http://127.0.0.1:4523/m1/4939924-0-default',
// });

/**
 * APIs
 */
// 获取帖子列表
export const posts = (params: PostCursorParams) => {
    return axios<PageResDataType<PostCursorResult>>('/v2/posts', {
        method: 'get',
        params,
    });
};

// 删除帖子
export const dPost = (data: DeletePostParams) => {
    return axios<PageResType<PostResult>>('/v2/posts', {
        method: 'delete',
        data,
    });
};

// 批量删除帖子
export const batchDeletePost = (data: BatchDeletePostParams) => {
    return axios<PageResType<PostResult>>('/v2/posts/batch', {
        method: 'delete',
        data,
    });
};

// 获取帖子评论列表
export const gPostsReplies = (params: PostParams) => {
    return axios<PageResType<PostRepliesResult>>('/v1/posts/comments', {
        method: 'get',
        params,
    });
};

// 删除帖子评论
export const dPostReplies = (data: DeletePostRepliesParams) => {
    return axios<PageResType<PostResult>>('/v1/posts/comments', {
        method: 'delete',
        data,
    });
};

// 删除帖子评论
export const batchDeletePostReplies = (data: BatchDeletePostRepliesParams) => {
    return axios<PageResType<PostResult>>('/v1/posts/comments/batch', {
        method: 'delete',
        data,
    });
};

/**
 * Types
 */
import type { PageResDataType, PageResType } from '@repo/typings/common';
import type { IdType } from './creator-academy';

/**
 * POST
 */
export type PostResult = {
    /**
     * 所有评论的总数量
     */
    all_comment_count: string;
    content: PostContent;
    created_at: string;
    /**
     * 删除时间，判断删除状态
     */
    deleted_at?: string;
    /**
     * 不喜欢的数量
     */
    dislike_count: string;
    ext: { [key: string]: any };
    /**
     * 收藏次数
     */
    favorite_count: string;
    /**
     * '文件位掩码:1=图片, 2=视频, 4=音频',
     */
    file_media: string;
    finish_at: string;
    /**
     * 转发次数
     */
    forward_count: string;
    labels: { [key: string]: any };
    /**
     * 点赞数量
     */
    like_count: string;
    /**
     * 帖子 ID
     */
    post_id: string;
    /**
     * DEBATE | NORMAL | FORMAL_DEBATE | POST_TYPE | NORMAKL
     */
    post_type: string;
    /**
     * 引用总数
     */
    quote_count: string;
    /**
     * 引用帖子ID
     */
    quote_post_id: string;
    /**
     * 引用帖子的创建用户
     */
    quote_user_id: string;
    /**
     * 回帖数量
     */
    repost_count: string;
    /**
     * 根评论的数量
     */
    root_comment_count: string;
    /**
     * 分享次数
     */
    share_count: string;
    /**
     * 帖子来源，默认为用户发布,
     */
    source: string;
    /**
     * 状态，OK | FORMAL_DEBATE_EXPIRED | FORMAL_DEBATE_FINISHED
     */
    status: string;
    updated_at: string;
    /**
     * 用户 ID
     */
    user_id: string;
    /**
     * 版本号
     */
    version: string;
};

// 内容管理-帖子数据返回值
// TODO 待后端定义好apifox接口文档 补充
export type PostCursorResult = any;

/**
 * POST_CONTENT
 */
export interface PostContent {
    body?: string;
    debate?: Debate;
    filePlatform?: string;
    formal_debate?: FormalDebate;
    title?: string;
    /**
     * 上传的物料，video/* | image/*
     */
    uploaded_materials?: string[];
    audio?: Audio;
}

export interface Debate {
    /**
     * 反方
     */
    against_text: string;
    /**
     * 过期时间
     */
    expired_at: number;
    /**
     * 正方
     */
    for_text: string;
    title: string;
}

export interface FormalDebate {
    con_text: string;
    debaters_per_side: number;
    end_at: number;
    pro_text: string;
    start_at: number;
}

export type Audio = {
    /**
     * 时长，160
     */
    audio_duration?: string;
    text?: string;
    /**
     * audio/79639217504129071/2024/10/11/u9CsyMgWXKbmYReJGFoRc3.mp3
     */
    url: string;
};

export type PostCursorParams = {
    /**
     * 正文（模糊匹配）
     */
    body_fuzzy?: string;
    /**
     * 创建时间结束
     */
    created_end?: string;
    /**
     * 创建时间开始
     */
    created_start?: string;
    /**
     * 辩论（模糊匹配）
     */
    debate_fuzzy?: string;
    /**
     * 是否包含音频
     */
    has_audio?: string;
    /**
     * 是否包含图片
     */
    has_image?: string;
    /**
     * 是否包含视频
     */
    has_video?: string;
    /**
     * 帖子 ID
     */
    id?: string;
    /**
     * 是否为辩论
     */
    is_debate?: string;
    /**
     * 是否被删除
     */
    is_deleted?: string;
    /**
     * 游标
     */
    search_after?: string;
    /**
     * 每页条数
     */
    size?: string;
    /**
     * 排序
     */
    'sort[0][created_at]'?: string;
    /**
     * 更新时间开始
     */
    'updated_at[0]'?: string;
    /**
     * 更新时间结束
     */
    'updated_at[1]'?: string;

    'deleted_at[0]'?: string;

    'deleted_at[1]'?: string;
    /**
     * 用户 ID
     */
    user_id?: string;

    created_date?: any[];
    updated_date?: any[];
    fe_is_deleted?: string;
    fe_types?: ('has_audio' | 'has_image' | 'has_video' | 'is_debate')[];
};

export type PostParams = {
    /**
     * 正文
     */
    content?: string;
    /**
     * 发布结束时间
     */
    create_end?: string;
    /**
     * 发布开始时间
     */
    create_start?: string;
    /**
     * 辩题
     */
    debate_title?: string;
    /**
     * 是否删除
     */
    is_deleted?: 'true' | 'false';
    limit?: number;
    order_by?: string;
    order_sort?: string;
    page?: number;
    /**
     * 帖子ID
     */
    post_id?: string;
    /**
     * 类型
     */
    types?: any[];
    /**
     * 发布者UID
     */
    user_id?: string;
    /**
     * 操作开始时间
     */
    updated_start?: string;
    /**
     * 操作结束时间
     */
    updated_end?: string;
};

export type FePostParams = PostParams & {
    create_date?: any;
    fe_is_deleted?: string;
    operation_date?: any;
};

export type DeletePostParams = {
    user_id: IdType;
    post_id: IdType;
};

/**
 * 帖子评论
 */
export type PostRepliesResult = PostResult & {
    comment_id: IdType;
};

export type DeletePostRepliesParams = {
    user_id: IdType;
    comment_id: IdType;
    post_id: IdType;
};

export type BatchDeletePostParams = {
    posts: {
        /**
         * 帖子 ID
         */
        post_id: string;
        /**
         * 用户 ID
         */
        user_id: string;
    }[];
};

export type BatchDeletePostRepliesParams = {
    comments: {
        /**
         * 评论 ID
         */
        comment_id: string;
        /**
         * 帖子 ID
         */
        post_id: string;
        /**
         * 用户 ID
         */
        user_id: string;
    }[];
};
