export default {
    p: {
        ambassadors: {
            b: {
                bulkApprove: '批量通过',
                bulkAssign: '批量分配',
                changeGroup: '转组',
            },
            t: {
                c: {
                    activeUserTotal: '活跃用户数',
                    assigned: '已分配',
                    followers: '粉丝数',
                    pending: '待审核',
                    postTotal: '帖子数',
                    postUserTotal: '发布用户数',
                    registerTotal: '邀请注册数',
                    realnameTotal: '活体认证用户数',
                    reviewResult: '审核结果',
                    socialMedia: '社交媒体',
                    status: '分配状态',
                    unassigned: '未分配',
                    revokeReason: '撤销原因',
                },
            },
            tips: {
                privatePool: {
                    email: '用户申请大使时填写的邮箱',
                },
                ambassadorData: {
                    uid: '大使的用户 ID',
                    posts: '大使发布的帖子数量',
                    invitedUsers: '大使邀请的注册用户数量',
                    usersPosted: '有发布帖子行为的用户数量',
                    usersVerified: '经过活体认证且认证成功的用户数量',
                },
                ambassador: {
                    unbound: '该大使将被释放入大使公海池？',
                },
                groupMemberTop: '请点击左侧各组进行人员配置',
            },
            detail: {
                languages: '语言',
                occupationDetails: '职业介绍',
                motivation: '申请原因',
                promotionPlan: '拉新推广计划',
                promotionExperience: '推广拉新经验',
                questions: '问题',
                timeWillingToSpend: '愿意花费时间',
                web3Experience: 'Web3 行业经验',
                web3Knowledge: 'Web3 是否了解',
                willingnessToLead: '愿意成为领导者',
                satisfyCurrentLife: '人生现状是否满意',
                needChangeLife: '渴望平台与机会',
                bestChangeLife: '是否愿意全力以赴',
            },
            privatePool: {
                rejectReason: '驳回原因',
            },
            publicPool: {
                status: {
                    normal: '正常',
                    unbound: '已释放',
                    revoked: '已撤销',
                },
            },
        },
    },
};
