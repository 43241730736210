import { ProCard, ProTable } from '@ant-design/pro-components';
import { useRequest, useSetState } from 'ahooks';
import { App, Button, DatePicker, Drawer, Flex, Form, Input, PaginationProps, Select, Typography } from 'antd';
import { get, isEmpty, omitBy } from 'lodash';
import Permission, { useAccess } from '@/components/permission';
import { useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import SearchUser from '@/components/search-user';

/**
 * APIs
 */
import {
    ambassadorApplicationsPublic,
    privatePoolConfigs,
    bulkUAmbassadorApplicationPublic,
} from '@/services/ambassadors';

/**
 * Components
 */
import ComponentDetail from './components/detail';

/**
 * Types
 */
import type { ActionType, ProTableProps } from '@ant-design/pro-components';
import type { DrawerProps, FormProps } from 'antd';
import type {
    AmbassadorApplicationsParams,
    AmbassadorApplicationsResult,
    PrivatePoolConfigsResult,
} from '@/services/ambassadors';

type State = {
    configs: PrivatePoolConfigsResult[];
    dataSource: AmbassadorApplicationsResult[];
    entity: AmbassadorApplicationsResult | null;
    open: boolean;
    openDetail: boolean;
    selectedRowKeys: React.Key[];
};

const PageAmbassadorsApplicationsPublicPool: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    const [form] = Form.useForm();

    const [formSearch] = Form.useForm();

    const { formatMessage } = useIntl();

    const searchRef = useRef<HTMLDivElement>(null);

    const tableRef = useRef<ActionType>();

    const checkAccess = useAccess();

    /**
     * States
     */
    const [state, setState] = useSetState<State>({
        configs: [],
        dataSource: [],
        entity: null,
        open: false,
        openDetail: false,
        selectedRowKeys: [],
    });

    /**
     * Constants
     */
    const hasAssignPermission = checkAccess('Ambassadors_Applications_Public-Pool_Assign');

    /**
     * Paginations
     */
    const [pagination, setPagination] = useSetState<PaginationProps>({
        current: 1,
        pageSize: 20,
        onChange: (page, pageSize) => {
            setPagination({
                current: page,
                pageSize,
            });
        },
    });

    /**
     * Payloads
     */
    const [payload, setPayload] = useSetState<AmbassadorApplicationsParams>({
        types: ['unassigned'],
    });

    /**
     * Requests
     */
    useRequest(privatePoolConfigs, {
        defaultParams: [{ limit: 9999, page: 1 }],
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setState({
                configs: data.list,
            });
        },
    });

    const {
        loading: loadingAmbassadorApplications,
        refresh: refreshAmbassadorApplications,
        run: runAmbassadorApplications,
    } = useRequest(ambassadorApplicationsPublic, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setPagination({
                current: data.meta.currentPage,
                pageSize: data.meta.perPage,
                total: data.meta.total,
            });

            setState({
                dataSource: data.list,
            });
        },
    });

    const { loading: loadingBulkUAmbassadorApplication, run: runBulkUAmbassadorApplication } = useRequest(
        bulkUAmbassadorApplicationPublic,
        {
            manual: true,
            onSuccess: ({ data: { code, msg } }) => {
                if (code !== 0) {
                    return message.error(msg);
                }

                // Close the drawer
                setState({
                    open: false,
                    openDetail: false,
                });

                // Reload the table
                tableRef.current?.clearSelected?.();
                refreshAmbassadorApplications();
            },
        },
    );

    /**
     * ChildrenProps
     */
    const drawerProps: DrawerProps = {
        footer: (
            <Flex gap={8} justify="flex-end">
                <Button
                    onClick={() => {
                        setState({
                            open: false,
                        });
                    }}
                >
                    <FormattedMessage id="b.cancel" />
                </Button>

                <Button loading={loadingBulkUAmbassadorApplication} type="primary" onClick={() => form.submit()}>
                    <FormattedMessage id="b.confirm" />
                </Button>
            </Flex>
        ),
        maskClosable: false,
        open: state.open,
        title: <FormattedMessage id="b.assign" />,
        afterOpenChange: (open) => open || form.resetFields(),
        onClose: () => {
            setState({
                open: false,
            });
        },
    };

    const drawerDetailProps: DrawerProps = {
        maskClosable: false,
        open: state.openDetail,
        size: 'large',
        title: <FormattedMessage id="b.details" />,
        onClose: () => {
            setState({
                openDetail: false,
            });
        },
    };

    const formProps: FormProps = {
        form,
        layout: 'vertical',
        onFinish: (values) => {
            runBulkUAmbassadorApplication(values);
        },
    };

    const formSearchProps: FormProps = {
        form: formSearch,
        initialValues: {
            type: 'unassigned',
        },
        layout: 'inline',
        onFinish: (values) => {
            if (values.apply_date) {
                values.apply_end = values.apply_date[1].endOf('d').format();
                values.apply_start = values.apply_date[0].startOf('d').format();
                values.apply_date = undefined;
            }

            if (values.operation_date) {
                values.operation_end = values.operation_date[1].endOf('d').format();
                values.operation_start = values.operation_date[0].startOf('d').format();
                values.operation_date = undefined;
            }

            values.types = values.type ? [values.type] : ['assigned', 'unassigned'];
            values.type = undefined;

            setPagination({
                current: 1,
                pageSize: 20,
            });

            setPayload({
                account_id: undefined,
                apply_end: undefined,
                apply_start: undefined,
                operation_end: undefined,
                operation_start: undefined,
                operator_account_id: undefined,
                order_by: undefined,
                order_sort: undefined,
                parent_user_id: undefined,
                request_id: undefined,
                statuses: undefined,
                types: ['unassigned'],
                user_id: undefined,
                nickname: undefined,
                request_email: undefined,
                username: undefined,

                ...omitBy(values, isEmpty),
            });
        },
    };

    const proTableProps: ProTableProps<AmbassadorApplicationsResult, any> = {
        actionRef: tableRef,
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                fixed: 'left',
                title: 'No.',
                valueType: 'index',
                width: 50,
            },
            {
                dataIndex: 'user_id',
                title: <FormattedMessage id="t.c.user" />,
                width: 200,
                render: (_, entity) => {
                    const { name = '-', nickname = '-' } = entity.user_info || {};

                    return (
                        <>
                            <div>{nickname}</div>

                            <Typography.Text copyable={{ text: entity.user_id, tooltips: 'Copy UID' }}>
                                @{name}
                            </Typography.Text>
                        </>
                    );
                },
            },
            {
                dataIndex: '1',
                ellipsis: true,
                title: <FormattedMessage id="t.c.fullName" />,
                width: 200,
                render: (_, entity) => {
                    const { first_name = '', last_name = '' } = get(entity, 'request.ambassador_info', {});

                    return `${first_name} ${last_name}`;
                },
            },
            {
                dataIndex: ['request', 'email'],
                ellipsis: true,
                title: <FormattedMessage id="t.c.email" />,
                width: 200,
            },
            {
                dataIndex: ['request', 'social_media_presence', 'link'],
                ellipsis: true,
                title: <FormattedMessage id="t.c.link" />,
                renderText: (text) => {
                    if (!text) return '-';
                    return (
                        <a href={text?.startsWith('http') ? text : `http://${text}`} target="_blank">
                            {text}
                        </a>
                    );
                },
            },
            {
                dataIndex: ['request', 'social_media_presence', 'fan_count'],
                title: <FormattedMessage id="p.ambassadors.t.c.followers" />,
                width: 120,
            },
            {
                dataIndex: 'created_at',
                title: <FormattedMessage id="t.c.appliedAt" />,
                valueType: 'dateTime',
                width: 200,
            },
            {
                dataIndex: 'type',
                title: <FormattedMessage id="p.ambassadors.t.c.status" />,
                width: 120,
                valueEnum: {
                    assigned: <FormattedMessage id="p.ambassadors.t.c.assigned" />,
                    unassigned: <FormattedMessage id="p.ambassadors.t.c.unassigned" />,
                },
            },
            {
                dataIndex: ['account', 'name'],
                ellipsis: true,
                title: <FormattedMessage id="t.c.owner" />,
                width: 120,
            },
            {
                dataIndex: ['operator_account', 'name'],
                ellipsis: true,
                title: <FormattedMessage id="t.c.operatedBy" />,
                width: 120,
            },
            {
                dataIndex: 'updated_at',
                title: <FormattedMessage id="t.c.operatedAt" />,
                valueType: 'dateTime',
                width: 200,
                renderText: (text, record) => (record.type === 'assigned' ? text : null),
            },
            {
                fixed: 'right',
                key: 'option',
                title: <FormattedMessage id="t.c.action" />,
                valueType: 'option',
                width: 200,
                render: (_, entity) => [
                    entity.type === 'assigned' ? null : (
                        <Permission permission="Ambassadors_Applications_Public-Pool_Assign">
                            <a
                                key="assign"
                                onClick={() => {
                                    form.setFieldsValue({
                                        ids: [entity.id],
                                    });

                                    setState({
                                        open: true,
                                    });
                                }}
                            >
                                <FormattedMessage id="b.assign" />
                            </a>
                        </Permission>
                    ),
                    <a
                        key="detail"
                        onClick={() => {
                            setState({
                                entity,
                                openDetail: true,
                            });
                        }}
                    >
                        <FormattedMessage id="b.details" />
                    </a>,
                ],
            },
        ],
        dataSource: state.dataSource,
        loading: loadingAmbassadorApplications,
        options: {
            reload: refreshAmbassadorApplications,
        },
        pagination,
        rowKey: 'id',
        rowSelection: hasAssignPermission
            ? {
                  columnWidth: 50,
                  selectedRowKeys: state.selectedRowKeys,
                  getCheckboxProps: (record) => ({
                      disabled: record.type === 'assigned',
                  }),
                  onChange: (selectedRowKeys) => {
                      setState({
                          selectedRowKeys,
                      });
                  },
              }
            : undefined,
        scroll: {
            x: 1980,
            y: `calc(100vh - 224px - ${searchRef.current?.clientHeight || 64}px - 16px - 48px)`,
        },
        search: false,
        tableAlertOptionRender: false,
        tableAlertRender: false,
        toolbar: {
            actions: [
                state.selectedRowKeys.length ? (
                    <Button
                        key="bulkAssign"
                        type="primary"
                        onClick={() => {
                            form.setFieldsValue({
                                ids: state.selectedRowKeys,
                            });

                            setState({
                                open: true,
                            });
                        }}
                    >
                        <FormattedMessage id="p.ambassadors.b.bulkAssign" />
                    </Button>
                ) : null,
            ],
        },
    };

    /**
     * Effects
     */
    useEffect(() => {
        setState({
            selectedRowKeys: [],
        });
    }, [pagination.current, pagination.pageSize]);

    useEffect(() => {
        runAmbassadorApplications({
            ...payload,
            limit: pagination.pageSize,
            page: pagination.current,
        });
    }, [pagination.current, pagination.pageSize, payload]);

    return (
        <>
            <ProCard className="mb-16" ref={searchRef}>
                <Form {...formSearchProps}>
                    <Flex gap={16} wrap={true}>
                        <SearchUser form={formSearch} />

                        <Form.Item name="type" style={{ marginRight: 0 }}>
                            <Select
                                allowClear={true}
                                optionFilterProp="label"
                                options={[
                                    {
                                        label: formatMessage({ id: 'p.ambassadors.t.c.assigned' }),
                                        value: 'assigned',
                                    },
                                    {
                                        label: formatMessage({ id: 'p.ambassadors.t.c.unassigned' }),
                                        value: 'unassigned',
                                    },
                                ]}
                                placeholder={<FormattedMessage id="p.ambassadors.t.c.status" />}
                                showSearch={true}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="account_id" style={{ marginRight: 0 }}>
                            <Select
                                allowClear={true}
                                optionFilterProp="label"
                                options={state.configs.map((config) => ({
                                    label: config.account.name,
                                    value: config.account.id,
                                }))}
                                placeholder={<FormattedMessage id="t.c.owner" />}
                                showSearch={true}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item
                            label={formatMessage({ id: 't.c.appliedAt' })}
                            name="apply_date"
                            style={{ marginRight: 0 }}
                        >
                            <DatePicker.RangePicker allowClear={true} />
                        </Form.Item>

                        <Form.Item
                            label={formatMessage({ id: 't.c.operatedAt' })}
                            name="operation_date"
                            style={{ marginRight: 0 }}
                        >
                            <DatePicker.RangePicker allowClear={true} />
                        </Form.Item>

                        <Button
                            loading={loadingAmbassadorApplications}
                            type="primary"
                            onClick={() => {
                                formSearch.submit();
                            }}
                        >
                            <FormattedMessage id="b.search" />
                        </Button>

                        <Button
                            onClick={() => {
                                formSearch.resetFields();
                            }}
                        >
                            <FormattedMessage id="b.reset" />
                        </Button>
                    </Flex>
                </Form>
            </ProCard>

            <ProTable {...proTableProps} />

            <Drawer {...drawerProps}>
                <Form {...formProps}>
                    <Form.Item hidden name="ids">
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label={formatMessage({ id: 't.c.account' })}
                        name="account_id"
                        rules={[{ required: true }]}
                    >
                        <Select
                            optionFilterProp="label"
                            options={state.configs.map((config) => ({
                                label: config.account.name,
                                value: config.account.id,
                            }))}
                            showSearch={true}
                        />
                    </Form.Item>
                </Form>
            </Drawer>

            <Drawer {...drawerDetailProps}>
                <ComponentDetail dataSource={state.entity!} />
            </Drawer>
        </>
    );
};

export default PageAmbassadorsApplicationsPublicPool;
