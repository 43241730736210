export default {
    mklf: {
        meme: {
            c: {
                UnderReview: 'Under Review',
                Rejected: 'Rejected',
                Unrecommended: 'Unrecommended',
                Recommended: 'Recommended',
                Launching: 'Launching',
                Launched: 'Launched',
                Delisted: 'Delisted',
            },
            t: {
                c: {
                    price: 'Price(SOC)',
                    marketCap: 'Market Cap(SOC)',
                    delist: 'Delist',
                    group: 'Group',
                    website: 'Website',
                },
            },
        },
    },
};
