export default {
    rm: {
        b: {
            normal: 'Normal',
        },
        t: {
            c: {
                postUid: 'Post ID',
                formPostUid: 'Post ID',
                commentId: 'Reply ID',
                commentContent: 'Content',
                postAccUid: 'Creator UID',
                content: 'Content',
                postImg: 'Image/Video',
                postAudio: 'Audio',
                debate: 'Debate',
                debateUsers: 'For&Against',
            },
        },
    },
};
