/**
 * Menu
 */
export default {
    m: {
        /**
         * Home
         *
         * 首页
         */
        Home: '首页',

        /**
         * Users
         *
         * 用户管理
         */
        Users: '用户管理',

        Users_Users: '用户管理',

        'Users_Identity-Verification-History': '真人认证记录',

        Users_Usernames: '用户名管理',

        'Users_Sanction-History': '用户处理记录',

        /**
         * Content
         *
         * 内容管理
         */
        Content: '内容管理',

        Content_Posts: '帖子管理',

        'Content_Posts-Comments': '帖子评论',

        Content_Shorts: '短视频管理',

        'Content_Shorts-Comments': '短视频评论',

        Content_Live: '辩论直播管理',

        /**
         * Creator
         *
         * 创作者中心
         */
        Creator_Center: '创作者中心',

        'Creator_Center-Topic': '话题管理',

        'Creator_Center-Creator_Hub': '创作学院',

        'Creator_Center-Top_Posts': '推荐帖子',

        /**
         * Ambassadors
         *
         * 大使计划
         */
        Ambassadors: '大使计划',

        Ambassadors_Applications: '大使申请',
        'Ambassadors_Applications_Public-Pool': '申请公海池',
        'Ambassadors_Applications_Private-Pool': '申请私海池',
        'Ambassadors_Applications_Review-Records': '审核记录',

        Ambassadors_Ambassadors: '大使管理',
        'Ambassadors_Ambassadors_Public-Pool': '大使公海池',
        Ambassadors_Ambassadors_Ambassadors: '大使',

        Ambassadors_Data: '数据管理',
        'Ambassadors_Data_Ambassador-Data': '大使任务数据',

        Ambassadors_Configurations: '配置管理',
        'Ambassadors_Configurations_Private-Pool-Config': '申请私海池配置',
        'Ambassadors_Configurations_Mkt-Member-Config': '运营人员管理',

        /**
         * Incentives
         *
         * 激励计划
         */
        Incentives: '激励计划',

        'Incentives_Incentive-Seasons': '激励计划活动列表',

        'Incentives_Task-Config': '任务管理',

        /**
         * Super Users
         *
         * 超级账号
         */
        'Super-Users': '超级账号',

        'Super-Users_Behavior': '行为管理',

        'Super-Users_Benefit': '权益管理',

        /**
         * Reports
         *
         * 举报管理
         */
        Reports: '举报管理',

        'Reports_Report-Moderation': '举报审核',

        'Reports_Moderation-Records': '审核记录',

        /**
         * Client Config
         *
         * 客户端配置
         */
        'Client-Config': '客户端配置',

        'Client-Config_Plaza-Config': '数字文明广场',

        'Client-Config_Super-Users': '超级账号',

        'Client-Config_Airdrop-Whitelist': '空投白名单',

        'Client-Config_Domain': '域名管理',

        'Client-Config_App-Version': 'App版本管理',

        'Client-Config_Channel-Code': '渠道码管理',

        /**
         * IM
         */
        IM_Messages: '站内通知',

        /**
         * Airdrop
         *
         * 空投管理
         */
        'official-airdrop': '空投管理',
        oa: {
            'airdrop-events': '官方空投活动列表',
            'official-airdrop-configurations': '官方空投参数配置',
        },

        /**
         * Marketplace
         *
         * 交易市场
         */
        Marketplace: '交易市场',
        Marketplace_NFT: 'NFT',
        'Marketplace_NFT_NFT-Collection': 'NFT合集管理',
        Marketplace_NFT_NFT: 'NFT管理',
        'Marketplace_NFT_NFT-Marketplace': 'NFT交易市场',
        Marketplace_Token: '代币',
        'Marketplace_Token_Token-Config': '代币配置',
        Marketplace_Token_Pool: '流动性池',
        'Marketplace_Token_Top-Token': '热门代币配置',

        /**
         * Airdrop
         *
         * 空投管理
         */
        Airdrop: '空投管理',

        'Airdrop_Airdrop-Seasons': '官方空投活动列表',

        'Airdrop_Airdrop-Config': '官方空投参数配置',

        /**
         * System Management
         *
         * 系统管理
         */
        'System-Management': '系统管理',

        'System-Management_Accounts': '账号管理',

        'System-Management_Roles': '角色管理',

        'System-Management_Logs': '系统日志',

        'System-Management_Exports': '下载管理',
    },
};
