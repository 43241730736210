import { useRef } from 'react';
import { useSetState } from 'ahooks';
import { FormattedMessage } from 'react-intl';
import { ProTable } from '@ant-design/pro-components';
import { get } from 'lodash';

/**
 * Components
 */
import ComponentDrawerFormCustom from './components/drawer-form-custom';
import Permission from '@/components/permission';

/**
 * APIs
 */
import { actionTypeMap, gBehaviors } from '@/services/super-users';

/**
 * Types
 */
import type { ActionType, ProTableProps } from '@ant-design/pro-components';
import type { AdminSuperUserBehaviors } from '@/services/super-users';

type State = {
    openDrawer: boolean;
    entity: any;
};

const PageSuperUsersBehavior: React.FC = () => {
    /**
     * Hooks
     */
    // const { message } = App.useApp();

    const tableRef = useRef<ActionType>();
    /**
     * States
     */
    const [state, setState] = useSetState<State>({
        openDrawer: false,
        entity: null,
    });
    /**
     * ChildrenProps
     */
    const proTableProps: ProTableProps<AdminSuperUserBehaviors, any> = {
        actionRef: tableRef,
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                valueType: 'index',
                title: 'No.',
                width: 50,
                fixed: 'left',
            },
            {
                dataIndex: 'type',
                title: <FormattedMessage id="su.t.c.action" />,
                render: (type) => actionTypeMap[type as string] || type,
                width: 200,
            },
            {
                dataIndex: 'content',
                title: <FormattedMessage id="su.t.c.targetReward" />,
                render: (val: any) => {
                    if (Array.isArray(val)) {
                        return val.map((item) => (
                            <div key={item.label}>
                                {`${item.label}`}: &nbsp;&nbsp;{item.value}
                            </div>
                        ));
                    }
                    if (typeof val === 'object') {
                        return (
                            <>
                                {val?.label}: &nbsp;&nbsp;{val?.value}
                            </>
                        );
                    }
                    return '-';
                },
            },
            {
                dataIndex: ['operator_account', 'name'],
                title: <FormattedMessage id="t.c.operatedBy" />,
                width: 200,
            },
            {
                dataIndex: 'updated_at',
                title: <FormattedMessage id="t.c.operatedAt" />,
                valueType: 'dateTime',
                width: 200,
            },
            {
                dataIndex: '_menu',
                valueType: 'option',
                width: 200,
                fixed: 'right',
                title: <FormattedMessage id="common.operation" />,
                hideInSearch: true,
                render: (_, entity) => [
                    <Permission permission="Super-Users_Behavior_Edit" key="edit">
                        <a
                            onClick={() => {
                                setState({
                                    entity,
                                    openDrawer: true,
                                });
                            }}
                        >
                            <FormattedMessage id="b.edit" />
                        </a>
                    </Permission>,
                ],
            },
        ],
        rowKey: 'id',
        scroll: {
            x: 1050,
            y: `calc(100vh - 232px)`,
        },
        search: false,
        pagination: false,
        request: async () => {
            const res = await gBehaviors();
            return {
                data: get(res, 'data.data', []),
                success: get(res, 'data.code') === 0,
                total: 0,
            };
        },
    };

    return (
        <>
            <ProTable {...proTableProps} />
            <ComponentDrawerFormCustom
                open={state.openDrawer}
                entity={state.entity}
                closeModelForm={(reload) => {
                    setState({ openDrawer: false });
                    reload && tableRef.current?.reload();
                }}
            />
        </>
    );
};

export default PageSuperUsersBehavior;
