import { useEffect, useRef } from 'react';
import { App, Button, Flex, Form, DatePicker, Select, Typography, Cascader } from 'antd';
import { ProCard, ProTable, type ProTableProps } from '@ant-design/pro-components';
import { isEmpty, omitBy } from 'lodash';
import { useRequest, useSetState } from 'ahooks';
import { FormattedMessage, useIntl } from 'react-intl';
import dayjs from 'dayjs';
import SearchUser from '@/components/search-user';

/**
 * APIs
 */
import { userTnsLogs } from '@/services/user';
import { operators } from '@/services/operator';

/**
 * Types
 */
import type { FormProps, PaginationProps } from 'antd';
import type { UserAction, UserTnsLogs, UserTnsLogsReq } from '@/services/user';
import type { OperatorsResponse } from '@/services/operator';

type State = {
    dataSource: UserTnsLogs[];
    operators: OperatorsResponse[];
};

/**
 * Constants
 */
const defaultPayload = {
    nickname: undefined,
    user_id: undefined,
    username: undefined,
};
const prefileResetTypes: Record<string, React.ReactNode> = {
    avatar: <FormattedMessage id="p.uu.action.avatar" />,
    name: <FormattedMessage id="p.uu.action.name" />,
    nickname: <FormattedMessage id="p.uu.action.nickname" />,
    bio: <FormattedMessage id="p.uu.action.bio" />,
};

const PageSanctionHistory: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    const [formSearch] = Form.useForm();

    const { formatMessage } = useIntl();

    const searchRef = useRef<HTMLDivElement>(null);

    const [state, setState] = useSetState<State>({
        dataSource: [],
        operators: [],
    });

    /**
     * Paginations
     */
    const [pagination, setPagination] = useSetState<PaginationProps>({
        current: 1,
        pageSize: 20,
        onChange: (page, pageSize) => {
            setPagination({
                current: page,
                pageSize,
            });
        },
    });

    /**
     * Payloads
     */
    const [payload, setPayload] = useSetState<UserTnsLogsReq>({
        ...defaultPayload,
        operation_start: dayjs().startOf('d').format(),
        operation_end: dayjs().endOf('d').format(),
    });

    /**
     * Requests
     */
    useRequest(operators, {
        defaultParams: [{ limit: 9999, model: 'Users_Sanction-History', page: 1 }],
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setState({
                operators: data.list,
            });
        },
    });

    const {
        loading,
        refresh: refreshUserList,
        run: runUserList,
    } = useRequest(userTnsLogs, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setPagination({
                current: data.meta.currentPage,
                pageSize: data.meta.perPage,
                total: data.meta.total,
            });

            setState({
                dataSource: data.list,
            });
        },
    });

    /**
     * ChildrenProps
     */
    const formSearchProps: FormProps = {
        form: formSearch,
        initialValues: {
            date: [dayjs(payload.operation_start), dayjs(payload.operation_end)],
        },
        layout: 'inline',
        onFinish: (values) => {
            if (values.date && values.date.length > 0) {
                values.operation_start = values.date[0].startOf('d').format();
                values.operation_end = values.date[1].endOf('d').format();
                delete values.date;
            }

            const types: UserAction[] = [];

            if (Array.isArray(values.type)) {
                for (const item of values.type) {
                    if (item.length === 1 && item.includes('mute')) {
                        types.push('temp_mute');
                        types.push('perma_mute');
                    } else if (item.length === 1 && item.includes('disable')) {
                        types.push('temp_ban');
                        types.push('perma_ban');
                    } else {
                        types.push(item[item.length - 1]);
                    }
                }
            }

            delete values.type;

            setPagination({
                current: 1,
                pageSize: 20,
            });

            setPayload({
                ...defaultPayload,
                operation_start: undefined,
                operation_end: undefined,
                operator_account_id: undefined,
                ...omitBy(values, isEmpty),
                types,
            });
        },
    };

    const proTableProps: ProTableProps<UserTnsLogs, any> = {
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                valueType: 'index',
                title: 'No.',
                width: 50,
                fixed: 'left',
            },
            {
                dataIndex: 'user_id',
                title: <FormattedMessage id="t.c.user" />,
                width: 200,
                render: (_, entity) => {
                    const { name = '-', nickname = '-' } = entity.userInfo || {};

                    return (
                        <>
                            <div>{nickname}</div>

                            <Typography.Text copyable={{ text: entity.user_id, tooltips: 'Copy UID' }}>
                                @{name}
                            </Typography.Text>
                        </>
                    );
                },
            },
            {
                dataIndex: 'type',
                valueType: 'select',
                width: 120,
                title: <FormattedMessage id="t.c.type" />,
                valueEnum: {
                    temp_mute: <FormattedMessage id="p.ush.types.temp_mute" />,
                    perma_mute: <FormattedMessage id="p.ush.types.perma_mute" />,
                    temp_ban: <FormattedMessage id="p.ush.types.temp_ban" />,
                    perma_ban: <FormattedMessage id="p.ush.types.perma_ban" />,
                    unmute: <FormattedMessage id="p.ush.types.unmute" />,
                    unban: <FormattedMessage id="p.ush.types.unban" />,
                    profile: <FormattedMessage id="p.uu.btn.mangProfile" />,
                },
            },
            {
                dataIndex: 'detail',
                width: 200,
                title: <FormattedMessage id="p.ush.columns.detail" />,
                render: (_, record) => {
                    if (record.type === 'profile') {
                        return (
                            <>
                                {record.content.map((v) => {
                                    return <div key={v}>{prefileResetTypes[v]}</div>;
                                })}
                            </>
                        );
                    }
                    return (
                        <span>
                            {!['temp_mute', 'perma_mute', 'temp_ban', 'perma_ban'].includes(record.type) && (
                                <span>-</span>
                            )}
                            {record.type === 'temp_mute' && record.period && record.period_unit && (
                                <>
                                    <FormattedMessage id={`p.uu.status.muted.mutedAt`} />: &nbsp;
                                    {record.period}&nbsp;
                                    <FormattedMessage id={`p.uu.cycleType.${record.period_unit}`} />
                                </>
                            )}
                            {record.type === 'perma_mute' && (!record.period || !record.period_unit) && (
                                <>
                                    <FormattedMessage id={`p.uu.status.muted.mutedAt`} />: &nbsp;
                                    <FormattedMessage id="p.uu.status.permanent" />
                                </>
                            )}
                            {record.type === 'temp_ban' && record.period && record.period_unit && (
                                <>
                                    <FormattedMessage id={`p.uu.status.account.disabledAt`} />: &nbsp;
                                    {record.period}&nbsp;
                                    <FormattedMessage id={`p.uu.cycleType.${record.period_unit}`} />
                                </>
                            )}
                            {record.type === 'perma_ban' && (!record.period || !record.period_unit) && (
                                <>
                                    <FormattedMessage id={`p.uu.status.account.disabledAt`} />: &nbsp;
                                    <FormattedMessage id="p.uu.status.permanent" />
                                </>
                            )}
                        </span>
                    );
                },
            },
            {
                dataIndex: 'remark',
                title: <FormattedMessage id="p.ush.columns.remark" />,
            },
            {
                dataIndex: ['operator_account', 'name'],
                width: 200,
                title: <FormattedMessage id="t.c.operatedBy" />,
            },
            {
                dataIndex: 'created_at',
                width: 200,
                title: <FormattedMessage id="t.c.operatedAt" />,
                valueType: 'dateTime',
            },
        ],
        dataSource: state.dataSource,
        loading,
        pagination,
        options: {
            reload: refreshUserList,
        },
        rowKey: 'id',
        scroll: {
            x: 1080,
            y: `calc(100vh - 224px - ${searchRef.current?.clientHeight || 64}px - 16px - 48px)`,
        },
        search: false,
        tableAlertRender: false,
    };

    /**
     * Effects
     */
    useEffect(() => {
        console.log('runUserList', payload);
        runUserList({
            page: pagination.current,
            limit: pagination.pageSize,
            ...payload,
        });
    }, [pagination.current, pagination.pageSize, payload]);

    return (
        <>
            <ProCard className="mb-16" ref={searchRef}>
                <Form {...formSearchProps}>
                    <Flex gap={16} wrap={true}>
                        <SearchUser form={formSearch} />

                        <Form.Item name="type" style={{ marginRight: 0 }}>
                            <Cascader
                                multiple={true}
                                allowClear={true}
                                options={[
                                    {
                                        label: <FormattedMessage id="p.ush.action.mute" />, // 禁言组标题
                                        value: 'mute',
                                        children: [
                                            {
                                                label: <FormattedMessage id="p.ush.types.temp_mute" />,
                                                value: 'temp_mute',
                                            },
                                            {
                                                label: <FormattedMessage id="p.ush.types.perma_mute" />,
                                                value: 'perma_mute',
                                            },
                                        ],
                                    },
                                    {
                                        label: <FormattedMessage id="p.ush.action.disable" />, // 封号组标题
                                        value: 'disable',
                                        children: [
                                            {
                                                label: <FormattedMessage id="p.ush.types.temp_ban" />,
                                                value: 'temp_ban',
                                            },
                                            {
                                                label: <FormattedMessage id="p.ush.types.perma_ban" />,
                                                value: 'perma_ban',
                                            },
                                        ],
                                    },
                                    {
                                        label: <FormattedMessage id="p.ush.action.unmute" />,
                                        value: 'unmute',
                                    },
                                    {
                                        label: <FormattedMessage id="p.ush.action.enabled" />,
                                        value: 'unban',
                                    },
                                    {
                                        label: <FormattedMessage id="p.uu.btn.mangProfile" />,
                                        value: 'profile',
                                    },
                                ]}
                                placeholder={<FormattedMessage id="t.c.type" />}
                                showSearch={true}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="operator_account_id" style={{ marginRight: 0 }}>
                            <Select
                                allowClear={true}
                                optionFilterProp="label"
                                options={state.operators.map((operator) => ({
                                    label: operator.account.name,
                                    value: operator.account.id,
                                }))}
                                placeholder={<FormattedMessage id="t.c.operatedBy" />}
                                showSearch={true}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item
                            label={formatMessage({ id: 't.c.operatedAt' })}
                            name="date"
                            style={{ marginRight: 0 }}
                        >
                            <DatePicker.RangePicker allowClear={true} />
                        </Form.Item>

                        <Button
                            loading={loading}
                            type="primary"
                            onClick={() => {
                                formSearch.submit();
                            }}
                        >
                            <FormattedMessage id="b.search" />
                        </Button>

                        <Button
                            onClick={() => {
                                formSearch.resetFields();
                                formSearch.setFieldValue('date', [dayjs().startOf('d'), dayjs().endOf('d')]);
                            }}
                        >
                            <FormattedMessage id="b.reset" />
                        </Button>
                    </Flex>
                </Form>
            </ProCard>

            <ProTable {...proTableProps} />
        </>
    );
};

export default PageSanctionHistory;
