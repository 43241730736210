export default {
    su: {
        c: {
            debate: 'Reply',
            follow: 'Follow',
            like: 'Like',
            quote: 'Quote',
            share: 'Repost',
            vote: 'Vote',
        },
        t: {
            c: {
                airdropPeriodRange: 'Expiration Period(day)',
                airdropCountRange: 'Airdrop Recipient Range',
                domain: 'Domain',
                action: 'Behavior',
                targetReward: 'Tier/Benefit Value',
                accountLevel: 'Level',
                binifit: 'Benefit Value',
                rateSale: 'Fee Discount',
                airdropCount: 'Airdrop Recipients',
                airdropExprise: 'Expiration Period(day)',
                airdropReson: 'Airdrop Condition',
                groupCount: 'Max Group Size',
            },
        },
        f: {
            pleaseInputUID: 'Please entry UID',
            uidFormatError: '请输入正确的uid',
            AccountExist: 'Account already exists',
            airdropCountRangeError: 'a positive integer between 1 and 1000000',
            airdropPeriodRangeError: 'a positive integer between 1 and 7',
            AccountNotExist: 'Account not found',
            MaxAirdropLtMin: '最大空头人数要大于最小空投人数',
            uidRepeat: 'UID already exists',
            domainFormateError: 'Domain format error',
            domainExit: 'Account already exists',
            actionLabel: 'Tier {value}',
            targetReward: 'Benefit Value {value}',
        },
    },
};
