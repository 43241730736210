import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { App, Button, Drawer, Form, Input, Popconfirm, Space, Typography, Upload } from 'antd';
import { DragSortTable } from '@ant-design/pro-components';
import { HolderOutlined, PlusOutlined } from '@ant-design/icons';
import { useRequest, useSetState } from 'ahooks';
import { cloneDeep, get } from 'lodash';
import store from 'store2';

/**
 * APIs
 */
import { checkPostId, uploadImage } from '@/services/common';
import { uBlock } from '@/services/square';

/**
 * Stores
 */
import { useDigitizedCivilizationSquareStore } from '@/store/digitized-civilization-square';

/**
 * Types
 */
import type { DragTableProps } from '@ant-design/pro-components';
import type { DrawerProps, FormProps, UploadProps } from 'antd';

type DataType = {
    created_at: string;
    encrypt_post_id: string;
    id: string;
    image: string;
    operator: string;
    title: string;
    updated_at: string;
};

type State = {
    index: number;
    open: boolean;
    type: 'create' | 'update';
    uploadPoster: boolean;
    sorting: boolean;
};

const ComponentBlockShorts: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    const [form] = Form.useForm();

    const intl = useIntl();

    /**
     * States
     */
    const [state, setState] = useSetState<State>({
        index: -1,
        open: false,
        type: 'create',
        uploadPoster: true,
        sorting: false,
    });

    const { current, dataSource, setCurrent, setDataSource, setRefreshDataSource } =
        useDigitizedCivilizationSquareStore();

    /**
     * Requests
     */
    const { loading: loadingCheckPostId } = useRequest(checkPostId, {
        manual: true,
    });

    const { loading: loadingUBlock, run: runUBlock } = useRequest(uBlock, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code === 150203) {
                return message.error('It has been edited by someone else, please refresh');
            }

            if (code !== 0) {
                if (typeof msg !== 'string') {
                    return message.error('Error');
                }
                return message.error(msg);
            }

            const prevData = dataSource.find((item) => item.type === current!.type);

            const index = prevData!.children!.findIndex((item) => item.id === current!.id);

            prevData!.children![index] = {
                ...current!,
                ...data,
            };

            setCurrent(data);

            setDataSource(dataSource);

            setState({
                open: false,
                uploadPoster: true,
                sorting: false,
            });

            message.success('Updated');
        },
    });

    /**
     * ChildrenProps
     */
    const dragTableProps: DragTableProps<DataType, any> = {
        bordered: true,
        columns: [
            {
                title: <FormattedMessage id="tc.t.c.sort" />,
                dataIndex: 'sort',
                width: 50,
                className: 'drag-visible',
                render: () => {
                    if (state.sorting) {
                        return null;
                    }
                    return (
                        <Button
                            type="text"
                            size="small"
                            icon={<HolderOutlined />}
                            style={{ cursor: 'not-allowed', opacity: 0.5 }}
                        />
                    );
                },
            },
            {
                dataIndex: 'index',
                fixed: 'left',
                title: 'No.',
                width: 50,
                render: (_, __, index) => index + 1,
            },
            {
                dataIndex: 'id',
                title: <FormattedMessage id="cp.t.c.shortsId" />,
                width: 200,
            },
            {
                dataIndex: 'title',
                title: <FormattedMessage id="cad.t.c.title" />,
            },
            {
                dataIndex: 'image',
                title: <FormattedMessage id="cp.f.videoCover" />,
                width: 120,
                valueType: 'image',
            },
            {
                dataIndex: 'operator',
                title: <FormattedMessage id="t.c.operatedBy" />,
                width: 120,
            },
            {
                dataIndex: 'updated_at',
                title: <FormattedMessage id="t.c.operatedAt" />,
                valueType: 'dateTime',
                width: 200,
            },
            {
                fixed: 'right',
                key: 'option',
                title: <FormattedMessage id="t.c.action" />,
                valueType: 'option',
                width: 120,
                render: (_, entity, index) => [
                    <a
                        key="update"
                        onClick={() => {
                            form.setFieldsValue({
                                ...entity,
                                image: entity.image
                                    ? [
                                          {
                                              name: 'image',
                                              url: entity.image,
                                          },
                                      ]
                                    : [],
                            });

                            setState({
                                index,
                                open: true,
                                type: 'update',
                                uploadPoster: !entity.image,
                            });
                        }}
                    >
                        <FormattedMessage id="b.edit" />
                    </a>,
                    <Popconfirm
                        cancelText="No"
                        key="delete"
                        okText="Yes"
                        title="Are you sure?"
                        onConfirm={() => {
                            current!.data.splice(index, 1);

                            runUBlock(current!.id, {
                                data: current!.data,
                                updated_at: current!.updated_at,
                            });
                        }}
                    >
                        <Typography.Link type="danger">
                            <FormattedMessage id="b.delete" />
                        </Typography.Link>
                    </Popconfirm>,
                ],
            },
        ],
        dataSource: current!.data,
        dragSortKey: state.sorting ? 'sort' : '_not_sortable',
        dragSortHandlerRender: () => (
            <Button type="text" size="small" icon={<HolderOutlined />} style={{ cursor: 'move' }} />
        ),
        headerTitle: current!.name,
        options: {
            reload: () => {
                setRefreshDataSource();
            },
        },
        pagination: false,
        rowKey: 'id',
        scroll: {
            x: 1060,
        },
        search: false,
        toolBarRender: () => {
            // 排序中的按钮
            if (state.sorting) {
                return [
                    <Button
                        type="primary"
                        loading={loadingUBlock}
                        onClick={() => {
                            runUBlock(current!.id, {
                                data: current!.data,
                                updated_at: current!.updated_at,
                            });
                        }}
                        key="sureSort"
                    >
                        <FormattedMessage id="tc.b.sureSort" />
                    </Button>,

                    <Button
                        loading={loadingUBlock}
                        onClick={() => {
                            setState({ sorting: false });
                            const prevData = dataSource.find((item) => item.type === current!.type);

                            const index = prevData!.children!.findIndex((item) => item.id === current!.id);

                            const oldCurrent = prevData!.children![index];

                            setCurrent({ ...oldCurrent });
                        }}
                        key="cancelSort"
                    >
                        <FormattedMessage id="tc.b.cancelSort" />
                    </Button>,
                ];
            }

            // 默认按钮组
            return [
                <Button
                    disabled={current!.data?.length >= current!.max_item_count}
                    key="add"
                    type="primary"
                    onClick={() => {
                        setState({
                            open: true,
                            type: 'create',
                        });
                    }}
                >
                    <FormattedMessage id="b.add" />
                </Button>,

                <Button
                    key="sort"
                    type="primary"
                    ghost
                    onClick={() => {
                        setState({ sorting: true });
                    }}
                >
                    <FormattedMessage id="tc.t.c.sort" />
                </Button>,
            ];
        },
        onDragSortEnd: (_, __, newDataSource) => {
            setCurrent({ ...current!, data: newDataSource });
        },
    };

    const drawerProps: DrawerProps = {
        footer: (
            <div style={{ textAlign: 'right' }}>
                <Space>
                    <Button
                        loading={loadingCheckPostId || loadingUBlock}
                        type="primary"
                        onClick={() => {
                            form.validateFields()
                                .then((values) => {
                                    if (
                                        current!.data?.some(
                                            (item: DataType, index: number) =>
                                                item.id === values.id && index !== state.index,
                                        )
                                    ) {
                                        return message.error(
                                            intl.formatMessage({ id: 'p.plazaConfig.shorts.f.idRepeat' }),
                                        );
                                    }

                                    const prevData = cloneDeep(current!.data) || [];

                                    // 创建
                                    if (state.type === 'create') {
                                        prevData.push({
                                            ...values,
                                            created_at: new Date().toISOString(),
                                            image: get(values, 'image.0.url', ''),
                                            operator: store('user').name,
                                            updated_at: new Date().toISOString(),
                                        });
                                    }
                                    // 编辑
                                    else {
                                        prevData[state.index] = {
                                            ...prevData[state.index],
                                            ...values,
                                            image: get(values, 'image.0.url', ''),
                                            operator: store('user').name,
                                            updated_at: new Date().toISOString(),
                                        };
                                    }

                                    runUBlock(current!.id, {
                                        data: prevData,
                                        updated_at: current!.updated_at,
                                    });
                                })
                                .catch(() => {});
                        }}
                    >
                        <FormattedMessage id="b.save" />
                    </Button>
                </Space>
            </div>
        ),
        maskClosable: false,
        open: state.open,
        title: state.type === 'create' ? <FormattedMessage id="b.add" /> : <FormattedMessage id="b.edit" />,
        afterOpenChange: (open) => {
            if (!open) {
                form.resetFields();
            }
        },
        onClose: () => {
            setState({
                open: false,
                uploadPoster: true,
            });
        },
    };

    const formProps: FormProps = {
        form,
        layout: 'vertical',
    };

    const uploadProps: UploadProps = {
        accept: 'image/jpg,image/jpeg,image/png',
        listType: 'picture-card',
        maxCount: 1,
        customRequest: (options) => {
            uploadImage({ file: options.file as File }).then(options.onSuccess, options.onError);
        },
    };

    useEffect(() => {
        setState({
            sorting: false,
        });
    }, [current?.id]);

    return (
        <>
            <DragSortTable {...dragTableProps} />

            <Drawer {...drawerProps}>
                <Form {...formProps}>
                    <Form.Item
                        label={intl.formatMessage({ id: 'cp.t.c.shortsId' })}
                        name="id"
                        rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label={intl.formatMessage({ id: 'cad.t.c.title' })}
                        name="title"
                        rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        extra="Size: 327*436 (width x height), JPG/JPEG/PNG, within 3 MB"
                        label={intl.formatMessage({ id: 'cp.f.videoCover' })}
                        name="image"
                        valuePropName="fileList"
                        getValueFromEvent={(e: any) => {
                            setState({
                                uploadPoster: e.file.status !== 'done' || e.file.response?.data?.code !== 0,
                            });

                            if (e.file.status === 'done') {
                                e.file.url = e.file.response.data.data?.url;

                                if (e.file.response.data.code !== 0) {
                                    message.error(e.file.response.data.msg);
                                    return [];
                                }
                            }

                            return e.fileList;
                        }}
                    >
                        <Upload {...uploadProps}>
                            {state.uploadPoster && (
                                <div>
                                    <PlusOutlined />

                                    <div style={{ marginTop: 8 }}>{intl.formatMessage({ id: 'b.upload' })}</div>
                                </div>
                            )}
                        </Upload>
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    );
};

export default ComponentBlockShorts;
