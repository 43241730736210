import { useEffect, useRef, useState } from 'react';
import { useRequest, useSetState } from 'ahooks';
import { App, Button, DatePicker, Flex, Form, Input, PaginationProps, Select } from 'antd';
import { ProCard, ProTable } from '@ant-design/pro-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { isEmpty, omitBy, trim } from 'lodash';

/**
 * Components
 */
import ComponentDrawerFormCustom from './components/drawer-form-custom';
import ComponentSearchFormSelectOperator from '@/components/search-form-select-operator';

/**
 * Constant
 */
const StatusEnumMap = {
    pending: <FormattedMessage id="oa.p.ae.s.notStarted" />,
    in_progress: <FormattedMessage id="oa.p.ae.s.inProgress" />,
    completed: <FormattedMessage id="p.exports.status.completed" />,
    paused: <FormattedMessage id="oa.p.ae.s.paused" />,
    failed: <FormattedMessage id="oa.p.ae.s.failed" />,
};
const StatusOptions = Object.entries(StatusEnumMap).map(([key, value]) => ({
    label: value,
    value: key,
}));

/**
 * APIs
 */
import { gIMMessage } from '@/services/im-message';

/**
 * Types
 */
import type { ActionType, FormProps, ProTableProps } from '@ant-design/pro-components';
import type { IntlShape } from 'react-intl';
import type { IMMessageParams, IMMessageResult } from '@/services/im-message';

/**
 * State
 */
type State = {
    dataSource: IMMessageResult[];
    openDrawer: boolean;
};

const PageIMMessage: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    const [formSearch] = Form.useForm();

    const { formatMessage } = useIntl();

    const searchRef = useRef<HTMLDivElement>(null);

    const tableRef = useRef<ActionType>();

    /**
     * Paginations
     */
    const [pagination, setPagination] = useSetState<PaginationProps>({
        current: 1,
        pageSize: 20,
        onChange: (page, pageSize) => {
            setPagination({
                current: page,
                pageSize,
            });
        },
    });

    /**
     * States
     */
    const [state, setState] = useSetState<State>({
        dataSource: [],
        openDrawer: false,
    });
    const intl: IntlShape = useIntl();

    /**
     * Payloads
     */
    const [payload, setPayload] = useState<IMMessageParams>({});

    const {
        loading: loadingIMMessage,
        refresh: refreshIMMessage,
        run: runIMMessage,
    } = useRequest(gIMMessage, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setState({
                dataSource: data.list.map((v) => {
                    const feLanguages: Record<string, string> = {};
                    (v.languages || []).forEach((v) => {
                        feLanguages[v.language.toLocaleLowerCase()] = v.value;
                    });
                    return {
                        ...v,
                        feLanguages,
                    };
                }),
            });

            setPagination({
                total: data.meta.total,
            });
        },
    });

    /**
     * ChildrenProps
     */
    const proTableProps: ProTableProps<IMMessageResult, any> = {
        actionRef: tableRef,
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                title: 'No.',
                width: 50,
                fixed: 'left',
                hideInSearch: true,
                render: (_, __, index) => index + 1,
            },
            {
                dataIndex: ['feLanguages', 'en'],
                ellipsis: true,
                title: 'EN',
                width: 200,
            },
            {
                dataIndex: ['feLanguages', 'chs'],
                ellipsis: true,
                title: 'CHS',
                width: 200,
            },
            {
                dataIndex: ['feLanguages', 'cht'],
                ellipsis: true,
                title: 'CHT',
                width: 200,
            },
            {
                dataIndex: ['feLanguages', 'jpn'],
                ellipsis: true,
                title: 'JPN',
                width: 200,
            },
            {
                dataIndex: ['feLanguages', 'vie'],
                ellipsis: true,
                title: 'VIE',
                width: 200,
            },
            {
                dataIndex: ['feLanguages', 'trk'],
                ellipsis: true,
                title: 'TRK',
                width: 200,
            },
            {
                dataIndex: ['feLanguages', 'esp'],
                ellipsis: true,
                title: 'ESP',
                width: 200,
            },
            {
                dataIndex: ['feLanguages', 'pt'],
                ellipsis: true,
                title: 'PT',
                width: 200,
            },
            {
                dataIndex: 'user_coverage',
                ellipsis: true,
                title: <FormattedMessage id="im.t.c.usersCovered" />,
                width: 200,
            },
            {
                dataIndex: 'target_user_count',
                ellipsis: true,
                title: <FormattedMessage id="im.t.c.totalUsers" />,
                width: 200,
            },
            {
                dataIndex: 'status',
                ellipsis: true,
                title: <FormattedMessage id="t.c.status" />,
                valueEnum: StatusEnumMap,
                width: 200,
            },
            {
                dataIndex: ['operator_account', 'name'],
                ellipsis: true,
                title: <FormattedMessage id="t.c.operatedBy" />,
                width: 200,
            },
            {
                dataIndex: 'updated_at',
                valueType: 'dateTime',
                title: <FormattedMessage id="t.c.operatedAt" />,
                hideInSearch: true,
            },
        ],
        search: false,
        dataSource: state.dataSource,
        loading: loadingIMMessage,
        options: {
            reload: refreshIMMessage,
        },
        pagination,
        rowKey: 'id',
        scroll: {
            y: `calc(100vh - 280px - ${searchRef.current?.clientHeight}px - 8px)`,
            x: 2650,
        },
        tableAlertRender: false,
        toolBarRender: () => [
            <Button
                type="primary"
                onClick={() => {
                    setState({ openDrawer: true });
                }}
                key="sureSort"
            >
                <FormattedMessage id="b.add" />
            </Button>,
        ],
    };

    const formSearchProps: FormProps<IMMessageParams> = {
        form: formSearch,
        layout: 'inline',
        onFinish: (values) => {
            if (values.updated_date) {
                values.updated_start = values.updated_date[0].startOf('d').format();
                values.updated_end = values.updated_date[1].endOf('d').format();
                values.updated_date = undefined;
            }

            if (values.en_message) {
                values.en_message = trim(values.en_message);
            }

            setPagination({
                current: 1,
            });
            setPayload({
                ...omitBy(values, isEmpty),
            });
        },
    };

    /**
     * Effects
     */
    useEffect(() => {
        runIMMessage({
            ...payload,
            page: pagination.current as any,
            limit: pagination.pageSize as any,
        });
    }, [pagination.current, pagination.pageSize, payload]);

    return (
        <>
            <ProCard className="mb-16" ref={searchRef}>
                <Form {...formSearchProps}>
                    <Flex gap={16} wrap={true}>
                        <Form.Item name="en_message" style={{ marginRight: 0 }}>
                            <Input allowClear={true} placeholder={'EN'} style={{ width: 200 }} />
                        </Form.Item>

                        <Form.Item name="status" style={{ marginRight: 0 }}>
                            <Select
                                allowClear={true}
                                optionFilterProp="label"
                                options={StatusOptions}
                                placeholder={intl.formatMessage({ id: 't.c.status' })}
                                showSearch={true}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="operator_account_id" style={{ marginRight: 0 }}>
                            <ComponentSearchFormSelectOperator model="IM_Messages" />
                        </Form.Item>

                        <Form.Item
                            label={formatMessage({ id: 't.c.operatedAt' })}
                            name="updated_date"
                            style={{ marginRight: 0 }}
                        >
                            <DatePicker.RangePicker allowClear={true} />
                        </Form.Item>

                        <Button
                            loading={loadingIMMessage}
                            type="primary"
                            onClick={() => {
                                formSearch.submit();
                            }}
                        >
                            <FormattedMessage id="b.search" />
                        </Button>

                        <Button
                            onClick={() => {
                                formSearch.resetFields();
                            }}
                        >
                            <FormattedMessage id="b.reset" />
                        </Button>
                    </Flex>
                </Form>
            </ProCard>

            <ProTable {...proTableProps} />

            <ComponentDrawerFormCustom
                open={state.openDrawer}
                closeModelForm={(reload) => {
                    setState({ openDrawer: false });
                    reload && refreshIMMessage();
                }}
            />
        </>
    );
};

export default PageIMMessage;
