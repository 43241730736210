import { useEffect } from 'react';
import { useBoolean } from 'ahooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { Drawer, Form, Button, Input, App, Row, Col, Select, ColorPicker, Switch } from 'antd';
import { updateTokenConfigs, updateTokenConfigsStatus } from '@/services/token-config';
import { get } from 'lodash';

/**
 * Types
 */
import type { IntlShape } from 'react-intl';
import type { TokenConfig } from '@/services/token-config';

export type ModalFormState = {
    open: boolean;
    data?: TokenConfig;
};

type Props = {
    state: ModalFormState;
    reload?: () => void;
    onClose: () => void;
};

const ModalFormTokenConfig: React.FC<Props> = ({ state, onClose, reload }) => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    /**
     * State
     */
    const [submitting, { setFalse: setSubmittingFlase, setTrue: setSubmittingTrue }] = useBoolean(false);
    const [form] = Form.useForm();
    const intl: IntlShape = useIntl();

    /**
     * Effect
     */
    useEffect(() => {
        if (!state.open) {
            form.resetFields();
            setSubmittingFlase();
        } else if (state.data) {
            form.setFieldsValue({
                ...state.data,
                status: !!state.data.status,
            });
        }
    }, [state]);

    // 获取小数点后的位数
    const getDecimals = (value: string) => {
        const [integer, decimal] = value.split('.');
        console.log('integer', integer);
        return decimal?.length || 0;
    };

    const handleFinish = async (formData: any) => {
        const minTopUpDecimals = getDecimals(formData.min_top_up);
        const priceScale = formData.price_scale;
        const showScale = formData.show_scale;

        const decimal = formData.decimals || 0;

        if (minTopUpDecimals > decimal || priceScale > decimal || showScale > decimal) {
            message.error(intl.formatMessage({ id: 'p.tc.message.decimalInvalid' }));
            return;
        }

        setSubmittingTrue();
        // 更新代币 status
        if (!!state?.data?.status !== !!formData.status) {
            try {
                const resStatus = await updateTokenConfigsStatus(state.data?.token?.id as string, {
                    status: formData.status ? 1 : 0,
                });
                if (get(resStatus, 'data.code') !== 0) {
                    message.error(get(resStatus, 'data.msg', 'Error'));
                    setSubmittingFlase();
                    return;
                }
            } catch (error) {
                message.error(get(error, 'response.data.msg', 'Error'));
                setSubmittingFlase();
                return;
            }
        }
        try {
            // 更新配置
            const res = await updateTokenConfigs(state.data?.token?.id || '', {
                color: typeof formData.color === 'string' ? formData.color : formData.color?.toHexString?.(),
                sort: formData.sort,
                price_scale: Number(formData.price_scale),
                show_scale: Number(formData.show_scale),
                // min_top_up: formData.min_top_up,
                // min_trade_amount: formData.min_trade_amount,
                type: formData.type,
                // status: formData.status ? 1 : 0,
                updated_at: state.data?.updated_at || '',
            });
            if (get(res, 'data.code') === 0) {
                message.success(intl.formatMessage({ id: 'common.submitSuccess' }));
                onClose();
                reload?.();
            } else {
                message.error(get(res, 'data.msg', intl.formatMessage({ id: 'common.undefinedError' })));
            }
            setSubmittingFlase();
        } catch (error) {
            message.error(get(error, 'response.data.msg', 'Error'));
            setSubmittingFlase();
        }
    };

    /**
     * ChildrenProps
     */
    const drawerProps = {
        title: state.data?.id ? <FormattedMessage id="b.edit" /> : <FormattedMessage id="b.add" />,
        onClose: () => onClose(),
        maskClosable: false,
        open: state.open,
        destroyOnClose: true,
        footer: (
            <div style={{ textAlign: 'right' }}>
                <Button onClick={() => onClose()} style={{ marginRight: 8 }}>
                    {intl.formatMessage({ id: 'common.cancel' })}
                </Button>
                <Button onClick={() => form.submit()} type="primary" loading={submitting}>
                    {intl.formatMessage({ id: 'common.sure' })}
                </Button>
            </div>
        ),
    };

    return (
        <Drawer {...drawerProps}>
            <Form form={form} layout="vertical" onFinish={handleFinish}>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="id"
                            label="Token ID"
                            rules={[{ required: true, message: intl.formatMessage({ id: 'common.notEmpty' }) }]}
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name={['token', 'label']}
                            label="Token"
                            rules={[{ required: true, message: intl.formatMessage({ id: 'common.notEmpty' }) }]}
                        >
                            <Input
                                addonBefore={
                                    state?.data?.token?.url ? (
                                        <img style={{ width: '20px', height: '20px' }} src={state?.data?.token?.url} />
                                    ) : undefined
                                }
                                disabled
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="color"
                            label={<FormattedMessage id="p.tc.column.color" />}
                            rules={[{ required: true, message: intl.formatMessage({ id: 'common.notEmpty' }) }]}
                        >
                            <ColorPicker disabledAlpha={true} format="hex" showText />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="sort"
                            label={<FormattedMessage id="p.tc.column.sort" />}
                            rules={[
                                { required: true, message: intl.formatMessage({ id: 'common.notEmpty' }) },
                                {
                                    pattern: /^\d{8}$/,
                                    message: intl.formatMessage({ id: 'p.tc.message.sort' }),
                                },
                            ]}
                        >
                            <Input disabled={['USDT', 'USDC'].includes(state?.data?.token?.label?.toString() || '')} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="price_scale"
                            tooltip={<FormattedMessage id="p.tc.tips.priceScale" />}
                            label={<FormattedMessage id="p.tc.column.priceScale" />}
                            rules={[
                                { required: true, message: intl.formatMessage({ id: 'common.notEmpty' }) },
                                {
                                    pattern: /^[0-9]$|^10$/,
                                    message: intl.formatMessage({ id: 'p.tc.message.invalidNumber' }),
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="show_scale"
                            tooltip={<FormattedMessage id="p.tc.tips.showScale" />}
                            label={<FormattedMessage id="p.tc.column.showScale" />}
                            rules={[
                                { required: true, message: intl.formatMessage({ id: 'common.notEmpty' }) },
                                {
                                    pattern: /^[0-9]$|^10$/,
                                    message: intl.formatMessage({ id: 'p.tc.message.invalidNumber' }),
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="decimals"
                            label={<FormattedMessage id="p.tc.column.decimals" />}
                            rules={[{ required: true, message: intl.formatMessage({ id: 'common.notEmpty' }) }]}
                        >
                            <Input disabled={!!state.data?.id} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="min_top_up"
                            label={<FormattedMessage id="p.tc.column.minTopUp" />}
                            rules={[
                                { required: true, message: intl.formatMessage({ id: 'common.notEmpty' }) },
                                {
                                    pattern: /^(0|[1-9]\d{0,10})(\.\d{1,10})?$/,
                                    message: intl.formatMessage({ id: 'p.tc.message.amountInvalid' }),
                                },
                            ]}
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="min_trade_amount"
                            label={<FormattedMessage id="p.tc.column.minTradeAmount" />}
                            rules={[
                                { required: true, message: intl.formatMessage({ id: 'common.notEmpty' }) },
                                {
                                    pattern: /^(0|[1-9]\d{0,10})(\.\d{1,10})?$/,
                                    message: intl.formatMessage({ id: 'p.tc.message.amountInvalid' }),
                                },
                            ]}
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="type"
                            label={<FormattedMessage id="t.c.type" />}
                            rules={[{ required: true, message: intl.formatMessage({ id: 'common.notEmpty' }) }]}
                        >
                            <Select
                                options={[
                                    { label: <FormattedMessage id="p.tc.column.type.majorToken" />, value: 1 },
                                    { label: <FormattedMessage id="p.tc.column.type.trustedToken" />, value: 2 },
                                    { label: <FormattedMessage id="p.tc.column.type.mintableToken" />, value: 3 },
                                    { label: <FormattedMessage id="p.tc.column.type.blacklist" />, value: 4 },
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="status"
                            label={<FormattedMessage id="common.status" />}
                            rules={[{ required: true, message: intl.formatMessage({ id: 'common.notEmpty' }) }]}
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    );
};

export default ModalFormTokenConfig;
