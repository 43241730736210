export default {
    im: {
        t: {
            c: {
                usersCovered: '覆盖用户数',
                totalUsers: '目标用户数',
            },
        },
    },
};
