import axios from './index';

/**
 * APIs
 */
// table list
export const airdropAccListApi = (query: Record<string, any>) => {
    return axios<ResType<AirdropAccListResult[]>>('/v1/airdrop/posts', {
        method: 'get',
        params: query,
    });
};

// delete airdrop account by id
export const deleteAirAccByIdApi = (recordId: string) => {
    return axios<ResType<AirdropAccItem>>(`/v1/airdrop/posts/${recordId}`, {
        method: 'delete',
    });
};

// create airdrop account
export const createAirAccApi = (data: CreateAirdropAccData) => {
    return axios<ResType<AirdropAccItem>>('/v1/airdrop/posts', {
        method: 'post',
        data,
    });
};

// update airdrop account
export const updateAirAccApi = (id: string, data: UpdateAirDropAccData) => {
    return axios<ResType<AirdropAccItem>>(`/v1/airdrop/posts/${id}`, {
        data,
        method: 'put',
    });
};

// query airdrop account by uid
export const queryAirAccByUidApi = (uid: string) => {
    return axios<ResType<AirdropAccItem>>(`/v1/airdrop/posts/uid/${uid}`, {
        method: 'get',
    });
};

/**
 * Error Code
 */

export const AirdropAccApiErrorCodeMsg: Record<number, string> = {
    190101: 'su.f.AccountExist', // 账户已存在
    190102: 'su.f.AccountNotExist', // 账户不存在
    190201: 'su.f.MaxAirdropLtMin', // 最大空头人数要大于最小空投人数
};

/**
 * Types
 */
import type { ResType } from '@repo/typings/common';

// airdrop account item
export type AirdropAccItem = {
    id: string;
    user_id: string;
    airdrop_min: number;
    airdrop_max: number;
    operator: string;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
    period_min: string;
    period_max: string;
};

// airdrop account list result
export type AirdropAccListResult = {
    totalCount: number;
    totalPages: number;
    currentPage: number;
    list: AirdropAccItem[];
};

// create airdrop account
export type CreateAirdropAccData = {
    user_id: string;
    airdrop_min: number;
    airdrop_max: number;
    // operator: string;
};

export type UpdateAirDropAccData = {
    updated_at: string;
} & Omit<CreateAirdropAccData, 'user_id'>;
