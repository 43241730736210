export default {
    p: {
        plazaConfig: {
            t: {
                headerTitle: '广场列表',
            },
            tips: {
                unselected: '请点击左侧各板块进行内容配置',
            },
            shorts: {
                f: {
                    idRepeat: '短视频 ID 重复',
                    idError: '短视频 ID 验证不通过',
                },
            },
        },
    },
};
