import { useEffect, useRef, useState } from 'react';
import { useRequest, useSetState } from 'ahooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { App, Button, DatePicker, Flex, Form, Input, Modal, Popconfirm, Select, Spin, Typography } from 'antd';
import { ProCard, ProTable } from '@ant-design/pro-components';
import dayjs from 'dayjs';
import { get, isEmpty, last, omitBy, trim } from 'lodash';

/**
 * Components
 */
import TableCellMedia from '@/components/table-cell/table-cell-media';
import ComponentTableCellAudio from '@/components/table-cell/table-cell-audio';
import ComponentTableCellDebate from '@/pages/content-management/posts/components/table-cell-debate';
import ComponentSearchFormSelectOperator from '@/components/search-form-select-operator';
import Permission from '@/components/permission';
import { Pagination } from '@repo/ui/pagination';

/**
 * APIs
 */
import { batchDeletePost, dPost, posts } from '@/services/posts';

/**
 * Types
 */
import type { FormProps } from 'antd';
import type { ActionType, ProTableProps } from '@ant-design/pro-components';
import type { PostCursorParams, PostCursorResult } from '@/services/posts';
import type { PaginationProps } from '@repo/ui/pagination';

// table 批量选择
type SeletRowState = {
    selects: string[];
    selectedRows: any[];
};

/**
 * Constants
 */
const getDefaultTodayDateTime = () => {
    const today = dayjs();
    return [today.startOf('d'), today.endOf('d')];
};

/**
 * State
 */
type State = {
    dataSource: PostCursorResult[];
};

const PageContentPosts: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    const [formSearch] = Form.useForm();

    const { formatMessage } = useIntl();

    const searchRef = useRef<HTMLDivElement>(null);

    const tableRef = useRef<ActionType>();

    const intl = useIntl();

    /**
     * States
     */
    const [state, setState] = useSetState<State>({
        dataSource: [],
    });

    const [tableCellViolateLoading, setTableCellViolateLoading] = useSetState<Record<string, boolean>>({});

    const [selectState, setSelectState] = useSetState<SeletRowState>({
        selects: [],
        selectedRows: [],
    });
    /**
     * Paginations
     */
    const [pagination, setPagination] = useSetState<PaginationProps>({
        current: 1,
        hasMore: false,
        searchAfter: '',
        size: 20,
        total: 0,
        onChange: ({ page, searchAfter, size }) => {
            setPagination({
                current: page,
                size: size,
                searchAfter,
            });
        },
    });

    /**
     * Payloads
     */
    const [payload, setPayload] = useState<PostCursorParams>({
        created_start: getDefaultTodayDateTime()[0].format(),
        created_end: getDefaultTodayDateTime()[1].format(),
    });

    const {
        loading: loadingPosts,
        refresh: refreshPosts,
        run: runPosts,
    } = useRequest(posts, {
        manual: true,
        onSuccess: ({ data: { code, data, msg, meta } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setState({
                dataSource: data,
            });

            setSelectState({
                selects: [],
                selectedRows: [],
            });

            setPagination({
                size: meta.size,
                total: meta.total,
                hasMore: meta.has_more,
                searchAfter: meta.search_after || '',
            });
        },
    });

    /**
     * Request
     */
    const { run: runBatchDelete, loading: loadingBatchDelete } = useRequest(batchDeletePost, {
        manual: true,
        onSuccess: ({ data: { code, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            refreshPosts();
        },
    });
    /**
     * ChildrenProps
     */

    const formSearchProps: FormProps<PostCursorParams> = {
        form: formSearch,
        layout: 'inline',
        onFinish: (values) => {
            if (values.fe_types && values.fe_types.length) {
                values.fe_types.map((v) => {
                    values[v] = 'true';
                });
            }
            values.fe_types = undefined;

            if (values.created_date) {
                values.created_start = values.created_date[0].format();
                values.created_end = values.created_date[1].format();
                values.created_date = undefined;
            }

            if (values.updated_date) {
                values['deleted_at[0]'] = values.updated_date[0].startOf('d').format();
                values['deleted_at[1]'] = values.updated_date[1].endOf('d').format();
                values.updated_date = undefined;
            }

            if (values.fe_is_deleted) {
                values.is_deleted = values.fe_is_deleted === 'normal' ? 'false' : 'true';
            } else {
                values.is_deleted = undefined;
            }

            if (values.body_fuzzy) {
                values.body_fuzzy = trim(values.body_fuzzy);
            }

            if (values.debate_fuzzy) {
                values.debate_fuzzy = trim(values.debate_fuzzy);
            }

            values.fe_is_deleted = undefined;

            setPagination({
                current: 1,
                searchAfter: '',
            });

            setPayload({
                ...omitBy(values, isEmpty),
            });
        },
    };

    const proTableProps: ProTableProps<PostCursorResult, any> = {
        actionRef: tableRef,
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                fixed: 'left',
                title: 'No.',
                width: 50,
                render: (_, __, index) => index + 1,
            },
            {
                dataIndex: 'id',
                ellipsis: true,
                title: <FormattedMessage id="rm.t.c.postUid" />,
                width: 200,
            },
            {
                dataIndex: 'user_id',
                ellipsis: true,
                title: <FormattedMessage id="rm.t.c.postAccUid" />,
                width: 200,
            },
            {
                dataIndex: 'body',
                ellipsis: true,
                title: <FormattedMessage id="rm.t.c.content" />,
            },
            {
                dataIndex: ['debate', 'title'],
                ellipsis: true,
                title: <FormattedMessage id="rm.t.c.debate" />,
                width: 200,
            },
            {
                dataIndex: '_debate_users',
                title: <FormattedMessage id="rm.t.c.debateUsers" />,
                render: (_, entity) => (
                    <ComponentTableCellDebate
                        debate={get(entity, 'debate', null)}
                        formalDebate={get(entity, 'formal_debate', null)}
                    />
                ),
                width: 200,
            },
            {
                dataIndex: '_imageAndVideo',
                title: <FormattedMessage id="rm.t.c.postImg" />,
                render: (_, entity) => <TableCellMedia list={get(entity, 'uploaded_materials', [])} />,
                width: 320,
            },
            {
                dataIndex: '_audio',
                title: <FormattedMessage id="rm.t.c.postAudio" />,
                render: (_, entity) => {
                    const audio = get(entity, 'audio');
                    if (!audio) {
                        return '-';
                    }
                    return <ComponentTableCellAudio srcs={[audio]} />;
                },
                width: 320,
            },
            {
                dataIndex: 'is_deleted',
                title: <FormattedMessage id="t.c.status" />,
                render: (_text, entity) =>
                    entity.is_deleted
                        ? intl.formatMessage({ id: 'cp.f.deleted' })
                        : intl.formatMessage({ id: 'rm.b.normal' }),
                width: 120,
            },
            {
                dataIndex: 'created_at',
                title: <FormattedMessage id="cp.t.c.publishAt" />,
                valueType: 'dateTime',
                width: 200,
            },
            {
                dataIndex: ['operator_account', 'name'],
                title: <FormattedMessage id="t.c.operatedBy" />,
                render: (_, entity) => {
                    return get(last(get(entity, 'log_operation')), 'operator.name') || '-';
                },
                width: 200,
            },
            {
                dataIndex: 'deleted_at',
                title: <FormattedMessage id="t.c.operatedAt" />,
                valueType: 'dateTime',
                width: 200,
            },
            {
                dataIndex: '_menu',
                valueType: 'option',
                width: 200,
                fixed: 'right',
                title: <FormattedMessage id="common.operation" />,
                hideInSearch: true,
                render: (_, entity) => [
                    !entity.deleted_at && (
                        <Permission permission="Content_Posts_Delete" key="delete">
                            <Spin spinning={!!tableCellViolateLoading[entity.id]} size="small">
                                <Popconfirm
                                    title={intl.formatMessage({ id: 'c.areyousure' })}
                                    onConfirm={() => {
                                        setTableCellViolateLoading({
                                            [entity.id]: true,
                                        });
                                        dPost({
                                            user_id: entity.user_id,
                                            post_id: entity.id,
                                        })
                                            .then(({ data: { code, msg } }) => {
                                                if (code === 0) {
                                                    message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
                                                    setTimeout(() => {
                                                        refreshPosts();
                                                        setTableCellViolateLoading({
                                                            [entity.id]: false,
                                                        });
                                                    }, 1000);
                                                    return;
                                                } else {
                                                    message.error(msg || 'Error');
                                                }
                                                setTableCellViolateLoading({
                                                    [entity.id]: false,
                                                });
                                            })
                                            .catch((e) => {
                                                message.error(get(e, 'response.data.msg', 'Error'));
                                                setTableCellViolateLoading({
                                                    [entity.id]: false,
                                                });
                                            });
                                    }}
                                >
                                    <Typography.Link type="danger">
                                        <FormattedMessage id="b.delete" />
                                    </Typography.Link>
                                </Popconfirm>
                            </Spin>
                        </Permission>
                    ),
                ],
            },
        ],
        dataSource: state.dataSource,
        loading: loadingPosts,
        options: {
            reload: refreshPosts,
        },
        pagination: false,
        rowKey: 'id',
        rowSelection: {
            columnWidth: 50,
            selectedRowKeys: selectState.selects,
            getCheckboxProps: (record) => {
                return {
                    disabled: loadingBatchDelete || record.is_deleted,
                };
            },
            onChange: (selectedRowKeys, selectedRows) => {
                setSelectState({
                    selects: selectedRowKeys as string[],
                    selectedRows,
                });
            },
        },
        scroll: {
            x: 2660,
            y: `calc(100vh - 280px - ${searchRef.current?.clientHeight}px - 8px)`,
        },
        tableAlertRender: false,
        toolBarRender: () => {
            if (!selectState.selects.length || loadingPosts) {
                return [];
            }
            return [
                <Permission permission="Content_Posts_Delete" key="bulkDelete">
                    <Button
                        type="primary"
                        loading={loadingBatchDelete}
                        onClick={() => {
                            Modal.confirm({
                                title: intl.formatMessage({ id: 'c.areyousureBulk' }),
                                onOk: async () => {
                                    const posts = selectState.selectedRows.map((v) => {
                                        return {
                                            user_id: v.user_id,
                                            post_id: v.id,
                                        };
                                    });
                                    await runBatchDelete({ posts });
                                },
                            });
                        }}
                    >
                        <FormattedMessage id="b.bulkDelete" />
                    </Button>
                </Permission>,
            ];
        },
        search: false,
    };

    /**
     * Effects
     */
    useEffect(() => {
        runPosts({
            ...payload,
            size: `${pagination.size}`,
            search_after: pagination.searchAfter,
        });
    }, [pagination.current, pagination.size, payload]);

    return (
        <>
            <ProCard className="mb-16" ref={searchRef}>
                <Form {...formSearchProps}>
                    <Flex gap={16} wrap={true}>
                        <Form.Item name="id" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'rm.t.c.postUid' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="user_id" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'rm.t.c.postAccUid' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="body_fuzzy" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'rm.t.c.content' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="debate_fuzzy" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'rm.t.c.debate' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="fe_types" style={{ marginRight: 0 }}>
                            <Select
                                mode="multiple"
                                allowClear={true}
                                showSearch={true}
                                optionFilterProp="label"
                                options={[
                                    {
                                        label: intl.formatMessage({ id: 'cp.f.debateTopic' }),
                                        value: 'is_debate',
                                    },
                                    {
                                        label: intl.formatMessage({ id: 'cp.f.image' }),
                                        value: 'has_image',
                                    },
                                    {
                                        label: intl.formatMessage({ id: 'cp.f.video' }),
                                        value: 'has_video',
                                    },
                                    {
                                        label: intl.formatMessage({ id: 'cp.f.audio' }),
                                        value: 'has_audio',
                                    },
                                    // {
                                    //     label: intl.formatMessage({ id: 'cp.f.nft' }),
                                    //     value: 'nft',
                                    // },
                                ]}
                                placeholder={<FormattedMessage id="cp.f.type" />}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="fe_is_deleted" style={{ marginRight: 0 }}>
                            <Select
                                allowClear={true}
                                showSearch={true}
                                optionFilterProp="label"
                                options={[
                                    {
                                        label: intl.formatMessage({ id: 'rm.b.normal' }),
                                        value: 'normal',
                                    },
                                    {
                                        label: intl.formatMessage({ id: 'cp.f.deleted' }),
                                        value: 'deleted',
                                    },
                                ]}
                                placeholder={<FormattedMessage id="t.c.status" />}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item
                            label={formatMessage({ id: 'cp.t.c.publishAt' })}
                            name="created_date"
                            style={{ marginRight: 0 }}
                            initialValue={getDefaultTodayDateTime()}
                        >
                            <DatePicker.RangePicker
                                onChange={(rangeDate) => {
                                    if (!rangeDate || !rangeDate[0] || !rangeDate[1]) {
                                        return;
                                    }
                                    const newStart = dayjs(rangeDate[0].format('YYYY-MM-DD HH:00:00'));
                                    const newDnd = dayjs(rangeDate[1].format('YYYY-MM-DD HH:59:59'));
                                    formSearch.setFieldValue('create_date', [newStart, newDnd]);
                                }}
                                showTime={{ format: 'HH' }}
                                allowClear={true}
                            />
                        </Form.Item>

                        <Form.Item name="operator_account_id" style={{ marginRight: 0 }}>
                            <ComponentSearchFormSelectOperator model="Content_Posts" />
                        </Form.Item>

                        <Form.Item
                            label={formatMessage({ id: 't.c.operatedAt' })}
                            name="updated_date"
                            style={{ marginRight: 0 }}
                        >
                            <DatePicker.RangePicker allowClear={true} />
                        </Form.Item>

                        <Button
                            loading={loadingPosts}
                            type="primary"
                            onClick={() => {
                                formSearch.submit();
                            }}
                        >
                            <FormattedMessage id="b.search" />
                        </Button>

                        <Button
                            onClick={() => {
                                formSearch.resetFields();
                            }}
                        >
                            <FormattedMessage id="b.reset" />
                        </Button>
                    </Flex>
                </Form>
            </ProCard>

            <ProTable {...proTableProps} />

            <Pagination {...pagination} isLoading={loadingPosts} />
        </>
    );
};

export default PageContentPosts;
