import { useBoolean } from 'ahooks';
import { get } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { App, Button, Drawer, Form, InputNumber, Select, Space } from 'antd';

/**
 * Utils
 */
import { apiErrorMsg } from '@/utils/apiErrorMsg';

/**
 * APIs
 */
import { pBenefits, putBenefits } from '@/services/super-users';

// form submit
const formSubmit = async (propsData: SuperUserBenefits | null, formData: PostBenefitsBody) => {
    // 区分是更新还是新增
    if (propsData && propsData.id) {
        // formData.updated_at = propsData.updated_at;
        return await putBenefits(propsData.id, formData);
    }
    return await pBenefits(formData);
};

/**
 * Constants
 */
const ConditionOptions = ['debate', 'vote', 'like', 'quote', 'share', 'follow'];

/**
 * Types
 */
import type { DrawerProps, FormProps } from 'antd';
import type { IntlShape } from 'react-intl';
import type { PostBenefitsBody, SuperUserBenefits } from '@/services/super-users';

type Props = {
    open: boolean;
    entity: SuperUserBenefits | null;
    closeModelForm: (reload?: boolean) => void;
};

const ComponentModalFormCustom: React.FC<Props> = ({ open, entity, closeModelForm }) => {
    /**
     * Hooks
     */
    const { message } = App.useApp();
    const intl: IntlShape = useIntl();

    /**
     * State
     */
    const [submitting, { setFalse: setSubmittingFlase, setTrue: setSubmittingTrue }] = useBoolean(false);
    const [form] = Form.useForm();

    /**
     * DrawerProps
     */
    const drawerProps: DrawerProps = {
        footer: (
            <div style={{ textAlign: 'right' }}>
                <Space>
                    <Button type="default" onClick={() => closeModelForm()}>
                        {intl.formatMessage({ id: 'b.cancel' })}
                    </Button>
                    <Button
                        type="primary"
                        loading={submitting}
                        onClick={() => {
                            form.validateFields()
                                .then(async (formData) => {
                                    setSubmittingTrue();
                                    try {
                                        const res = await formSubmit(entity, formData);
                                        if (get(res, 'data.code') === 0) {
                                            message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
                                            closeModelForm(true);
                                        } else {
                                            apiErrorMsg(message, intl, {}, res.data);
                                            setSubmittingFlase();
                                        }
                                    } catch {
                                        setSubmittingFlase();
                                    }
                                })
                                .catch(() => {});
                        }}
                    >
                        {intl.formatMessage({ id: 'b.confirm' })}
                    </Button>
                </Space>
            </div>
        ),
        destroyOnClose: true,
        maskClosable: false,
        open: open,
        title: <FormattedMessage id={entity ? 'b.edit' : 'b.add'} />,
        afterOpenChange: (open) => {
            if (!open) {
                setSubmittingFlase();
                form.resetFields();
            } else if (entity) {
                form.setFieldsValue({
                    /**
                     * 空投条件，ConditionType 组合
                     */
                    conditions: entity.conditions,
                    /**
                     * 手续费折扣
                     */
                    discount_rate: parseFloat(entity.discount_rate),
                    /**
                     * 群人数
                     */
                    group_size: entity.group_size,
                    /**
                     * 空投人数
                     */
                    max_investments: entity.max_investments,
                    /**
                     * 空投有效期 (天)
                     */
                    validity_days: entity.validity_days,
                    /**
                     * 权益值
                     */
                    value: entity.value,
                });
            }
        },
        onClose: () => {
            closeModelForm();
        },
    };

    /**
     * ProformProps
     */
    const formProps: FormProps<PostBenefitsBody> = {
        form: form,
        layout: 'vertical',
        validateTrigger: 'onBlur',
    };

    return (
        <Drawer {...drawerProps}>
            <Form {...formProps}>
                <Form.Item
                    name="value"
                    label={intl.formatMessage({ id: 'su.t.c.binifit' })}
                    rules={[{ required: true }]}
                >
                    <InputNumber min={1} max={100000000} precision={0} style={{ width: '100%' }} />
                </Form.Item>

                <Form.Item
                    name="discount_rate"
                    label={intl.formatMessage({ id: 'su.t.c.rateSale' })}
                    rules={[{ required: true }]}
                >
                    <InputNumber
                        min={0.01}
                        max={99.99}
                        step={0.01}
                        precision={2}
                        suffix="%"
                        style={{ width: '100%' }}
                    />
                </Form.Item>

                <Form.Item
                    name="max_investments"
                    label={intl.formatMessage({ id: 'su.t.c.airdropCount' })}
                    rules={[{ required: true }]}
                >
                    <InputNumber min={1} max={100000000} precision={0} style={{ width: '100%' }} />
                </Form.Item>

                <Form.Item
                    name="validity_days"
                    label={intl.formatMessage({ id: 'su.t.c.airdropExprise' })}
                    rules={[{ required: true }]}
                >
                    <InputNumber min={1} max={366} precision={0} style={{ width: '100%' }} />
                </Form.Item>

                <Form.Item
                    name="conditions"
                    label={intl.formatMessage({ id: 'su.t.c.airdropReson' })}
                    rules={[{ required: true }]}
                >
                    <Select
                        allowClear
                        mode="multiple"
                        showSearch
                        optionFilterProp="label"
                        options={ConditionOptions.map((v) => {
                            return { label: intl.formatMessage({ id: `su.c.${v}` }), value: v };
                        })}
                    />
                </Form.Item>

                <Form.Item
                    name="group_size"
                    label={intl.formatMessage({ id: 'su.t.c.groupCount' })}
                    rules={[{ required: true }]}
                >
                    <InputNumber min={1} max={100000} precision={0} style={{ width: '100%' }} />
                </Form.Item>
            </Form>
        </Drawer>
    );
};

export default ComponentModalFormCustom;
