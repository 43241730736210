export default {
    oa: {
        p: {
            /**
             * Airdrop Events
             *
             * 官方空投活动列表
             */
            ae: {
                b: {
                    send: 'Distribute',
                },
                // info
                i: {
                    activityCyclesPlaceholder: 'Please enter a number',
                    endTimePlaceholder: 'End',
                    seasonNotStart: '空投季尚未开始', // 空投季尚未开始 - 102100
                    seasonNotStartButAc: '空投季尚未开始已激活', // 空投季尚未开始已激活 - 102102
                    seasonStatusError: 'Airdrop season status is not pending', // Airdrop season status is not pending 102112
                },
                t: {
                    c: {
                        activityId: 'Season ID',
                        activityCycles: 'Season',
                        registrationDates: 'Registration Period',
                        activityStatus: 'Season Status',
                        airdropStatus: 'Distribution Status',
                        airdropBy: 'Distribution By',
                        airdropAt: 'Distribution At',
                    },
                },
                s: {
                    notStarted: 'Not Started',
                    inProgress: 'In Progress',
                    completed: 'Completed',
                    waitDistribute: 'Pending',
                    distributing: 'Distributing',
                    distributed: 'Success',
                    paused: 'Paused',
                    failed: 'Failed',
                },
            },
            /**
             * Official Airdrop Configurations
             *
             * 官方空投参数配置
             */
            oac: {
                mainConfigTitle: 'Total Airdrop Amount',
                userConfigTitle: 'Airdrop Claim Amount',
                airdropAmount: 'Total airdrop amount P',
                airdropAmountMax: 'Max total airdrop amount M',
                efficient: 'Coefficient {value}',
                userConfigAmount: "User's airdrop claim amount p",
                userConfigAmountVla: 'p = Individual score s / Total score of all users S * Total airdrop amount P',
                postScore: 'Post count score',
                postScoreVla: 'Post count',
                newUserCommentNum: 'New user comment count score',
                oldUserCommentNum: 'Comment count',
                oldUserCommentNumVla: 'Existing user comment count score',
                newUserVoteNumVla: 'New user vote count score',
                oldUserVoteNumVla: 'Existing user vote count score',
                voteUserNum: 'Voter count',
                voteNumVla: 'Vote count score',
                voteNum: 'Vote count',
                commentNum: 'Comment count',
                commentNumScore: 'Comment count score',
                commentLikeScore: 'Comment like score',
                likeNum: 'Like count',
                replyNum: 'Reply count',
                replyNumScore: 'Comment reply score',
                inviteScore: 'Referral score',
                inviteNum: 'Number of Invites',
                // error info
                e: {
                    integerError: 'A positive integer within 100 million',
                    validPositiveNumberError: 'A positive number within 100 million, up to two decimals',
                    decimalsNumError: 'Non-negative numbers up to one hundred million, with up to two decimal places',
                },
            },
        },
    },
};
