import { useCallback, useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useRequest, useSetState } from 'ahooks';
import { App, Button, Popconfirm, Spin, Tag, Typography } from 'antd';
import { HolderOutlined } from '@ant-design/icons';
import { DragSortTable } from '@ant-design/pro-components';

/**
 * Components
 */
import TokenConfigModalForm from './components/top-token-modal-form';
import Permission, { useAccess } from '@/components/permission';

/**
 * APIs
 */
import { dTopToken, gTopToken, sTopToken } from '@/services/top-token';

/**
 * Types
 */
import type { IntlShape } from 'react-intl';
import type { ActionType, DragTableProps } from '@ant-design/pro-components';
import type { TokenConfig } from '@/services/top-token';

type State = {
    sorting: boolean;
    dataSource: TokenConfig[];
    openAddModal: boolean;
    socCount: number;
    faceActiveCount: number;
};

const PageTokenTopToken: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    const tableRef = useRef<ActionType>();

    const checkAccess = useAccess();

    /**
     * States
     */
    const intl: IntlShape = useIntl();
    const [state, setState] = useSetState<State>({
        sorting: false,
        dataSource: [],
        openAddModal: false,
        socCount: 0,
        faceActiveCount: 0,
    });
    const [cellLoading, setCellLoading] = useSetState<Record<string, boolean>>({});

    /**
     * Callbacks
     */
    const handleDragSortEnd = useCallback((_beforeIndex: number, _afterIndex: number, newDataSource: any) => {
        setState({
            dataSource: newDataSource,
        });
    }, []);

    // Sort
    const { loading: loadingTopTokenSorts, run: runTopTokenSorts } = useRequest(sTopToken, {
        manual: true,
        onSuccess: ({ data: { code, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }
            message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
            refreshTopToken();
            setState({
                sorting: false,
            });
        },
    });

    /**
     * Requests
     */
    const {
        loading,
        refresh: refreshTopToken,
        run: runTopToken,
    } = useRequest(gTopToken, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setState({
                dataSource: data,
            });
        },
    });

    const hasSortPermission = checkAccess('Marketplace_Token_Top-Token_Sort') || checkAccess('super_admin');

    const proTableProps: DragTableProps<TokenConfig, any> = {
        actionRef: tableRef,
        bordered: true,
        columns: [
            {
                title: <FormattedMessage id="tc.t.c.sort" />,
                fixed: 'left',
                dataIndex: 'sort',
                hideInTable: !hasSortPermission,
                width: 50,
                className: 'drag-visible',
                render: () => {
                    if (state.sorting) {
                        return null;
                    }
                    return (
                        <Button
                            type="text"
                            size="small"
                            icon={<HolderOutlined />}
                            style={{ cursor: 'not-allowed', opacity: 0.5 }}
                        />
                    );
                },
            },
            {
                dataIndex: 'index',
                valueType: 'index',
                title: 'No.',
                width: 50,
                fixed: !hasSortPermission ? 'left' : undefined,
            },
            {
                dataIndex: ['token', 'id'],
                title: 'Token ID',
                width: 120,
            },
            {
                dataIndex: ['token', 'label'],
                width: 120,
                title: 'Token',
                render: (_, entity) => {
                    const url = entity?.token?.url;
                    return (
                        <div>
                            <img style={{ width: '20px', height: '20px', marginRight: '8px' }} src={url} />
                            {entity?.token?.label}
                        </div>
                    );
                },
            },
            // 色值
            {
                dataIndex: 'color',
                width: 120,
                title: <FormattedMessage id="p.tc.column.color" />,
                render: (_, entity) => {
                    return (
                        <Tag bordered color={entity.color}>
                            {entity.color}
                        </Tag>
                    );
                },
            },
            {
                dataIndex: 'sort',
                width: 120,
                title: <FormattedMessage id="p.tc.column.sort" />,
            },
            // 价格精度
            {
                dataIndex: 'price_scale',
                tooltip: <FormattedMessage id="p.tc.tips.priceScale" />,
                width: 200,
                title: <FormattedMessage id="p.tc.column.priceScale" />,
            },

            {
                dataIndex: 'show_scale',
                tooltip: <FormattedMessage id="p.tc.tips.showScale" />,
                width: 200,
                title: <FormattedMessage id="p.tc.column.showScale" />,
            },
            {
                dataIndex: 'decimals',
                width: 200,
                title: <FormattedMessage id="p.tc.column.decimals" />,
            },
            {
                dataIndex: 'min_top_up',
                width: 120,
                title: <FormattedMessage id="p.tc.column.minTopUp" />,
            },
            {
                dataIndex: 'min_trade_amount',
                width: 120,
                title: <FormattedMessage id="p.tc.column.minTradeAmount" />,
            },
            {
                dataIndex: 'type',
                width: 120,
                title: <FormattedMessage id="t.c.type" />,
                valueEnum: {
                    1: <FormattedMessage id="p.tc.column.type.majorToken" />,
                    2: <FormattedMessage id="p.tc.column.type.trustedToken" />,
                    3: <FormattedMessage id="p.tc.column.type.mintableToken" />,
                    4: <FormattedMessage id="p.tc.column.type.blacklist" />,
                },
            },
            {
                dataIndex: 'status',
                width: 120,
                title: <FormattedMessage id="common.status" />,
                hideInSearch: true,
                render: (dom) => (dom === 1 ? <Tag color="green">Enabled</Tag> : <Tag color="red">Disabled</Tag>),
            },
            {
                dataIndex: 'operator',
                width: 120,
                title: <FormattedMessage id="t.c.operatedBy" />,
            },
            {
                dataIndex: 'updated_at',
                width: 200,
                valueType: 'dateTime',
                title: <FormattedMessage id="common.table.columns.operatedAt" />,
            },
            {
                fixed: 'right',
                key: 'option',
                title: <FormattedMessage id="t.c.action" />,
                valueType: 'option',
                width: 200,
                render: (_, entity) => [
                    <Permission permission="Marketplace_Token_Top-Token_Delete" key="delete">
                        <Spin spinning={!!cellLoading[entity.id]} size="small">
                            <Popconfirm
                                title={intl.formatMessage({ id: 'c.areyousure' })}
                                onConfirm={() => {
                                    setCellLoading({ [entity.id]: true });
                                    dTopToken(entity.token.id)
                                        .then(({ data: { code, msg } }) => {
                                            setCellLoading({ [entity.id]: false });
                                            if (code !== 0) {
                                                return message.error(msg || 'Error');
                                            }
                                            message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
                                            refreshTopToken();
                                        })
                                        .catch(() => {
                                            setCellLoading({ [entity.id]: false });
                                            message.error('Error');
                                        });
                                }}
                            >
                                <Typography.Link type="danger">
                                    <FormattedMessage id="b.delete" />
                                </Typography.Link>
                            </Popconfirm>
                        </Spin>
                    </Permission>,
                ],
            },
        ],
        dataSource: state.dataSource,
        dragSortKey: state.sorting ? 'sort' : '_not_sortable',
        dragSortHandlerRender: () => (
            <Button type="text" size="small" icon={<HolderOutlined />} style={{ cursor: 'move' }} />
        ),
        loading,
        pagination: false,
        options: {
            reload: refreshTopToken,
        },
        rowKey: 'id',
        toolBarRender: () => {
            if (state.sorting) {
                return [
                    <Button
                        type="primary"
                        loading={loadingTopTokenSorts}
                        onClick={() => {
                            runTopTokenSorts({ token_ids: state.dataSource.map((item) => item.token.id) });
                        }}
                        key="sureSort"
                    >
                        <FormattedMessage id="tc.b.sureSort" />
                    </Button>,
                    <Button
                        loading={loadingTopTokenSorts}
                        onClick={() => {
                            setState({ sorting: false });
                            refreshTopToken();
                        }}
                        key="cancelSort"
                    >
                        <FormattedMessage id="tc.b.cancelSort" />
                    </Button>,
                ];
            }
            return [
                <Permission permission="Marketplace_Token_Top-Token_Add" key="add">
                    <Button
                        type="primary"
                        onClick={() => {
                            setState({
                                openAddModal: true,
                            });
                        }}
                    >
                        <FormattedMessage id="b.add" />
                    </Button>
                </Permission>,
                <Permission permission="Marketplace_Token_Top-Token_Sort" key="sort">
                    <Button
                        type="primary"
                        ghost
                        onClick={() => {
                            setState({ sorting: true });
                        }}
                    >
                        <FormattedMessage id="tc.t.c.sort" />
                    </Button>
                </Permission>,
            ];
        },
        scroll: {
            y: 'calc(100vh - 232px)',
            x: 1500,
        },
        search: false,
        onDragSortEnd: handleDragSortEnd,
    };

    /**
     * Effects
     */
    useEffect(() => {
        runTopToken();
    }, []);

    return (
        <>
            <DragSortTable {...proTableProps} />

            <TokenConfigModalForm
                open={state.openAddModal}
                includes={state.dataSource}
                closeModelForm={(reload?: boolean) => {
                    setState({
                        openAddModal: false,
                    });
                    reload && refreshTopToken();
                }}
            />
        </>
    );
};

export default PageTokenTopToken;
