import { useRequest } from 'ahooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Drawer, Button, Input, Badge, Select, Flex, message, Radio, InputNumber } from 'antd';
import { userMute, userBan, userUnmute, userUnban } from '@/services/user';

/**
 * Types
 */
import type { IntlShape } from 'react-intl';
import type { User } from '@/services/user';
import dayjs from 'dayjs';
import type { FormProps } from 'antd';
import { useEffect, useState } from 'react';

export type ModalFormState = {
    open: boolean;
    user: User | null;
};

type Props = {
    type: 'mute' | 'ban' | null;
    state: ModalFormState;
    onClose: (reload: boolean) => void;
};

/**
 * Constants
 */
const actionMap = {
    mute: {
        true: userUnmute,
        false: userMute,
    },
    ban: {
        true: userUnban,
        false: userBan,
    },
};

const UserStatusModalForm: React.FC<Props> = ({ type, state, onClose }) => {
    /**
     * State
     */
    const [form] = Form.useForm();
    const intl: IntlShape = useIntl();
    const [showPeriodUnit, setShowPeriodUnit] = useState(false);

    const status = type === 'mute' ? state.user?.is_muted : state.user?.is_banned;
    const userTnsAction = type ? actionMap[type][`${!!status}`] : null;

    const { run: runUserTnsAction, loading: loadingUserTnsAction } = useRequest(userTnsAction || actionMap.mute.false, {
        manual: true,
        onSuccess: ({ data: { code, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            message.success(intl.formatMessage({ id: 'common.submitSuccess' }));

            onClose(true);
            form.resetFields();
        },
    });

    const handleFinish = async (formData: any) => {
        if (!state.user?.id) {
            return;
        }

        if (!userTnsAction) {
            return;
        }

        await runUserTnsAction(state.user?.id, {
            period: formData.period_type === 'temporary' ? Number(formData.period) : undefined,
            period_unit: formData.period_type === 'temporary' ? formData.period_unit : undefined,
            remark: formData.remark,
        });
    };

    const drawerProps = {
        title: <FormattedMessage id={`p.uu.action.${status ? 'un' : ''}${type}`} />,
        open: state.open,
        maskClosable: false,
        destroyOnClose: true,
        onClose: () => {
            onClose(false);
            form.resetFields();
        },
        footer: (
            <div style={{ textAlign: 'right' }}>
                <Button onClick={() => onClose(false)} style={{ marginRight: 8 }}>
                    {intl.formatMessage({ id: 'common.cancel' })}
                </Button>
                <Button onClick={() => form.submit()} type="primary" loading={loadingUserTnsAction}>
                    {intl.formatMessage({ id: 'common.sure' })}
                </Button>
            </div>
        ),
    };

    const getInitialPeriodType = (type: 'mute' | 'ban' | null, user: User | null) => {
        if (!type || !user) return 'permanent';
        const status = type === 'mute' ? user.is_muted : user.is_banned;
        return status ? `un${type}` : 'permanent';
    };

    const formProps: FormProps<any> = {
        form,
        initialValues: {
            period_type: getInitialPeriodType(type, state.user),
        },
        layout: 'vertical',
        labelAlign: 'right',
        onFinish: handleFinish,
        onValuesChange: (_, allValues) => {
            if (allValues.period_type === 'temporary') {
                setShowPeriodUnit(true);
            } else {
                setShowPeriodUnit(false);
            }
        },
    };

    useEffect(() => {
        form.setFieldValue('period_type', getInitialPeriodType(type, state.user));
    }, [type, state.user]);

    return (
        <Drawer {...drawerProps}>
            <Form {...formProps}>
                <Form.Item label={<FormattedMessage id="p.uu.columns.uid" />} layout="horizontal">
                    {state.user?.id}
                </Form.Item>
                <Form.Item label={<FormattedMessage id="p.uu.columns.userName" />} layout="horizontal">
                    {state.user?.username ? `@${state.user?.username}` : '-'}
                </Form.Item>
                <Form.Item label={<FormattedMessage id="p.uu.columns.nickname" />} layout="horizontal">
                    {state.user?.nickname || '-'}
                </Form.Item>
                <Form.Item label={<FormattedMessage id="common.status" />} layout="horizontal">
                    {type === 'mute' &&
                        (state.user?.is_muted ? (
                            <>
                                <Badge status="error" text={intl.formatMessage({ id: 'p.uu.status.muted.muted' })} />
                                {state.user?.time_unmute ? (
                                    <span style={{ marginLeft: 8 }}>
                                        ({intl.formatMessage({ id: 'p.uu.status.muted.mutedAt' })}:&nbsp;
                                        {state.user?.time_unmute
                                            ? dayjs(state.user?.time_unmute).format('YYYY-MM-DD HH:mm:ss')
                                            : ''}
                                        )
                                    </span>
                                ) : (
                                    <span>（{intl.formatMessage({ id: 'p.uu.status.permanent' })}）</span>
                                )}
                            </>
                        ) : (
                            <>
                                <Badge status="success" text={intl.formatMessage({ id: 'p.uu.status.muted.normal' })} />
                            </>
                        ))}
                    {type === 'ban' &&
                        (state.user?.is_banned ? (
                            <>
                                <Badge
                                    status="error"
                                    text={intl.formatMessage({ id: 'p.uu.status.account.disabled' })}
                                />
                                {state.user?.time_unban ? (
                                    <span style={{ marginLeft: 8 }}>
                                        ({intl.formatMessage({ id: 'p.uu.status.account.enabledAt' })}:&nbsp;
                                        {state.user?.time_unban
                                            ? dayjs(state.user?.time_unban).format('YYYY-MM-DD HH:mm:ss')
                                            : ''}
                                        )
                                    </span>
                                ) : (
                                    <span>（{intl.formatMessage({ id: 'p.uu.status.permanent' })}）</span>
                                )}
                            </>
                        ) : (
                            <>
                                <Badge
                                    status="success"
                                    text={intl.formatMessage({ id: 'p.uu.status.account.normal' })}
                                />
                            </>
                        ))}
                </Form.Item>
                <Form.Item required style={{ marginBottom: 0 }} label={<FormattedMessage id="t.c.action" />}>
                    {(type === 'mute' && !state.user?.is_muted) || (type === 'ban' && !state.user?.is_banned) ? (
                        <Form.Item
                            name="period_type"
                            rules={[{ required: true, message: intl.formatMessage({ id: 'common.notEmpty' }) }]}
                        >
                            <Radio.Group>
                                <Flex gap={12}>
                                    <Radio style={{ height: '32px', lineHeight: '32px' }} value="temporary">
                                        <FormattedMessage id={`p.uu.action.${type}Temporary`} />
                                    </Radio>
                                    <Flex gap={12}>
                                        <Form.Item
                                            name="period"
                                            rules={[
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (getFieldValue('period_type') !== 'temporary') {
                                                            return Promise.resolve();
                                                        }
                                                        if (!value) {
                                                            return Promise.reject(
                                                                intl.formatMessage({ id: 'common.notEmpty' }),
                                                            );
                                                        }
                                                        if (!/^[1-9]\d{0,2}$|^1000$/.test(value)) {
                                                            return Promise.reject(
                                                                intl.formatMessage({
                                                                    id: 'p.uu.message.cycleNumber',
                                                                }),
                                                            );
                                                        }
                                                        return Promise.resolve();
                                                    },
                                                }),
                                            ]}
                                        >
                                            <InputNumber
                                                disabled={!showPeriodUnit}
                                                min={1}
                                                max={1000}
                                                step={1}
                                                style={{ width: 80 }}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="period_unit"
                                            rules={[
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (getFieldValue('period_type') !== 'temporary') {
                                                            return Promise.resolve();
                                                        }
                                                        if (!value) {
                                                            return Promise.reject(
                                                                intl.formatMessage({ id: 'common.notEmpty' }),
                                                            );
                                                        }
                                                        return Promise.resolve();
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Select
                                                disabled={!showPeriodUnit}
                                                allowClear={true}
                                                optionFilterProp="label"
                                                options={[
                                                    {
                                                        label: intl.formatMessage({ id: 'p.uu.cycleType.minute' }),
                                                        value: 'minute',
                                                    },
                                                    {
                                                        label: intl.formatMessage({ id: 'p.uu.cycleType.hour' }),
                                                        value: 'hour',
                                                    },
                                                    {
                                                        label: intl.formatMessage({ id: 'p.uu.cycleType.day' }),
                                                        value: 'day',
                                                    },
                                                ]}
                                                style={{ width: 100 }}
                                                showSearch={true}
                                            />
                                        </Form.Item>
                                    </Flex>
                                </Flex>
                                <Radio value="permanent">
                                    <FormattedMessage id={`p.uu.action.${type}Permanent`} />
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                    ) : null}
                    {(type === 'mute' && state.user?.is_muted) || (type === 'ban' && state.user?.is_banned) ? (
                        <Form.Item name="period_type">
                            <Radio.Group>
                                <Radio value={`un${type}`} checked={true}>
                                    <FormattedMessage id={`p.uu.action.un${type}`} />
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                    ) : null}
                </Form.Item>
                <Form.Item
                    name="remark"
                    label={<FormattedMessage id="p.uu.columns.remark" />}
                    rules={[{ required: true, message: intl.formatMessage({ id: 'common.notEmpty' }) }]}
                >
                    <Input.TextArea showCount maxLength={1000} rows={4} />
                </Form.Item>
            </Form>
        </Drawer>
    );
};

export default UserStatusModalForm;
