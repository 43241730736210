import { FormattedMessage } from 'react-intl';
import { Card, Descriptions, Image } from 'antd';
import { User } from '@/services/user';

/**
 * Types
 */

type Props = {
    user: User | null;
};

/**
 * Constants
 */
const WEB2_ASSETS_URL = import.meta.env.VITE_WEB2_ASSETS || '';

const UserDetail: React.FC<Props> = ({ user }) => {
    return (
        <Card style={{ marginBottom: 12 }}>
            <h3 style={{ marginBottom: 16 }}>
                <FormattedMessage id="p.uu.h.baseInfo" />
            </h3>

            <Descriptions
                layout="vertical"
                size="middle"
                items={[
                    {
                        key: '1',
                        label: 'UID',
                        children: user?.id || '-',
                    },
                    {
                        key: '2',
                        label: 'Username',
                        children: user?.username ? <span>@{user.username}</span> : '-',
                    },
                    {
                        key: '3',
                        label: 'Nickname',
                        children: user?.nickname || '-',
                    },
                    {
                        key: '4',
                        label: 'Avatar',
                        children: user?.avatar ? (
                            <Image
                                width={64}
                                height={64}
                                style={{ borderRadius: '50%' }}
                                src={user.avatar.includes('https') ? user.avatar : `${WEB2_ASSETS_URL}/${user.avatar}`}
                            />
                        ) : (
                            '-'
                        ),
                    },
                    {
                        key: '5',
                        label: 'Bio',
                        children: user?.bio || '-',
                        span: 2,
                    },
                ]}
            />
        </Card>
    );
};

export default UserDetail;
