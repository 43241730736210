import { useEffect, useRef, useState } from 'react';
import { useRequest, useSetState } from 'ahooks';
import { App, Button, Flex, Form, Input, PaginationProps, Select, Tag } from 'antd';
import { ProCard, ProTable } from '@ant-design/pro-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { isEmpty, omitBy, trim } from 'lodash';

/**
 * Components
 */
import Permission from '@/components/permission';
import ComponentDrawerFormCustom, { channelTypeLangMap, ChannelTypeOptions } from './components/drawer-form-custom';

/**
 * Constant
 */
const StatusOptions = [
    {
        label: 'Enabled',
        value: 'Enabled',
    },
    {
        label: 'Disabled',
        value: 'Disabled',
    },
];

/**
 * APIs
 */
import { channelCode, channelCodeType, exportChannelCode } from '@/services/channel-code';

/**
 * Types
 */
import type { ActionType, FormProps, ProTableProps } from '@ant-design/pro-components';
import type { ChannelCodeParams, ChannelCodeResult } from '@/services/channel-code';
import type { IntlShape } from 'react-intl';

/**
 * State
 */
type State = {
    dataSource: ChannelCodeResult[];
    entity: any;
    openDrawer: boolean;
    openUploadDrawer: boolean;
    typeOptions: ChannelTypeOptions;
};

const PageClientChannelCode: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    const [formSearch] = Form.useForm();

    const searchRef = useRef<HTMLDivElement>(null);

    const tableRef = useRef<ActionType>();

    const intl: IntlShape = useIntl();

    /**
     * Paginations
     */
    const [pagination, setPagination] = useSetState<PaginationProps>({
        current: 1,
        pageSize: 20,
        onChange: (page, pageSize) => {
            setPagination({
                current: page,
                pageSize,
            });
        },
    });

    /**
     * States
     */
    const [state, setState] = useSetState<State>({
        dataSource: [],
        entity: null,
        openDrawer: false,
        openUploadDrawer: false,
        typeOptions: [],
    });

    /**
     * Payloads
     */
    const [payload, setPayload] = useState<ChannelCodeParams>({});

    const {
        loading: loadingListData,
        refresh: refreshListData,
        run: runListData,
    } = useRequest(channelCode, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setState({
                dataSource: data.list,
            });

            setPagination({
                total: data.meta.total,
            });
        },
    });

    /**
     * Requests
     */
    useRequest(channelCodeType, {
        onSuccess: ({ data }) => {
            setState({
                typeOptions: data.data.map((v) => {
                    return {
                        label: channelTypeLangMap[v] ? <FormattedMessage id={channelTypeLangMap[v]} /> : v,
                        value: v,
                    };
                }),
            });
        },
    });

    const { run: runExport, loading: loadingExport } = useRequest(exportChannelCode, {
        manual: true,
        onSuccess: ({ data }) => {
            if (data.code !== 0) {
                return message.error(data.msg);
            }
            message.success(intl.formatMessage({ id: 'm.exportTips' }));
        },
    });

    /**
     * ChildrenProps
     */
    const proTableProps: ProTableProps<ChannelCodeResult, any> = {
        actionRef: tableRef,
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                title: 'No.',
                width: 50,
                fixed: 'left',
                hideInSearch: true,
                render: (_, __, index) => index + 1,
            },
            {
                dataIndex: 'channel_id',
                ellipsis: true,
                title: <FormattedMessage id="ccc.t.c.channelID" />,
                width: 200,
            },
            {
                dataIndex: 'type',
                title: <FormattedMessage id="t.c.type" />,
                render: (text: any) => {
                    console.log(text);
                    return channelTypeLangMap[text] ? <FormattedMessage id={channelTypeLangMap[text]} /> : text;
                },
                width: 120,
            },
            {
                dataIndex: 'name',
                title: <FormattedMessage id="cad.t.c.name" />,
            },
            {
                dataIndex: 'link',
                copyable: true,
                ellipsis: true,
                title: <FormattedMessage id="ccc.t.c.link" />,
                width: 200,
            },
            {
                dataIndex: 'channel_code',
                copyable: true,
                ellipsis: true,
                title: <FormattedMessage id="ccc.t.c.code" />,
                width: 200,
            },
            {
                dataIndex: 'invite_count',
                title: <FormattedMessage id="ccc.t.c.registrations" />,
                width: 120,
            },
            {
                dataIndex: 'status',
                title: <FormattedMessage id="t.c.status" />,
                render: (status) => <Tag color={status ? 'green' : 'orange'}>{status ? 'Enabled' : 'Disabled'}</Tag>,
                width: 120,
            },
            {
                dataIndex: 'comment',
                title: <FormattedMessage id="b.remark" />,
                width: 120,
            },
            {
                dataIndex: ['operator_account', 'name'],
                ellipsis: true,
                title: <FormattedMessage id="t.c.operatedBy" />,
                width: 200,
            },
            {
                dataIndex: 'updated_at',
                width: 200,
                valueType: 'dateTime',
                title: <FormattedMessage id="t.c.operatedAt" />,
                hideInSearch: true,
            },
            {
                dataIndex: '_menu',
                valueType: 'option',
                width: 200,
                fixed: 'right',
                title: <FormattedMessage id="common.operation" />,
                hideInSearch: true,
                render: (_, entity) => [
                    <Permission permission="Client-Config_Channel-Code_Edit" key="edit">
                        <a
                            onClick={() => {
                                setState({
                                    entity,
                                    openDrawer: true,
                                });
                            }}
                        >
                            <FormattedMessage id="b.edit" />
                        </a>
                    </Permission>,
                ],
            },
        ],
        search: false,
        dataSource: state.dataSource,
        loading: loadingListData,
        options: {
            reload: refreshListData,
        },
        pagination,
        rowKey: 'id',
        scroll: {
            y: `calc(100vh - 280px - ${searchRef.current?.clientHeight}px - 8px)`,
            x: 1930,
        },
        tableAlertRender: false,
        toolBarRender: () => [
            <Permission permission="Client-Config_Channel-Code_Add" key="add">
                <Button
                    type="primary"
                    onClick={() => {
                        setState({ openDrawer: true, entity: null });
                    }}
                    key="sureSort"
                >
                    <FormattedMessage id="b.add" />
                </Button>
            </Permission>,
        ],
    };

    const formSearchProps: FormProps<ChannelCodeParams> = {
        form: formSearch,
        layout: 'inline',
        onFinish: (values) => {
            if (values.channel) {
                values.channel = trim(values.channel);
            }

            if (values.name) {
                values.name = trim(values.name);
            }

            if (values.status) {
                values.status = values.status === 'Enabled' ? 'true' : 'false';
            }

            setPagination({
                current: 1,
            });
            setPayload({
                ...omitBy(values, isEmpty),
            });
        },
    };

    /**
     * Effects
     */
    useEffect(() => {
        runListData({
            ...payload,
            page: pagination.current as any,
            limit: pagination.pageSize as any,
        });
    }, [pagination.current, pagination.pageSize, payload]);

    return (
        <>
            <ProCard className="mb-16" ref={searchRef}>
                <Form {...formSearchProps}>
                    <Flex gap={16} wrap={true}>
                        <Form.Item name="channel" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'ccc.t.c.channelID' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="type" style={{ marginRight: 0 }}>
                            <Select
                                allowClear={true}
                                optionFilterProp="label"
                                options={state.typeOptions}
                                placeholder={intl.formatMessage({ id: 't.c.type' })}
                                showSearch={true}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="name" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'cad.t.c.name' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="status" style={{ marginRight: 0 }}>
                            <Select
                                allowClear={true}
                                optionFilterProp="label"
                                options={StatusOptions}
                                placeholder={intl.formatMessage({ id: 't.c.status' })}
                                showSearch={true}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Button
                            loading={loadingListData}
                            type="primary"
                            onClick={() => {
                                formSearch.submit();
                            }}
                        >
                            <FormattedMessage id="b.search" />
                        </Button>

                        <Button
                            onClick={() => {
                                formSearch.resetFields();
                            }}
                        >
                            <FormattedMessage id="b.reset" />
                        </Button>
                        <Permission permission="Client-Config_Channel-Code_Export" key="export">
                            <Button
                                onClick={() => {
                                    runExport({ ...payload });
                                }}
                                key="export"
                                loading={loadingExport}
                            >
                                <FormattedMessage id="b.export" />
                            </Button>
                        </Permission>
                    </Flex>
                </Form>
            </ProCard>

            <ProTable {...proTableProps} />

            <ComponentDrawerFormCustom
                open={state.openDrawer}
                entity={state.entity}
                closeModelForm={(reload) => {
                    setState({ openDrawer: false });
                    reload && refreshListData();
                }}
            />
        </>
    );
};

export default PageClientChannelCode;
