import { useState } from 'react';
import { useRequest } from 'ahooks';
import { message, Select } from 'antd';
import { FormattedMessage } from 'react-intl';

/**
 * APis
 */
import { gAmbassadorsGroups } from '@/services/ambassadors-configurations';

/**
 * Types
 */
import type { DefaultOptionType } from 'antd/lib/select';

type ValueType = 'number' | 'string' | undefined;

type SelectProps = {
    id?: string;
    value?: ValueType;
    onChange?: (value: ValueType) => void;
};

const ComponentAmbassadorsGroupSelect: React.FC<SelectProps> = ({ id, value = undefined, onChange }) => {
    /**
     * States
     */
    const [state, setState] = useState<DefaultOptionType[]>([]);

    /**
     * Requests
     */
    useRequest(gAmbassadorsGroups, {
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }
            const options = data?.filter((v) => v.status === 'opened').map((v) => ({ label: v.name, value: v.id }));
            setState(options || []);
        },
    });

    return (
        <Select
            id={id}
            value={value}
            allowClear={true}
            optionFilterProp="label"
            options={state}
            placeholder={<FormattedMessage id="t.c.group" />}
            showSearch={true}
            style={{ width: 200 }}
            onChange={onChange}
        />
    );
};

export default ComponentAmbassadorsGroupSelect;
