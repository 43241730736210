export default {
    ccc: {
        t: {
            c: {
                channelID: '渠道ID',
                link: '邀请链接',
                code: '邀请码',
                registrations: '注册次数',
            },
        },
        s: {
            KOL: 'KOL',
            DEFAULT: '默认',
            AMBASSADOR: '大使',
            PROJECT: '项目',
            CHANNEL: '渠道',
        },
    },
};
