import axios from './index';
// import localAxios from 'axios';

// const axios = localAxios.create({
//     baseURL: 'http://127.0.0.1:4523/m1/4939924-0-default',
// });

/**
 * APIs
 * 超级账号 - 行为管理
 */
// 获取 行为管理
export const gBehaviors = () => {
    return axios<ResType<AdminSuperUserBehaviors[]>>('/v1/users/super/behaviors', {
        method: 'get',
    });
};

// 更新 行为管理
export const putBehaviors = (id: IdType, data: AdminSuperUserBehaviorsBody) => {
    return axios<ResType<AdminSuperUserBehaviors>>(`/v1/users/super/behaviors/${id}`, {
        method: 'put',
        data,
    });
};

/**
 * APIs
 * 超级账号 - 权益管理
 */
// 获取 权益管理
export const gBenefits = () => {
    return axios<ResType<SuperUserBenefits[]>>('/v1/users/super/benefits', {
        method: 'get',
    });
};

// 创建 权益管理
export const pBenefits = (data: PostBenefitsBody) => {
    return axios<ResType<SuperUserBenefits>>(`/v1/users/super/benefits`, {
        method: 'post',
        data,
    });
};

// 更新 权益管理
export const putBenefits = (id: IdType, data: PostBenefitsBody) => {
    return axios<PageResType<SuperUserBenefits>>(`/v1/users/super/benefits/${id}`, {
        method: 'put',
        data,
    });
};

// 删除 权益管理
export const dBenefits = (id: IdType) => {
    return axios<PageResType<null>>(`/v1/users/super/benefits/${id}`, {
        method: 'delete',
    });
};

/**
 * Constants
 */
export const actionTypeMap: Record<string, string> = {
    is_use_nft_avatar: '是否使用NFT头像',
    nft_amount: '持有NFT数量',
    nft_username_len: 'NFT用户名位数',
    soc_amount: '持有SOC数量',
};

/**
 * Types
 */
import type { PageResType, ResType } from '@repo/typings/common';
import { IdType } from './marketplace-nft';

/**
 * 行为管理
 */
export interface AdminSuperUserBehaviors {
    content: BehaviorRule[] | ContentObject;
    /**
     * 创建时间
     */
    created_at: Date;
    /**
     * 删除时间
     */
    deleted_at?: Date | null;
    /**
     * ID
     */
    id: string;
    operator_account?: OperatorAccount;
    type: Type;
    /**
     * 更新时间
     */
    updated_at: Date;
}

/**
 * BehaviorRule
 */
export interface BehaviorRule {
    label: string;
    label_options?: LabelOption[];
    value: number;
}

export interface LabelOption {
    lable: string;
    value: string;
}

/**
 * BehaviorRule
 */
export interface ContentObject {
    level: string;
    level_options?: LevelOption[];
    value: number;
    label: string;
    label_options?: LabelOption[];
}

export interface LevelOption {
    lable: string;
    value: string;
}

/**
 * OperatorAccount
 */
export interface OperatorAccount {
    /**
     * 创建时间
     */
    created_at: Date;
    /**
     * 删除时间
     */
    deleted_at?: Date | null;
    /**
     * ID
     */
    id: string;
    /**
     * 账号名
     */
    name: string;
    /**
     * 更新时间
     */
    updated_at: Date;
}

export enum Type {
    IsUseNftAvatar = 'is_use_nft_avatar',
    NftAmount = 'nft_amount',
    NftUsernameLen = 'nft_username_len',
    SocAmount = 'soc_amount',
}

export interface AdminSuperUserBehaviorsBody {
    content: BehaviorRule[] | ContentObject;
}

/**
 * 权益管理
 */
export interface SuperUserBenefits {
    /**
     * 空投条件
     */
    conditions: Condition[];
    created_at: Date;
    deleted_at?: Date;
    /**
     * 手续费折扣
     */
    discount_rate: string;
    /**
     * 群人数
     */
    group_size: number;
    id: string;
    /**
     * 空投人数
     */
    max_investments: number;
    operator_account?: OperatorAccount;
    updated_at: Date;
    /**
     * 空投有效期 (天)
     */
    validity_days: number;
    /**
     * 权益值
     */
    value: number;
}

export enum Condition {
    Debate = 'debate',
    Follow = 'follow',
    Like = 'like',
    Quote = 'quote',
    Share = 'share',
    Vote = 'vote',
}

export interface PostBenefitsBody {
    /**
     * 空投条件，ConditionType 组合
     */
    conditions: Condition[];
    /**
     * 手续费折扣
     */
    discount_rate: number;
    /**
     * 群人数
     */
    group_size: number;
    /**
     * 空投人数
     */
    max_investments: number;
    /**
     * 空投有效期 (天)
     */
    validity_days: number;
    /**
     * 权益值
     */
    value: number;
}
