import { useRequest, useSetState } from 'ahooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { App, Button, Popconfirm, Spin, Typography } from 'antd';
import { ProTable } from '@ant-design/pro-components';
import { get } from 'lodash';

/**
 * Components
 */
import ComponentDrawerFormCustom from './components/drawer-form-custom';
import Permission from '@/components/permission';

/**
 * APIs
 */
import { dBenefits, gBenefits } from '@/services/super-users';

/**
 * Types
 */
import type { ProTableProps } from '@ant-design/pro-components';
import type { SuperUserBenefits } from '@/services/super-users';

type State = {
    openDrawer: boolean;
    entity: any;
    dataSource: any[];
};

const PageSuperUsersBenifit: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();
    const intl = useIntl();

    /**
     * States
     */
    const [state, setState] = useSetState<State>({
        dataSource: [],
        openDrawer: false,
        entity: null,
    });
    const [tableCellViolateLoading, setTableCellViolateLoading] = useSetState<Record<string, boolean>>({});

    /**
     * Requests
     */
    const { loading: loadingBenefits, refresh: refreshBenefits } = useRequest(gBenefits, {
        manual: false,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }
            setState({
                dataSource: data,
            });
        },
    });

    /**
     * ChildrenProps
     */
    const proTableProps: ProTableProps<SuperUserBenefits, any> = {
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                valueType: 'index',
                title: 'No.',
                width: 50,
                fixed: 'left',
            },
            {
                dataIndex: '_name',
                title: <FormattedMessage id="su.t.c.accountLevel" />,
                render: (_, _entity, index) => {
                    return `超级账号Lv.${index + 1}`;
                },
                width: 200,
            },
            {
                dataIndex: 'value',
                title: <FormattedMessage id="su.t.c.binifit" />,
                width: 200,
            },
            {
                dataIndex: 'discount_rate',
                title: <FormattedMessage id="su.t.c.rateSale" />,
                render: (val) => `${val}%`,
                width: 200,
            },
            {
                dataIndex: 'max_investments',
                title: <FormattedMessage id="su.t.c.airdropCount" />,
                width: 200,
            },
            {
                dataIndex: 'validity_days',
                title: <FormattedMessage id="su.t.c.airdropExprise" />,
                width: 200,
            },
            {
                dataIndex: 'conditions',
                title: <FormattedMessage id="su.t.c.airdropReson" />,
                render: (val: any) => {
                    return (val || []).map((v: string) => intl.formatMessage({ id: `su.c.${v}` })).join('、');
                },
            },
            {
                dataIndex: 'group_size',
                title: <FormattedMessage id="su.t.c.groupCount" />,
                width: 200,
            },
            {
                dataIndex: ['operator_account', 'name'],
                title: <FormattedMessage id="t.c.operatedBy" />,
                width: 200,
            },
            {
                dataIndex: 'updated_at',
                title: <FormattedMessage id="t.c.operatedAt" />,
                valueType: 'dateTime',
                width: 200,
            },
            {
                dataIndex: '_menu',
                valueType: 'option',
                width: 200,
                fixed: 'right',
                title: <FormattedMessage id="common.operation" />,
                hideInSearch: true,
                render: (_, entity) => [
                    <Permission permission="Super-Users_Benefit_Edit" key="edit">
                        <a
                            onClick={() => {
                                setState({
                                    entity,
                                    openDrawer: true,
                                });
                            }}
                        >
                            <FormattedMessage id="b.edit" />
                        </a>
                    </Permission>,
                    <Permission permission="Super-Users_Benefit_Delete" key="delete">
                        <Spin spinning={!!tableCellViolateLoading[entity.id]} size="small">
                            <Popconfirm
                                title={intl.formatMessage({ id: 'c.areyousure' })}
                                onConfirm={() => {
                                    setTableCellViolateLoading({
                                        [entity.id]: true,
                                    });
                                    dBenefits(entity.id)
                                        .then(({ data: { code, msg } }) => {
                                            if (code === 0) {
                                                message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
                                                refreshBenefits();
                                            } else {
                                                message.error(msg || 'Error');
                                            }
                                            setTableCellViolateLoading({
                                                [entity.id]: false,
                                            });
                                        })
                                        .catch((e) => {
                                            message.error(get(e, 'response.data.msg', 'Error'));
                                            setTableCellViolateLoading({
                                                [entity.id]: false,
                                            });
                                        });
                                }}
                            >
                                <Typography.Link type="danger">
                                    <FormattedMessage id="b.delete" />
                                </Typography.Link>
                            </Popconfirm>
                        </Spin>
                    </Permission>,
                ],
            },
        ],
        dataSource: state.dataSource,
        loading: loadingBenefits,
        options: {
            reload: refreshBenefits,
        },
        pagination: false,
        rowKey: 'id',
        scroll: {
            x: 2050,
            y: `calc(100vh - 232px)`,
        },
        search: false,
        tableAlertRender: false,
        toolBarRender: () => {
            return [
                <Permission permission="Super-Users_Benefit_Add" key="add">
                    <Button
                        disabled={state.dataSource.length >= 10}
                        type="primary"
                        onClick={() => {
                            setState({ openDrawer: true, entity: null });
                        }}
                        key="sureSort"
                    >
                        <FormattedMessage id="b.add" />
                    </Button>
                </Permission>,
            ];
        },
    };

    return (
        <>
            <ProTable {...proTableProps} />
            <ComponentDrawerFormCustom
                open={state.openDrawer}
                entity={state.entity}
                closeModelForm={(reload) => {
                    setState({ openDrawer: false });
                    reload && refreshBenefits();
                }}
            />
        </>
    );
};

export default PageSuperUsersBenifit;
