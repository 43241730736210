import axios from './index';
// import localAxios from 'axios';

// const axios = localAxios.create({
//     baseURL: 'http://127.0.0.1:4523/m1/4939924-0-default',
// });

/**
 * APIs
 */
// 获取 memeCoin 列表
export const gMemeCoin = (params: gMemeCoinParams) => {
    return axios<PageResType<MemeCoinResult>>('/v1/web3/memes', {
        method: 'get',
        params,
    });
};

// memeCoin 下架
export const delistMemeCoin = (data: DelistMemeCoinParams) => {
    return axios<PageResType<MemeCoinResult>>('/v1/web3/memes/delist', {
        method: 'put',
        data,
    });
};

/**
 * Types
 */
import { PageResType } from '@repo/typings/common';

/**
 * Meme Coin
 */
export type MemeCoinResult = {
    /**
     * 合约地址
     */
    contract: string;
    craeted_at: string;
    /**
     * 创建人 ID，用户user_id，Bigint，可能没有，是外部人员
     */
    creator?: Creator;
    /**
     * 创建者类型，1 内部 2 外部
     */
    creator_type: string;
    /**
     * 价格
     */
    current_price: string;
    /**
     * 1024字符
     */
    description: string;
    /**
     * 锁定meme量
     */
    frozen_meme_amount: string;
    /**
     * BigInt
     */
    id: string;
    /**
     * 图标url
     */
    image: string;
    /**
     * 毕业时间
     */
    launch_at: string;
    /**
     * 0 未毕业，1毕业中，2 已毕业
     */
    launch_status: number;
    name: string;
    operator_account: OperatorAccount;
    pricing_token_id: string;
    /**
     * 推荐时间
     */
    recommend_at: string;
    /**
     * 0 未推荐，1 已推荐
     */
    recommend_status: number;
    /**
     * 剩余可买meme量
     */
    remaining_meme_amount: string;
    socrates_group: string;
    /**
     * -2 下架，-1 失败，0 创建中，1 创建完成
     */
    status: string;
    symbol: string;
    telegram: string;
    /**
     * 总量，乘上价格就是市值
     */
    total_supply: string;
    updated_at: string;
    website: string;
    x: string;
    market_cap: string;
    meme_id: string;
};

/**
 * 创建人 ID，用户user_id，Bigint，可能没有，是外部人员
 */
export interface Creator {
    nickname: string;
    user_id: string;
    username: string;
}

export interface OperatorAccount {
    /**
     * ID
     */
    id: string;
    /**
     * 账号名
     */
    name: string;
}

export type gMemeCoinParams = {
    created_end?: string;
    created_start?: string;
    limit?: number;
    name?: string;
    page?: number;
    status?: string;
    symbol?: string;
};

export type FeGMemeCoinParams = gMemeCoinParams & {
    _created_date?: any[];
};

export type DelistMemeCoinParams = {
    meme_id: string | number;
    creator: string | number;
};
