export default {
    cp: {
        t: {
            c: {
                publishAt: 'Post At',
                shortsId: 'Shorts ID',
            },
        },
        f: {
            type: 'Type',
            deleted: 'Deleted',
            debateTopic: 'Debate',
            video: 'Video',
            audio: 'Audio',
            nft: 'NFT',
            image: 'Image',
            videoCover: 'Video Cover',
        },
    },
};
