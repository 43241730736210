import { useCallback, useEffect, useRef } from 'react';
import { App, Button, Tag } from 'antd';
import { DragSortTable } from '@ant-design/pro-components';
import type { ModalFormState } from './components/token-config-modal-form';
import { useRequest, useSetState } from 'ahooks';
import Permission, { useAccess } from '@/components/permission';
import TokenConfigModalForm from './components/token-config-modal-form';
import { FormattedMessage, useIntl } from 'react-intl';

/**
 * APIs
 */
import { tokenConfigs, tokenConfigsSorts } from '@/services/token-config';

/**
 * Types
 */
import type { IntlShape } from 'react-intl';
import type { ActionType, DragTableProps } from '@ant-design/pro-components';
import type { TokenConfig } from '@/services/token-config';
import { HolderOutlined } from '@ant-design/icons';

type State = {
    sorting: boolean;
    dataSource: TokenConfig[];
    modalFormState: ModalFormState;
    socCount: number;
    faceActiveCount: number;
};

const PageTokenConfig: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    const tableRef = useRef<ActionType>();

    const checkAccess = useAccess();

    /**
     * States
     */
    const intl: IntlShape = useIntl();
    const [state, setState] = useSetState<State>({
        sorting: false,
        dataSource: [],
        modalFormState: {
            open: false,
            data: undefined,
        },
        socCount: 0,
        faceActiveCount: 0,
    });

    /**
     * Callbacks
     */
    const handleDragSortEnd = useCallback((_beforeIndex: number, _afterIndex: number, newDataSource: any) => {
        setState({
            dataSource: newDataSource,
        });
    }, []);

    // Sort
    const { loading: loadingTokenConfigsSorts, run: runTokenConfigsSorts } = useRequest(tokenConfigsSorts, {
        manual: true,
        onSuccess: ({ data: { code, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }
            message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
            refreshTokenConfigs();
            setState({
                sorting: false,
            });
        },
    });

    /**
     * Requests
     */
    const {
        loading,
        refresh: refreshTokenConfigs,
        run: runTokenConfigs,
    } = useRequest(tokenConfigs, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setState({
                dataSource: data,
            });
        },
    });

    const hasSortPermission = checkAccess('Marketplace_Token_Token-Config_Sort') || checkAccess('super_admin');

    const proTableProps: DragTableProps<TokenConfig, any> = {
        actionRef: tableRef,
        bordered: true,
        columns: [
            {
                title: <FormattedMessage id="tc.t.c.sort" />,
                fixed: 'left',
                dataIndex: 'sort',
                hideInTable: !hasSortPermission,
                width: 50,
                className: 'drag-visible',
                render: () => {
                    if (state.sorting) {
                        return null;
                    }
                    return (
                        <Button
                            type="text"
                            size="small"
                            icon={<HolderOutlined />}
                            style={{ cursor: 'not-allowed', opacity: 0.5 }}
                        />
                    );
                },
            },
            {
                dataIndex: 'index',
                valueType: 'index',
                title: 'No.',
                width: 50,
                fixed: !hasSortPermission ? 'left' : undefined,
            },
            {
                dataIndex: ['token', 'id'],
                title: 'Token ID',
                width: 120,
            },
            {
                dataIndex: ['token', 'label'],
                width: 120,
                title: 'Token',
                render: (_, entity) => {
                    const url = entity?.token?.url;
                    return (
                        <div>
                            <img style={{ width: '20px', height: '20px', marginRight: '8px' }} src={url} />
                            {entity?.token?.label}
                        </div>
                    );
                },
            },
            // 色值
            {
                dataIndex: 'color',
                width: 120,
                title: <FormattedMessage id="p.tc.column.color" />,
                render: (_, entity) => {
                    return (
                        <Tag bordered color={entity.color}>
                            {entity.color}
                        </Tag>
                    );
                },
            },
            {
                dataIndex: 'sort',
                width: 120,
                title: <FormattedMessage id="p.tc.column.sort" />,
            },
            // 价格精度
            {
                dataIndex: 'price_scale',
                tooltip: <FormattedMessage id="p.tc.tips.priceScale" />,
                width: 200,
                title: <FormattedMessage id="p.tc.column.priceScale" />,
            },

            {
                dataIndex: 'show_scale',
                tooltip: <FormattedMessage id="p.tc.tips.showScale" />,
                width: 200,
                title: <FormattedMessage id="p.tc.column.showScale" />,
            },
            {
                dataIndex: 'decimals',
                width: 200,
                title: <FormattedMessage id="p.tc.column.decimals" />,
            },
            {
                dataIndex: 'min_top_up',
                width: 120,
                title: <FormattedMessage id="p.tc.column.minTopUp" />,
            },
            {
                dataIndex: 'min_trade_amount',
                width: 120,
                title: <FormattedMessage id="p.tc.column.minTradeAmount" />,
            },
            {
                dataIndex: 'type',
                width: 120,
                title: <FormattedMessage id="t.c.type" />,
                valueEnum: {
                    1: <FormattedMessage id="p.tc.column.type.majorToken" />,
                    2: <FormattedMessage id="p.tc.column.type.trustedToken" />,
                    3: <FormattedMessage id="p.tc.column.type.mintableToken" />,
                    4: <FormattedMessage id="p.tc.column.type.blacklist" />,
                },
            },
            {
                dataIndex: 'status',
                width: 120,
                title: <FormattedMessage id="common.status" />,
                hideInSearch: true,
                render: (dom) => (dom === 1 ? <Tag color="green">Enabled</Tag> : <Tag color="red">Disabled</Tag>),
            },
            {
                dataIndex: 'operator',
                width: 120,
                title: <FormattedMessage id="t.c.operatedBy" />,
            },
            {
                dataIndex: 'updated_at',
                width: 200,
                valueType: 'dateTime',
                title: <FormattedMessage id="common.table.columns.operatedAt" />,
            },
            {
                fixed: 'right',
                key: 'option',
                title: <FormattedMessage id="t.c.action" />,
                valueType: 'option',
                width: 200,
                render: (_, entity) => [
                    <Permission permission="Marketplace_Token_Token-Config_Edit">
                        <a
                            onClick={() => {
                                setState({
                                    modalFormState: {
                                        open: true,
                                        data: entity,
                                    },
                                });
                            }}
                        >
                            <FormattedMessage id="common.edit" />
                        </a>
                    </Permission>,
                ],
            },
        ],
        dataSource: state.dataSource,
        dragSortKey: state.sorting ? 'sort' : '_not_sortable',
        dragSortHandlerRender: () => (
            <Button type="text" size="small" icon={<HolderOutlined />} style={{ cursor: 'move' }} />
        ),
        loading,
        pagination: false,
        options: {
            reload: refreshTokenConfigs,
        },
        rowKey: 'id',
        toolBarRender: () => {
            if (state.sorting) {
                return [
                    <Button
                        type="primary"
                        loading={loadingTokenConfigsSorts}
                        onClick={() => {
                            runTokenConfigsSorts({ token_ids: state.dataSource.map((item) => item.token.token_id) });
                        }}
                        key="sureSort"
                    >
                        <FormattedMessage id="tc.b.sureSort" />
                    </Button>,
                    <Button
                        loading={loadingTokenConfigsSorts}
                        onClick={() => {
                            setState({ sorting: false });
                            refreshTokenConfigs();
                        }}
                        key="cancelSort"
                    >
                        <FormattedMessage id="tc.b.cancelSort" />
                    </Button>,
                ];
            }
            return [
                <Permission permission="Marketplace_Token_Token-Config_Sort">
                    <Button
                        type="primary"
                        ghost
                        onClick={() => {
                            setState({ sorting: true });
                        }}
                    >
                        <FormattedMessage id="tc.t.c.sort" />
                    </Button>
                </Permission>,
            ];
        },
        scroll: {
            y: 'calc(100vh - 232px)',
            x: 1500,
        },
        search: false,
        onDragSortEnd: handleDragSortEnd,
    };

    /**
     * Effects
     */
    useEffect(() => {
        runTokenConfigs({
            token_id: '',
        });
    }, []);

    return (
        <>
            <DragSortTable {...proTableProps} />

            <TokenConfigModalForm
                state={state.modalFormState}
                reload={refreshTokenConfigs}
                onClose={() => {
                    setState({
                        modalFormState: {
                            open: false,
                            data: undefined,
                        },
                    });
                }}
            />
        </>
    );
};

export default PageTokenConfig;
