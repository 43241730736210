import { useState } from 'react';
import { useBoolean } from 'ahooks';
import { get } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { App, Button, Col, Drawer, Form, InputNumber, Row, Space } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

/**
 * Utils
 */
import { apiErrorMsg } from '@/utils/apiErrorMsg';

/**
 * APIs
 */
import { putBehaviors } from '@/services/super-users';

/**
 * Types
 */
import type { DrawerProps, FormProps } from 'antd';
import type { IntlShape } from 'react-intl';
import { AdminSuperUserBehaviors, AdminSuperUserBehaviorsBody } from '@/services/super-users';

type Props = {
    open: boolean;
    entity: AdminSuperUserBehaviors | null;
    closeModelForm: (reload?: boolean) => void;
};

const ComponentModalFormCustom: React.FC<Props> = ({ open, entity, closeModelForm }) => {
    /**
     * Hooks
     */
    const { message } = App.useApp();
    const intl: IntlShape = useIntl();

    /**
     * State
     */
    const [submitting, { setFalse: setSubmittingFlase, setTrue: setSubmittingTrue }] = useBoolean(false);
    const [form] = Form.useForm();
    const [isOne, setIsOne] = useState(true);

    /**
     * DrawerProps
     */
    const drawerProps: DrawerProps = {
        footer: (
            <div style={{ textAlign: 'right' }}>
                <Space>
                    <Button type="default" onClick={() => closeModelForm()}>
                        {intl.formatMessage({ id: 'b.cancel' })}
                    </Button>
                    <Button
                        type="primary"
                        loading={submitting}
                        onClick={() => {
                            form.validateFields()
                                .then(async (formData) => {
                                    setSubmittingTrue();
                                    const submitData = formData;
                                    if (isOne) {
                                        submitData.content = formData.content[0];
                                    }
                                    try {
                                        const res = await putBehaviors(entity?.id as string, submitData);
                                        if (get(res, 'data.code') === 0) {
                                            message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
                                            closeModelForm(true);
                                        } else {
                                            apiErrorMsg(message, intl, {}, res.data);
                                            setSubmittingFlase();
                                        }
                                    } catch {
                                        setSubmittingFlase();
                                    }
                                })
                                .catch(() => {});
                        }}
                    >
                        {intl.formatMessage({ id: 'b.confirm' })}
                    </Button>
                </Space>
            </div>
        ),
        destroyOnClose: true,
        maskClosable: false,
        open: open,
        size: 'large',
        title: <FormattedMessage id={entity ? 'b.edit' : 'b.add'} />,
        afterOpenChange: (open) => {
            if (!open) {
                setSubmittingFlase();
                form.resetFields();
            } else if (entity) {
                const content = entity.content;
                if (Array.isArray(content)) {
                    form.setFieldsValue({
                        content: content.map((v) => {
                            return {
                                label: `${v.label}`,
                                value: v.value,
                            };
                        }),
                    });
                    setIsOne(entity.type === 'is_use_nft_avatar');
                } else {
                    form.setFieldsValue({
                        content: [
                            {
                                label: content.label,
                                value: content.value,
                            },
                        ],
                    });
                    setIsOne(true);
                }
            }
        },
        onClose: () => {
            closeModelForm();
        },
    };

    /**
     * ProformProps
     */
    const formProps: FormProps<AdminSuperUserBehaviorsBody> = {
        form: form,
        layout: 'vertical',
        validateTrigger: 'onBlur',
    };

    return (
        <Drawer {...drawerProps}>
            <Form {...formProps}>
                <Form.List name="content">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, ...restField }, index) => {
                                const showIndex = index + 1;
                                return (
                                    <Row key={key} gutter={16} align="bottom">
                                        <Col span={11}>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'label']}
                                                label={intl.formatMessage(
                                                    { id: 'su.f.actionLabel' },
                                                    { value: showIndex },
                                                )}
                                                rules={[{ required: true }]}
                                            >
                                                <InputNumber
                                                    min={1}
                                                    max={100000000}
                                                    precision={0}
                                                    disabled={isOne}
                                                    style={{ width: '100%' }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={11}>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'value']}
                                                label={intl.formatMessage(
                                                    { id: 'su.f.targetReward' },
                                                    { value: showIndex },
                                                )}
                                                rules={[{ required: true }]}
                                            >
                                                <InputNumber
                                                    min={1}
                                                    max={100000000}
                                                    precision={0}
                                                    style={{ width: '100%' }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        {!isOne && (
                                            <Col span={2}>
                                                <Form.Item>
                                                    <MinusCircleOutlined
                                                        style={{
                                                            cursor: fields.length > 1 ? 'pointer' : 'not-allowed',
                                                        }}
                                                        onClick={() => {
                                                            if (fields.length > 1) {
                                                                remove(name);
                                                            }
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        )}
                                    </Row>
                                );
                            })}
                            {!isOne && fields.length < 10 && (
                                <Form.Item>
                                    <Button type="dashed" icon={<PlusOutlined />} onClick={() => add()} block>
                                        Add
                                    </Button>
                                </Form.Item>
                            )}
                        </>
                    )}
                </Form.List>
            </Form>
        </Drawer>
    );
};

export default ComponentModalFormCustom;
