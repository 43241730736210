import { useEffect, useRef, useState } from 'react';
import { useRequest, useSetState } from 'ahooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { App, Button, DatePicker, Flex, Form, Input, Modal, Popconfirm, Select, Spin, Typography } from 'antd';
import { ProCard, ProTable } from '@ant-design/pro-components';
import dayjs from 'dayjs';
import { get, isEmpty, last, omitBy, trim } from 'lodash';

/**
 * Components
 */
import Permission from '@/components/permission';
import ComponentSearchFormSelectOperator from '@/components/search-form-select-operator';
import VideoPlayer from '@repo/ui/video-player';
import { Pagination } from '@repo/ui/pagination';

/**
 * APIs
 */
import { batchDeleteShort, dShort, shorts } from '@/services/shorts';

/**
 * Types
 */
import type { FormProps } from 'antd';
import type { ActionType, ProTableProps } from '@ant-design/pro-components';
import type { ShortsParams, ShortsItem } from '@/services/shorts';
import ComponentTableCellDebate from '@/components/table-cell/table-cell-debate';
import type { PaginationProps } from '@repo/ui/pagination';

// table 批量选择
type SeletRowState = {
    selects: string[];
    selectedRows: any[];
};

/**
 * Constants
 */
const getDefaultTodayDateTime = () => {
    const today = dayjs();
    return [today.startOf('d'), today.endOf('d')];
};

/**
 * State
 */
type State = {
    dataSource: ShortsItem[];
};

const PageContentShorts: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    const [formSearch] = Form.useForm();

    const { formatMessage } = useIntl();

    const searchRef = useRef<HTMLDivElement>(null);

    const tableRef = useRef<ActionType>();

    const intl = useIntl();

    /**
     * States
     */
    const [state, setState] = useSetState<State>({
        dataSource: [],
    });

    const [tableCellLoading, setTableCellLoading] = useSetState<Record<string, boolean>>({});

    const [selectState, setSelectState] = useSetState<SeletRowState>({
        selects: [],
        selectedRows: [],
    });

    /**
     * Paginations
     */
    const [pagination, setPagination] = useSetState<PaginationProps>({
        current: 1,
        hasMore: false,
        searchAfter: '',
        size: 20,
        total: 0,
        onChange: ({ page, searchAfter, size }) => {
            setPagination({
                current: page,
                size: size,
                searchAfter,
            });
        },
    });

    /**
     * Payloads
     */
    const [payload, setPayload] = useState<ShortsParams>({
        created_start: getDefaultTodayDateTime()[0].format(),
        created_end: getDefaultTodayDateTime()[1].format(),
    });

    /**
     * Request
     */
    const { run: runBatchDelete, loading: loadingBatchDelete } = useRequest(batchDeleteShort, {
        manual: true,
        onSuccess: ({ data: { code, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            refreshShorts();
        },
    });

    const {
        loading: loadingShorts,
        refresh: refreshShorts,
        run: runShorts,
    } = useRequest(shorts, {
        manual: true,
        onSuccess: ({ data: { code, data, msg, meta } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setState({
                dataSource: data,
            });

            setSelectState({
                selects: [],
                selectedRows: [],
            });

            setPagination({
                size: meta.size,
                total: meta.total,
                hasMore: meta.has_more,
                searchAfter: meta.search_after || '',
            });
        },
    });
    /**
     * ChildrenProps
     */

    const formSearchProps: FormProps<ShortsParams> = {
        form: formSearch,
        layout: 'inline',
        onFinish: (values) => {
            if (values.fe_created_date) {
                values.created_start = values.fe_created_date[0].format();
                values.created_end = values.fe_created_date[1].format();
                values.fe_created_date = undefined;
            }

            if (values.fe_updated_date) {
                values['deleted_at[0]'] = values.fe_updated_date[0].startOf('d').format();
                values['deleted_at[1]'] = values.fe_updated_date[1].endOf('d').format();
                values.fe_updated_date = undefined;
            }

            if (values.fe_is_deleted) {
                values.is_deleted = values.fe_is_deleted === 'normal' ? 'false' : 'true';
            } else {
                values.is_deleted = undefined;
            }

            if (values.description_fuzzy) {
                values.description_fuzzy = trim(values.description_fuzzy);
            }

            if (values.debate_fuzzy) {
                values.debate_fuzzy = trim(values.debate_fuzzy);
            }

            values.fe_is_deleted = undefined;

            setPagination({
                current: 1,
                searchAfter: '',
            });
            setPayload({
                ...omitBy(values, isEmpty),
            });
        },
    };

    const proTableProps: ProTableProps<ShortsItem, any> = {
        actionRef: tableRef,
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                fixed: 'left',
                title: 'No.',
                width: 50,
                render: (_, __, index) => index + 1,
            },
            {
                dataIndex: 'id',
                ellipsis: true,
                title: <FormattedMessage id="cp.t.c.shortsId" />,
                width: 200,
            },
            {
                dataIndex: 'user_id',
                ellipsis: true,
                title: <FormattedMessage id="rm.t.c.postAccUid" />,
                width: 200,
            },
            {
                dataIndex: ['debate', 'title'],
                ellipsis: true,
                title: <FormattedMessage id="cad.t.c.title" />,
                width: 200,
            },
            {
                dataIndex: 'description',
                title: <FormattedMessage id="rm.t.c.content" />,
            },
            {
                dataIndex: '_debate_users',
                title: <FormattedMessage id="rm.t.c.debateUsers" />,
                render: (_, entity) => (
                    <ComponentTableCellDebate
                        forText={get(entity, 'debate.for_text')}
                        againstText={get(entity, 'debate.against_text')}
                    />
                ),
                width: 200,
            },
            {
                dataIndex: '_shorts',
                title: <FormattedMessage id="cp.f.video" />,
                render: (_, entity) => (
                    <VideoPlayer src={entity.small_url || entity.source_url || entity.large_url} cover={entity.cover} />
                ),
                width: 120,
            },
            {
                dataIndex: 'is_deleted',
                title: <FormattedMessage id="t.c.status" />,
                render: (_text, entity: any) =>
                    entity.is_deleted
                        ? intl.formatMessage({ id: 'cp.f.deleted' })
                        : intl.formatMessage({ id: 'rm.b.normal' }),
                width: 120,
            },
            {
                dataIndex: 'created_at',
                title: <FormattedMessage id="cp.t.c.publishAt" />,
                valueType: 'dateTime',
                width: 200,
            },
            {
                dataIndex: 'log_operation',
                title: <FormattedMessage id="t.c.operatedBy" />,
                render: (_, entity) => {
                    return get(last(get(entity, 'log_operation')), 'operator.name') || '-';
                },
                width: 200,
            },
            {
                dataIndex: 'deleted_at',
                title: <FormattedMessage id="t.c.operatedAt" />,
                valueType: 'dateTime',
                width: 200,
            },
            {
                dataIndex: '_menu',
                valueType: 'option',
                width: 200,
                fixed: 'right',
                title: <FormattedMessage id="common.operation" />,
                hideInSearch: true,
                render: (_, entity: any) => [
                    !entity.is_deleted && (
                        <Permission permission="Content_Shorts_Delete" key="delete">
                            <Spin spinning={!!tableCellLoading[`${entity.id}${entity.video_id}`]} size="small">
                                <Popconfirm
                                    title={intl.formatMessage({ id: 'c.areyousure' })}
                                    onConfirm={() => {
                                        setTableCellLoading({
                                            [`${entity.id}${entity.video_id}`]: true,
                                        });
                                        dShort({
                                            user_id: entity.user_id,
                                            video_id: entity.id,
                                        })
                                            .then(({ data: { code, msg } }) => {
                                                if (code === 0) {
                                                    message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
                                                    refreshShorts();
                                                } else {
                                                    message.error(msg || 'Error');
                                                }
                                                setTableCellLoading({
                                                    [`${entity.id}${entity.video_id}`]: false,
                                                });
                                            })
                                            .catch((e) => {
                                                message.error(get(e, 'response.data.msg', 'Error'));
                                                setTableCellLoading({
                                                    [`${entity.id}${entity.video_id}`]: false,
                                                });
                                            });
                                    }}
                                >
                                    <Typography.Link type="danger">
                                        <FormattedMessage id="b.delete" />
                                    </Typography.Link>
                                </Popconfirm>
                            </Spin>
                        </Permission>
                    ),
                ],
            },
        ],
        dataSource: state.dataSource,
        loading: loadingShorts,
        options: {
            reload: refreshShorts,
        },
        pagination: false,
        rowKey: (entity) => `${entity.id}${entity.video_id}`,
        rowSelection: {
            columnWidth: 50,
            selectedRowKeys: selectState.selects,
            getCheckboxProps: (record: any) => {
                return {
                    disabled: loadingBatchDelete || record.is_deleted,
                };
            },
            onChange: (selectedRowKeys, selectedRows) => {
                setSelectState({
                    selects: selectedRowKeys as string[],
                    selectedRows,
                });
            },
        },
        scroll: {
            x: 2140,
            y: `calc(100vh - 280px - ${searchRef.current?.clientHeight}px - 16px)`,
        },
        tableAlertRender: false,
        toolBarRender: () => {
            if (!selectState.selects.length || loadingShorts) {
                return [];
            }
            return [
                <Permission permission="Content_Shorts_Delete" key="bulkDelete">
                    <Button
                        type="primary"
                        loading={loadingBatchDelete}
                        onClick={() => {
                            Modal.confirm({
                                title: intl.formatMessage({ id: 'c.areyousureBulk' }),
                                onOk: async () => {
                                    const videos = selectState.selectedRows.map((v) => {
                                        return {
                                            user_id: v.user_id,
                                            video_id: v.id,
                                        };
                                    });
                                    await runBatchDelete({ videos });
                                },
                            });
                        }}
                    >
                        <FormattedMessage id="b.bulkDelete" />
                    </Button>
                </Permission>,
            ];
        },
        search: false,
    };

    /**
     * Effects
     */
    useEffect(() => {
        runShorts({
            ...payload,
            size: `${pagination.size}`,
            search_after: pagination.searchAfter,
        });
    }, [pagination.current, pagination.size, payload]);

    return (
        <>
            <ProCard className="mb-16" ref={searchRef}>
                <Form {...formSearchProps}>
                    <Flex gap={16} wrap={true}>
                        <Form.Item name="id" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'cp.t.c.shortsId' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="user_id" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'rm.t.c.postAccUid' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="debate_fuzzy" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'cad.t.c.title' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="description_fuzzy" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'rm.t.c.content' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="fe_is_deleted" style={{ marginRight: 0 }}>
                            <Select
                                allowClear={true}
                                showSearch={true}
                                optionFilterProp="label"
                                options={[
                                    {
                                        label: intl.formatMessage({ id: 'rm.b.normal' }),
                                        value: 'normal',
                                    },
                                    {
                                        label: intl.formatMessage({ id: 'cp.f.deleted' }),
                                        value: 'deleted',
                                    },
                                ]}
                                placeholder={<FormattedMessage id="t.c.status" />}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item
                            label={formatMessage({ id: 'cp.t.c.publishAt' })}
                            name="fe_created_date"
                            style={{ marginRight: 0 }}
                            initialValue={getDefaultTodayDateTime()}
                        >
                            <DatePicker.RangePicker
                                onChange={(rangeDate) => {
                                    if (!rangeDate || !rangeDate[0] || !rangeDate[1]) {
                                        return;
                                    }
                                    const newStart = dayjs(rangeDate[0].format('YYYY-MM-DD HH:00:00'));
                                    const newDnd = dayjs(rangeDate[1].format('YYYY-MM-DD HH:59:59'));
                                    formSearch.setFieldValue('fe_created_date', [newStart, newDnd]);
                                }}
                                showTime={{ format: 'HH' }}
                                allowClear={true}
                            />
                        </Form.Item>

                        <Form.Item name="operator_id" style={{ marginRight: 0 }}>
                            <ComponentSearchFormSelectOperator model="Content_Shorts" />
                        </Form.Item>

                        <Form.Item
                            label={formatMessage({ id: 't.c.operatedAt' })}
                            name="fe_updated_date"
                            style={{ marginRight: 0 }}
                        >
                            <DatePicker.RangePicker allowClear={true} />
                        </Form.Item>

                        <Button
                            loading={loadingShorts}
                            type="primary"
                            onClick={() => {
                                formSearch.submit();
                            }}
                        >
                            <FormattedMessage id="b.search" />
                        </Button>

                        <Button
                            onClick={() => {
                                formSearch.resetFields();
                            }}
                        >
                            <FormattedMessage id="b.reset" />
                        </Button>
                    </Flex>
                </Form>
            </ProCard>

            <ProTable {...proTableProps} />

            <Pagination {...pagination} isLoading={loadingShorts} />
        </>
    );
};

export default PageContentShorts;
