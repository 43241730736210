import { ProDescriptions } from '@ant-design/pro-components';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';

/**
 * Types
 */
import type { ProDescriptionsProps } from '@ant-design/pro-components';
import type { AmbassadorPublicPoolResult } from '@/services/ambassadors';

type Props = {
    dataSource: AmbassadorPublicPoolResult;
};

const ComponentDetail: React.FC<Props> = ({ dataSource }) => {
    /**
     * ChildrenProps
     */
    const proDescriptions: ProDescriptionsProps = {
        bordered: true,
        column: 2,
        columns: [
            {
                dataIndex: '1',
                title: <FormattedMessage id="t.c.fullName" />,
                render: (_, entity) => {
                    const { first_name = '', last_name = '' } =
                        get(entity, 'application.request.ambassador_info') || {};

                    return `${first_name} ${last_name}`;
                },
            },
            {
                dataIndex: ['application', 'request', 'ambassador_info', 'age'],
                title: <FormattedMessage id="t.c.age" />,
            },
            {
                dataIndex: ['application', 'request', 'ambassador_info', 'gender'],
                title: <FormattedMessage id="t.c.gender" />,
            },
            {
                dataIndex: ['application', 'request', 'ambassador_info', 'country_of_residence'],
                title: <FormattedMessage id="t.c.countryRegion" />,
            },
            {
                dataIndex: ['application', 'request', 'email'],
                title: <FormattedMessage id="t.c.email" />,
            },
            {
                dataIndex: ['application', 'request', 'is_student'],
                title: <FormattedMessage id="t.c.occupation" />,
                renderText: (text) => (text ? 'Student' : 'Professional'),
            },
            {
                dataIndex: '2',
                span: 2,
                title: <FormattedMessage id="p.ambassadors.detail.occupationDetails" />,
                render: (_, entity) => {
                    const { current_degree, current_occupation_description, is_student, study_field_introduction } =
                        get(entity, 'application.request') || {};

                    if (is_student) {
                        return `${current_degree}, ${study_field_introduction}`;
                    }

                    return current_occupation_description;
                },
            },
            {
                dataIndex: '3',
                span: 2,
                title: <FormattedMessage id="p.ambassadors.detail.web3Knowledge" />,
                render: (_, entity) => {
                    const { has_web3_knowledge, web3_knowledge } = get(entity, 'application.request') || {};

                    return `${has_web3_knowledge ? 'Yes' : 'No'}, ${web3_knowledge || '-'}`;
                },
            },
            {
                dataIndex: '4',
                span: 2,
                title: <FormattedMessage id="p.ambassadors.detail.web3Experience" />,
                render: (_, entity) => {
                    const { description, web3_experience } = get(entity, 'application.request.web3_experience') || {};

                    return `${web3_experience ? 'Yes' : 'No'}, ${description || '-'}`;
                },
            },
            {
                dataIndex: ['application', 'request', 'social_media_presence', 'platform'],
                title: <FormattedMessage id="p.ambassadors.t.c.socialMedia" />,
            },
            {
                dataIndex: ['application', 'request', 'social_media_presence', 'link'],
                title: <FormattedMessage id="t.c.link" />,
                renderText: (text) => (
                    <a href={text?.startsWith('http') ? text : `http://${text}`} target="_blank">
                        {text}
                    </a>
                ),
            },
            {
                dataIndex: ['application', 'request', 'social_media_presence', 'fan_count'],
                title: <FormattedMessage id="p.ambassadors.t.c.followers" />,
            },
            {
                dataIndex: ['application', 'request', 'fluent_languages'],
                title: <FormattedMessage id="p.ambassadors.detail.languages" />,
            },
            {
                dataIndex: ['application', 'request', 'future_leader'],
                title: <FormattedMessage id="p.ambassadors.detail.willingnessToLead" />,
                renderText: (text) => (text ? 'Yes' : 'No'),
            },
            {
                dataIndex: ['application', 'request', 'daily_ambassador_time'],
                title: <FormattedMessage id="p.ambassadors.detail.timeWillingToSpend" />,
            },
            {
                dataIndex: '5',
                span: 2,
                title: <FormattedMessage id="p.ambassadors.detail.promotionExperience" />,
                render: (_, entity) => {
                    const { has_promotion_experience, promotion_experience } = get(entity, 'application.request') || {};

                    return `${has_promotion_experience ? 'Yes' : 'No'}, ${promotion_experience || '-'}`;
                },
            },
            {
                dataIndex: ['application', 'request', 'expectations'],
                span: 2,
                title: <FormattedMessage id="p.ambassadors.detail.motivation" />,
            },
            {
                dataIndex: ['application', 'request', 'promo_recruit_strategies'],
                span: 2,
                title: <FormattedMessage id="p.ambassadors.detail.promotionPlan" />,
            },
            {
                dataIndex: ['application', 'request', 'questions'],
                span: 2,
                title: <FormattedMessage id="p.ambassadors.detail.questions" />,
            },
        ],
        dataSource,
        size: 'middle',
    };

    const proDescriptionsV4: ProDescriptionsProps = {
        bordered: true,
        column: 2,
        columns: [
            {
                dataIndex: '1',
                title: <FormattedMessage id="t.c.fullName" />,
                render: (_, entity) => {
                    const { first_name = '', last_name = '' } =
                        get(entity, 'application.request.ambassador_info') || {};

                    return `${first_name} ${last_name}`;
                },
            },
            {
                dataIndex: ['application', 'request', 'email'],
                title: <FormattedMessage id="t.c.email" />,
            },
            {
                dataIndex: '2',
                span: 2,
                title: <FormattedMessage id="p.ambassadors.detail.satisfyCurrentLife" />,
                render: (_, entity) => {
                    const { satisfy_current_life, current_life_description } = get(entity, 'application.request') || {};

                    return `${satisfy_current_life ? 'Yes' : 'No'}, ${current_life_description || '-'}`;
                },
            },
            {
                dataIndex: '3',
                span: 2,
                title: <FormattedMessage id="p.ambassadors.detail.needChangeLife" />,
                render: (_, entity) => {
                    const { need_change_life, change_life_description } = get(entity, 'application.request') || {};

                    return `${need_change_life ? 'Yes' : 'No'}, ${change_life_description || '-'}`;
                },
            },
            {
                dataIndex: '4',
                span: 2,
                title: <FormattedMessage id="p.ambassadors.detail.bestChangeLife" />,
                render: (_, entity) => {
                    const { best_change_life, best_change_life_description } = get(entity, 'application.request') || {};

                    return `${best_change_life ? 'Yes' : 'No'}, ${best_change_life_description || '-'}`;
                },
            },
        ],
        dataSource,
        size: 'middle',
    };

    const childrenProps = get(dataSource, 'application.request.version') === 'v4' ? proDescriptionsV4 : proDescriptions;

    return <ProDescriptions {...childrenProps} />;
};

export default ComponentDetail;
