import { useEffect, useRef, useState } from 'react';
import { App, Button, Flex, Form, DatePicker, Select, Typography, Tag } from 'antd';
import { isEmpty, omitBy } from 'lodash';
import { useCountDown, useRequest, useSetState } from 'ahooks';
import { FormattedMessage, useIntl } from 'react-intl';
import Permission from '@/components/permission';

/**
 * APIs
 */
import { userIdentityVerificationHistory, userIdentityVerificationHistoryExport } from '@/services/user';

/**
 * Types
 */
import type { FormProps, PaginationProps } from 'antd';
import type { UserIdentityVerificationHistory, UserIdentityVerificationHistoryParams } from '@/services/user';
import { ProCard, ProTable, type ProTableProps } from '@ant-design/pro-components';
import SearchUser from '@/components/search-user';

type State = {
    dataSource: UserIdentityVerificationHistory[];
};

/**
 * Constants
 */
const defaultPayload = {
    name: undefined,
    nickname: undefined,
    register_start: undefined,
    register_end: undefined,
    parent_uid: undefined,
    user_id: undefined,
    username: undefined,
    identity_status: undefined,
    liveness_status: undefined,
    liveness2_status: undefined,
    identity_start: undefined,
    identity_end: undefined,
    liveness_start: undefined,
    liveness_end: undefined,
    liveness2_start: undefined,
    liveness2_end: undefined,
};

const PageIdentityVerificationHistory: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    const [formSearch] = Form.useForm();

    const { formatMessage } = useIntl();

    const searchRef = useRef<HTMLDivElement>(null);

    const [target, setTarget] = useState(0);

    const [countdown] = useCountDown({
        targetDate: target,
    });

    const [state, setState] = useSetState<State>({
        dataSource: [],
    });

    /**
     * Paginations
     */
    const [pagination, setPagination] = useSetState<PaginationProps>({
        current: 1,
        pageSize: 20,
        onChange: (page, pageSize) => {
            setPagination({
                current: page,
                pageSize,
            });
        },
    });

    /**
     * Payloads
     */
    const [payload, setPayload] = useSetState<UserIdentityVerificationHistoryParams>({});

    /**
     * Requests
     */
    const {
        loading,
        refresh: refreshIdentityVerificationHistory,
        run: runIdentityVerificationHistory,
    } = useRequest(userIdentityVerificationHistory, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setPagination({
                current: data.meta.currentPage,
                pageSize: data.meta.perPage,
                total: data.meta.total,
            });

            setState({
                dataSource: data.list,
            });
        },
    });

    /**
     * ChildrenProps
     */
    const formSearchProps: FormProps = {
        form: formSearch,
        // initialValues: {
        //     date: [dayjs(payload.register_start), dayjs(payload.register_end)],
        // },
        layout: 'inline',
        onFinish: (values) => {
            if (values.identity_at && values.identity_at.length > 0) {
                values.identity_start = values.identity_at[0].startOf('d').format();
                values.identity_end = values.identity_at[1].endOf('d').format();
                delete values.identity_at;
            }

            if (values.liveness_at && values.liveness_at.length > 0) {
                values.liveness_start = values.liveness_at[0].startOf('d').format();
                values.liveness_end = values.liveness_at[1].endOf('d').format();
                delete values.liveness_at;
            }

            if (values.liveness2_at && values.liveness2_at.length > 0) {
                values.liveness2_start = values.liveness2_at[0].startOf('d').format();
                values.liveness2_end = values.liveness2_at[1].endOf('d').format();
                delete values.liveness2_at;
            }

            setPagination({
                current: 1,
                pageSize: 20,
            });

            setPayload({
                ...defaultPayload,
                ...omitBy(values, isEmpty),
            });
        },
    };

    const proTableProps: ProTableProps<UserIdentityVerificationHistory, any> = {
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                valueType: 'index',
                title: 'No.',
                width: 50,
                fixed: 'left',
            },
            {
                dataIndex: 'user_id',
                title: <FormattedMessage id="t.c.user" />,
                width: 200,
                render: (_, entity) => {
                    const { username = '-', nickname = '-' } = entity.user_info || {};

                    return (
                        <>
                            <div>{nickname}</div>

                            <Typography.Text copyable={{ text: entity.user_info.id, tooltips: 'Copy UID' }}>
                                @{username}
                            </Typography.Text>
                        </>
                    );
                },
            },
            {
                dataIndex: 'liveness_at',
                valueType: 'dateTime',
                title: <FormattedMessage id="p.uivh.columns.livenessAt" />,
            },
            {
                dataIndex: 'identity_status',
                valueType: 'select',
                title: <FormattedMessage id="p.uivh.columns.identityStatus" />,
                valueEnum: {
                    Verified: <Tag color="success">{formatMessage({ id: 'p.uivh.status.verified' })}</Tag>,
                    Unverified: <Tag color="error">{formatMessage({ id: 'p.uivh.status.unverified' })}</Tag>,
                },
            },
            {
                dataIndex: 'identity_at',
                valueType: 'dateTime',
                title: <FormattedMessage id="p.uivh.columns.identityAt" />,
            },
            {
                dataIndex: 'liveness2_status',
                valueType: 'select',
                title: <FormattedMessage id="p.uivh.columns.liveness2Status" />,
                valueEnum: {
                    Verified: <Tag color="success">{formatMessage({ id: 'p.uivh.status.verified' })}</Tag>,
                    Unverified: <Tag color="error">{formatMessage({ id: 'p.uivh.status.unverified' })}</Tag>,
                },
            },
            {
                dataIndex: 'liveness2_at',
                valueType: 'dateTime',
                title: <FormattedMessage id="p.uivh.columns.liveness2At" />,
            },
        ],
        dataSource: state.dataSource,
        loading,
        pagination,
        options: {
            reload: refreshIdentityVerificationHistory,
        },
        rowKey: 'id',
        scroll: {
            x: 1250,
            y: `calc(100vh - 224px - ${searchRef.current?.clientHeight || 64}px - 16px - 48px)`,
        },
        search: false,
        tableAlertRender: false,
    };

    /**
     * Effects
     */
    useEffect(() => {
        runIdentityVerificationHistory({
            page: pagination.current,
            limit: pagination.pageSize,
            ...payload,
        });
    }, [pagination.current, pagination.pageSize, payload]);

    return (
        <>
            <ProCard className="mb-16" ref={searchRef}>
                <Form {...formSearchProps}>
                    <Flex gap={16} wrap={true}>
                        <SearchUser form={formSearch} />

                        <Form.Item name="identity_status" style={{ marginRight: 0 }}>
                            <Select
                                allowClear={true}
                                optionFilterProp="label"
                                options={[
                                    {
                                        label: formatMessage({ id: 'p.uivh.status.verified' }),
                                        value: 'Verified',
                                    },
                                    {
                                        label: formatMessage({ id: 'p.uivh.status.unverified' }),
                                        value: 'Unverified',
                                    },
                                ]}
                                placeholder={<FormattedMessage id="p.uivh.columns.identityStatus" />}
                                showSearch={true}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="liveness2_status" style={{ marginRight: 0 }}>
                            <Select
                                allowClear={true}
                                optionFilterProp="label"
                                options={[
                                    {
                                        label: formatMessage({ id: 'p.uivh.status.verified' }),
                                        value: 'Verified',
                                    },
                                    {
                                        label: formatMessage({ id: 'p.uivh.status.unverified' }),
                                        value: 'Unverified',
                                    },
                                ]}
                                placeholder={<FormattedMessage id="p.uivh.columns.liveness2Status" />}
                                showSearch={true}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item
                            label={formatMessage({ id: 'p.uivh.columns.livenessAt' })}
                            name="liveness_at"
                            style={{ marginRight: 0 }}
                        >
                            <DatePicker.RangePicker allowClear={true} />
                        </Form.Item>

                        <Form.Item
                            label={formatMessage({ id: 'p.uivh.columns.identityAt' })}
                            name="identity_at"
                            style={{ marginRight: 0 }}
                        >
                            <DatePicker.RangePicker allowClear={true} />
                        </Form.Item>

                        <Form.Item
                            label={formatMessage({ id: 'p.uivh.columns.liveness2At' })}
                            name="liveness2_at"
                            style={{ marginRight: 0 }}
                        >
                            <DatePicker.RangePicker allowClear={true} />
                        </Form.Item>

                        <Button
                            loading={loading}
                            type="primary"
                            onClick={() => {
                                formSearch.submit();
                            }}
                        >
                            <FormattedMessage id="b.search" />
                        </Button>

                        <Button
                            onClick={() => {
                                formSearch.resetFields();
                            }}
                        >
                            <FormattedMessage id="b.reset" />
                        </Button>

                        <Permission permission="Users_Identity-Verification-History_Export">
                            <Button
                                disabled={countdown !== 0}
                                onClick={async () => {
                                    const values = formSearch.getFieldsValue();
                                    if (values.identity_at && values.identity_at.length > 0) {
                                        values.identity_start = values.identity_at[0].startOf('d').format();
                                        values.identity_end = values.identity_at[1].endOf('d').format();
                                        delete values.identity_at;
                                    }

                                    if (values.liveness_at && values.liveness_at.length > 0) {
                                        values.liveness_start = values.liveness_at[0].startOf('d').format();
                                        values.liveness_end = values.liveness_at[1].endOf('d').format();
                                        delete values.liveness_at;
                                    }

                                    if (values.liveness2_at && values.liveness2_at.length > 0) {
                                        values.liveness2_start = values.liveness2_at[0].startOf('d').format();
                                        values.liveness2_end = values.liveness2_at[1].endOf('d').format();
                                        delete values.liveness2_at;
                                    }
                                    const { data } = await userIdentityVerificationHistoryExport({
                                        ...omitBy(values, isEmpty),
                                    });
                                    if (data.code !== 0) {
                                        return message.error(data.msg);
                                    }
                                    message.success(formatMessage({ id: 'm.exportTips' }));

                                    setTarget(Date.now() + 1000 * 10);
                                }}
                            >
                                <FormattedMessage id="b.export" />
                                {countdown === 0 ? null : `(${Math.round(countdown / 1000)}s)`}
                            </Button>
                        </Permission>
                    </Flex>
                </Form>
            </ProCard>

            <ProTable {...proTableProps} />
        </>
    );
};

export default PageIdentityVerificationHistory;
