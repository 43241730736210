export default {
    p: {
        uivh: {
            columns: {
                user: 'User',
                livenessAt: 'Liveness At',
                identityStatus: 'Identity Status',
                identityAt: 'Identity At',
                liveness2Status: '2nd Liveness Status',
                liveness2At: '2nd Liveness At',
            },
            status: {
                verified: 'Verified',
                unverified: 'Unverified',
            },
        },
    },
};
