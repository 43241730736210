import axios from './index';

// import localAxios from 'axios';

// const Laxios = localAxios.create({
//     baseURL: 'http://127.0.0.1:4523/m1/4939924-0-default',
// });

/**
 * APIs 大使计划-配置管理-运营人员管理
 */

// 运营人员分组列表
export const gAmbassadorsGroups = () => {
    return axios<ResType<AmbassadorsGroupsResult[]>>('/v1/ambassadors/groups', {
        method: 'get',
    });
};

// 添加 运营人员分组
export const postAmbassadorsGroups = (data: AmbassadorsGroupsCreateParams) => {
    return axios<ResType<AmbassadorsGroupsResult[]>>('/v1/ambassadors/groups', {
        method: 'post',
        data,
    });
};

// 编辑 运营人员分组
export const putAmbassadorsGroups = (group_id: IdType, data: AmbassadorsGroupsCreateParams) => {
    return axios<ResType<AmbassadorsGroupsResult[]>>(`/v1/ambassadors/groups/${group_id}/edit`, {
        method: 'put',
        data,
    });
};

// 排序 运营人员分组
export const sAmbassadorsGroups = (data: { ids: IdType[] }) => {
    return axios<PageResType<null>>('/v1/ambassadors/groups/sort', {
        method: 'put',
        data,
    });
};

// 获取申请私海池配置列表
export const gAmbassadorsGroupMembers = (group_id: IdType) => {
    return axios<ResType<AmbassadorsGroupMembersResult[]>>(`/v2/ambassadors/mkt/members`, {
        method: 'get',
        params: { group_id },
    });
};

// 添加 运营人员分组成员
export const postAmbassadorsGroupMembers = (group_id: IdType, data: AmbassadorsGroupMembersCreateParams) => {
    return axios<ResType<AmbassadorsGroupsResult[]>>(`/v2/ambassadors/mkt/members`, {
        method: 'post',
        data: {
            ...data,
            group_id,
        },
    });
};

// 编辑 运营人员分组成员
export const putAmbassadorsGroupMembers = (group_id: IdType, id: IdType, data: AmbassadorsGroupMembersCreateParams) => {
    return axios<ResType<AmbassadorsGroupsResult[]>>(`/v2/ambassadors/mkt/members/${id}`, {
        method: 'put',
        data: {
            ...data,
            group_id,
        },
    });
};

// 排序 运营人员分组成员
export const sAmbassadorsGroupMembers = (group_id: IdType, data: { ids: IdType[] }) => {
    return axios<PageResType<null>>(`/v2/ambassadors/mkt/members/sort`, {
        method: 'put',
        params: { group_id },
        data,
    });
};

// 删除 运营人员分组成员
export const dAmbassadorsGroupMembers = (group_id: IdType, id: IdType) => {
    return axios<PageResType<null>>(`/v2/ambassadors/mkt/members/${id}`, {
        method: 'delete',
        params: { group_id },
    });
};

// 转组 运营人员分组成员
export const moveAmbassadorsGroupMembers = (id: IdType, data: { group_id: IdType }) => {
    return axios<PageResType<null>>(`/v2/ambassadors/mkt/members/${id}/transfer`, {
        method: 'put',
        data,
    });
};

/**
 * Types
 */
import type { PageResType, ResType } from '@repo/typings/common';
export type IdType = string | number;
export type TypeValue = 1 | 2;

/**
 * Group Status
 */
export type GroupStatus = 'closed' | 'opened';

/**
 * Group Result
 */
export type AmbassadorsGroupsResult = {
    /**
     * 创建时间
     */
    created_at: string;
    /**
     * 组名
     */
    name: string;
    /**
     * 分组ID
     */
    id: string;
    /**
     * 状态
     */
    status: GroupStatus;
    /**
     * 更新时间
     */
    updated_at: string;
};

/**
 * 新增分组参数
 */

export type AmbassadorsGroupsCreateParams = {
    name: string;
    status: GroupStatus;
};

export type FeAmbassadorsGroupsCreateParams = {
    name: string;
    status: boolean;
};

// ***
export type AmbassadorsGroupMembersResult = {
    account: {
        id: string;
        name: string;
    };
    created_at: string;
    deleted_at: string;
    id: string;
    operator_account: {
        id: string;
        name: string;
    };
    updated_at: string;
    user_id: string;
    user_info: {
        avatar: string;
        id: string;
        name: string;
        nickname: string;
    };
};

// 新增分组成员

export type AmbassadorsGroupMembersCreateParams = {
    account_id: string;
    user_id: string;
};
