export default {
    su: {
        c: {
            debate: '辩论',
            follow: '关注',
            like: '点赞',
            quote: '引用',
            share: '转发',
            vote: '投票',
        },
        t: {
            c: {
                airdropPeriodRange: '空投有效期（day）',
                airdropCountRange: '空投人数范围',
                domain: '域名',
                action: '行为',
                targetReward: '档位/权益值',
                accountLevel: '账号等级',
                binifit: '权益值',
                rateSale: '手续费折扣',
                airdropCount: '空投人数',
                airdropExprise: '空投有效期（day）',
                airdropReson: '空投条件',
                groupCount: '群人数',
            },
        },
        f: {
            pleaseInputUID: '请输入UID',
            uidFormatError: '请输入正确的uid',
            AccountExist: '账户已存在',
            airdropCountRangeError: '请输入1-1000000之间的数字',
            airdropPeriodRangeError: '请输入1-7之间的数字',
            AccountNotExist: '账户不存在',
            MaxAirdropLtMin: '最大空头人数要大于最小空投人数',
            uidRepeat: '用户UID已存在',
            domainFormateError: '域名格式错误',
            domainExit: '域名已存在',
            actionLabel: '档位{value}',
            targetReward: '目标奖励权益值{value}',
        },
    },
};
