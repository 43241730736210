import { useBoolean, useRequest } from 'ahooks';
import { get, omit } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { App, Button, Drawer, Form, Input, Select, Space, Switch } from 'antd';

/**
 * Utils
 */
import { apiErrorMsg } from '@/utils/apiErrorMsg';

/**
 * APIs
 */
import { addChannelCode, channelCodeType, editChannelCode } from '@/services/channel-code';

// form submit
const formSubmit = async (propsData: ChannelCodeResult | null, formData: ChannelCodeBody) => {
    formData.status = formData.fe_status;
    formData.fe_status = undefined;
    // 区分是更新还是新增
    if (propsData && propsData.id) {
        // formData.updated_at = propsData.updated_at;
        formData.type = undefined;
        return await editChannelCode(propsData.id, { ...omit(formData) });
    }
    return await addChannelCode({ ...omit(formData) });
};

/**
 * Constants
 */
export const channelTypeLangMap: Record<string, string> = {
    DEFAULT: 'ccc.s.DEFAULT',
    AMBASSADOR: 'ccc.s.AMBASSADOR',
    KOL: 'ccc.s.KOL',
    PROJECT: 'ccc.s.PROJECT',
    CHANNEL: 'ccc.s.CHANNEL',
};

/**
 * Types
 */
import type { DrawerProps, FormProps } from 'antd';
import type { IntlShape } from 'react-intl';
import type { ChannelCodeBody, ChannelCodeResult } from '@/services/channel-code';
import { ReactElement, useState } from 'react';

export type ChannelTypeOptions = {
    label: ReactElement | string;
    value: string;
}[];

type Props = {
    open: boolean;
    entity: ChannelCodeResult | null;
    closeModelForm: (reload?: boolean) => void;
};

const ComponentModalFormCustom: React.FC<Props> = ({ open, entity, closeModelForm }) => {
    /**
     * Hooks
     */
    const { message } = App.useApp();
    const intl: IntlShape = useIntl();

    /**
     * State
     */
    const [submitting, { setFalse: setSubmittingFlase, setTrue: setSubmittingTrue }] = useBoolean(false);
    const [form] = Form.useForm();
    const [typeOptions, setTypeOptions] = useState<ChannelTypeOptions>([]);

    /**
     * DrawerProps
     */
    const drawerProps: DrawerProps = {
        footer: (
            <div style={{ textAlign: 'right' }}>
                <Space>
                    <Button type="default" onClick={() => closeModelForm()}>
                        {intl.formatMessage({ id: 'b.cancel' })}
                    </Button>
                    <Button
                        type="primary"
                        loading={submitting}
                        onClick={() => {
                            form.validateFields()
                                .then(async (formData) => {
                                    setSubmittingTrue();
                                    try {
                                        const res = await formSubmit(entity, formData);
                                        if (get(res, 'data.code') === 0) {
                                            message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
                                            closeModelForm(true);
                                        } else {
                                            apiErrorMsg(message, intl, {}, res.data);
                                            setSubmittingFlase();
                                        }
                                    } catch {
                                        setSubmittingFlase();
                                    }
                                })
                                .catch(() => {});
                        }}
                    >
                        {intl.formatMessage({ id: 'b.confirm' })}
                    </Button>
                </Space>
            </div>
        ),
        destroyOnClose: true,
        maskClosable: false,
        open: open,
        title: <FormattedMessage id={entity ? 'b.edit' : 'b.add'} />,
        afterOpenChange: (open) => {
            if (!open) {
                setSubmittingFlase();
                form.resetFields();
            } else if (entity) {
                form.setFieldsValue({
                    comment: entity.comment,
                    type: entity.type,
                    name: entity.name,
                    fe_status: entity.status,
                });
            }
        },
        onClose: () => {
            closeModelForm();
        },
    };

    /**
     * ProformProps
     */
    const formProps: FormProps<ChannelCodeBody> = {
        form: form,
        layout: 'vertical',
        validateTrigger: 'onBlur',
    };

    useRequest(channelCodeType, {
        onSuccess: ({ data }) => {
            setTypeOptions(
                data.data.map((v) => {
                    return {
                        label: channelTypeLangMap[v] ? <FormattedMessage id={channelTypeLangMap[v]} /> : v,
                        value: v,
                    };
                }),
            );
        },
    });

    return (
        <Drawer {...drawerProps}>
            <Form {...formProps}>
                <Form.Item name="type" label={intl.formatMessage({ id: 't.c.type' })} rules={[{ required: true }]}>
                    <Select
                        disabled={!!entity}
                        allowClear={true}
                        optionFilterProp="label"
                        options={typeOptions}
                        showSearch={true}
                        style={{ width: '100%' }}
                    />
                </Form.Item>

                <Form.Item name="name" label={intl.formatMessage({ id: 'cad.t.c.name' })} rules={[{ required: true }]}>
                    <Input showCount maxLength={100} />
                </Form.Item>

                <Form.Item name="comment" label={intl.formatMessage({ id: 'b.remark' })} rules={[{ required: false }]}>
                    <Input showCount maxLength={100} />
                </Form.Item>

                <Form.Item
                    name="fe_status"
                    initialValue={true}
                    label={intl.formatMessage({ id: 't.c.status' })}
                    rules={[{ required: true }]}
                >
                    <Switch />
                </Form.Item>
            </Form>
        </Drawer>
    );
};

export default ComponentModalFormCustom;
