import { HolderOutlined } from '@ant-design/icons';
import { ActionType, DragSortTable, DragTableProps, ProCard } from '@ant-design/pro-components';
import { useRequest, useSetState } from 'ahooks';
import { App, Button, Col, Row, Tag } from 'antd';
import { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

/**
 * Components
 */
import Permission from '@/components/permission';
import ComponentMembersTable from './components/members-table';
import ComponentGroupDrawer from './components/group-drawer';
import { gAmbassadorsGroups, sAmbassadorsGroups } from '@/services/ambassadors-configurations';

/**
 * Constants
 */

/**
 * Types
 */
import type { AmbassadorsGroupsResult } from '@/services/ambassadors-configurations';

type State = {
    dataSource: AmbassadorsGroupsResult[];
    sorting: boolean;
    openDrawer: boolean;
    entity: any;
    selectdEntity: any;
};

const PageAmbassadorsConfigurationsMktMemberConfig: React.FC = () => {
    /**
     * Hooks
     */
    // const intl = useIntl();

    const { message } = App.useApp();

    const searchRef = useRef<HTMLDivElement>(null);

    const tableRef = useRef<ActionType>();
    /**
     * States
     */
    const [state, setState] = useSetState<State>({
        dataSource: [],
        sorting: false,
        openDrawer: false,
        entity: null,
        selectdEntity: null,
    });

    /**
     * Requests
     */
    const {
        loading: loadingAmbassadorsGroups,
        refresh: refreshAmbassadorsGroups,
        run: runAmbassadorsGroups,
    } = useRequest(gAmbassadorsGroups, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }
            const dataSource = data || [];
            // 当选中时，编辑选中的数据，同步更新选中的数据
            let selectdEntity = state.selectdEntity;
            if (selectdEntity) {
                selectdEntity = dataSource.find((item) => item.id === state.selectdEntity.id);
            }

            setState({
                dataSource,
                sorting: false,
                selectdEntity,
            });
        },
    });

    const { run: runSortAmbassadorsGroups, loading: loadingSortAmbassadorsGroups } = useRequest(sAmbassadorsGroups, {
        manual: true,
        onSuccess: ({ data: { code, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }
            refreshAmbassadorsGroups();
        },
    });

    /**
     * ChildrenProps
     */
    const dragSortTableProps: DragTableProps<AmbassadorsGroupsResult, any> = {
        actionRef: tableRef,
        bordered: true,
        columns: [
            {
                title: <FormattedMessage id="tc.t.c.sort" />,
                dataIndex: 'sort',
                width: 50,
                className: 'drag-visible',
                render: () => {
                    if (state.sorting) {
                        return null;
                    }
                    return (
                        <Button
                            type="text"
                            size="small"
                            icon={<HolderOutlined />}
                            style={{ cursor: 'not-allowed', opacity: 0.5 }}
                        />
                    );
                },
            },
            {
                dataIndex: 'index',
                title: 'No.',
                width: 50,
                render: (_, __, index) => index + 1,
            },
            {
                dataIndex: 'name',
                ellipsis: true,
                title: 'Group',
            },
            {
                dataIndex: 'status',
                title: <FormattedMessage id="t.c.status" />,
                render: (status) => (
                    <Tag color={status === 'opened' ? 'green' : 'orange'}>
                        {status === 'opened' ? 'Enabled' : 'Disabled'}
                    </Tag>
                ),
                width: 80,
            },
            {
                dataIndex: '_',
                title: <FormattedMessage id="t.c.action" />,
                valueType: 'option',
                render: (_, entity) => [
                    <Permission permission="Ambassadors_Configurations_Mkt-Member-Config_Group_Edit" key="edit">
                        <a
                            onClick={() => {
                                setState({
                                    entity,
                                    openDrawer: true,
                                });
                            }}
                        >
                            <FormattedMessage id="b.edit" />
                        </a>
                    </Permission>,
                ],
                width: 100,
            },
        ],
        dataSource: state.dataSource,
        dragSortKey: state.sorting ? 'sort' : '_not_sortable',
        dragSortHandlerRender: () => (
            <Button type="text" size="small" icon={<HolderOutlined />} style={{ cursor: 'move' }} />
        ),
        loading: loadingAmbassadorsGroups,
        options: {
            reload: refreshAmbassadorsGroups,
        },
        pagination: false,
        rowKey: 'id',
        scroll: {
            y: `calc(100vh - 240px - ${searchRef.current?.clientHeight}px - 8px)`,
        },
        search: false,
        headerTitle: 'Group List',
        toolBarRender: () => {
            // 排序中的按钮
            if (state.sorting) {
                return [
                    <Button
                        type="primary"
                        loading={loadingSortAmbassadorsGroups}
                        onClick={() => {
                            runSortAmbassadorsGroups({ ids: state.dataSource.map((item) => item.id) });
                        }}
                        key="sureSort"
                    >
                        <FormattedMessage id="tc.b.sureSort" />
                    </Button>,
                    <Button
                        loading={loadingSortAmbassadorsGroups}
                        onClick={() => {
                            setState({ sorting: false });
                            refreshAmbassadorsGroups();
                        }}
                        key="cancelSort"
                    >
                        <FormattedMessage id="tc.b.cancelSort" />
                    </Button>,
                ];
            }

            // 默认按钮组
            return [
                <Permission permission="Ambassadors_Configurations_Mkt-Member-Config_Group_Add" key="add">
                    <Button
                        type="primary"
                        onClick={() => {
                            setState({ openDrawer: true, entity: null });
                        }}
                    >
                        <FormattedMessage id="b.add" />
                    </Button>
                </Permission>,
                <Permission permission="Ambassadors_Configurations_Mkt-Member-Config_Group_Sort" key="sort">
                    <Button
                        type="primary"
                        ghost
                        onClick={() => {
                            setState({ sorting: true });
                        }}
                    >
                        <FormattedMessage id="tc.t.c.sort" />
                    </Button>
                </Permission>,
            ];
        },
        onDragSortEnd: (_beforeIndex: number, _afterIndex: number, newDataSource: any) => {
            setState({
                dataSource: newDataSource,
            });
        },
        onRow: (data) => {
            return {
                onClick: (e) => {
                    const clickTagName = (e.target as HTMLElement).tagName;
                    if (
                        clickTagName === 'A' ||
                        clickTagName === 'BUTTON' ||
                        (e.target as HTMLElement).parentElement?.tagName === 'BUTTON'
                    ) {
                        return true;
                    }
                    // if (e.target)
                    setState({ selectdEntity: data });
                },
            };
        },
        rowClassName: (record) => (record.id === state.selectdEntity?.id ? 'rowClassName' : ''),
    };

    /**
     * Effects
     */
    useEffect(() => {
        runAmbassadorsGroups();
    }, []);

    return (
        <>
            <Row gutter={16} wrap={false}>
                <Col flex="480px">
                    <DragSortTable {...dragSortTableProps} tableStyle={{ minHeight: 'calc(100vh - 264px)' }} />
                </Col>

                <Col flex="auto">
                    <ProCard style={{ padding: 0, minHeight: 'calc(100vh - 104px)' }} bodyStyle={{ padding: 0 }}>
                        <ComponentMembersTable groupEntity={state.selectdEntity} />
                    </ProCard>
                </Col>
            </Row>
            <ComponentGroupDrawer
                open={state.openDrawer}
                entity={state.entity}
                closeModelForm={(reload) => {
                    setState({ openDrawer: false, entity: null });
                    reload && refreshAmbassadorsGroups();
                }}
            />
        </>
    );
};

export default PageAmbassadorsConfigurationsMktMemberConfig;
