import axios from './index';
// import localAxios from 'axios';

// const axios = localAxios.create({
//     baseURL: 'http://127.0.0.1:4523/m1/4939924-0-default',
// });

/**
 * APIs
 */
// 获取站内通知任务
export const gIMMessage = (params: IMMessageParams) => {
    return axios<PageResType<IMMessageResult>>('/v1/im/notification/tasks', {
        method: 'get',
        params,
    });
};

// 创建站内通知任务

export const cIMMessage = (data: CreateIMMessageData) => {
    return axios<PageResType<IMMessageResult>>('/v1/im/notification/tasks', {
        method: 'post',
        data,
    });
};

/**
 * status
 */
export enum Status {
    Cancelled = 'cancelled',
    Completed = 'completed',
    InProgress = 'in_progress',
    Pending = 'pending',
}

/**
 * Types
 */
import type { PageResType } from '@repo/typings/common';

/**
 * 站内通知任务
 */
export type IMMessageResult = {
    /**
     * 创建时间
     */
    created_at: Date;
    /**
     * 删除时间
     */
    deleted_at?: Date | null;
    /**
     * ID
     */
    id: string;
    /**
     * 语言包
     */
    languages: Language[];
    operator_account: OperatorAccount;
    /**
     * status
     */
    status: Status;
    /**
     * 目标用户数
     */
    target_user_count: number;
    /**
     * 更新时间
     */
    updated_at: Date;
    /**
     * 覆盖用户数
     */
    user_coverage?: number;
};

export type Language = {
    /**
     * 语言
     */
    language: string;
    /**
     * 通知内容
     */
    value: string;
};

/**
 * OperatorAccount
 */
export type OperatorAccount = {
    /**
     * 创建时间
     */
    created_at: Date;
    /**
     * 删除时间
     */
    deleted_at?: Date | null;
    /**
     * ID
     */
    id: string;
    /**
     * 账号名
     */
    name: string;
    /**
     * 更新时间
     */
    updated_at: Date;
};

export type IMMessageParams = {
    /**
     * 英文搜索
     */
    en_message?: string;
    /**
     * 每页条数
     */
    limit?: string;
    /**
     * 操作人
     */
    operator_account_id?: string;
    order_by?: string;
    order_sort?: string;
    /**
     * 当前页数
     */
    page?: string;
    /**
     * 状态
     */
    status?: string;
    /**
     * 操作结束时间
     */
    updated_end?: string;
    /**
     * 操作开始时间
     */
    updated_start?: string;

    updated_date?: any[];
};

export type CreateIMMessageData = {
    languages: Language[];
};
