import { useEffect, useRef, useState } from 'react';
import { useRequest, useSetState } from 'ahooks';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    App,
    Button,
    DatePicker,
    Flex,
    Form,
    Input,
    Popconfirm,
    Select,
    Space,
    Spin,
    Typography,
    Image,
    Modal,
} from 'antd';
import { ProCard, ProTable } from '@ant-design/pro-components';
import dayjs from 'dayjs';
import { get, isEmpty, omitBy, trim } from 'lodash';

/**
 * Components
 */
import Permission from '@/components/permission';
import ComponentSearchFormSelectOperator from '@/components/search-form-select-operator';

/**
 * APIs
 */
import { batchDeleteShortReplies, dShortReplies, gShortsReplies } from '@/services/shorts';

/**
 * Types
 */
import type { FormProps, PaginationProps } from 'antd';
import type { ActionType, ProTableProps } from '@ant-design/pro-components';
import type { FeShortsRepliesParams, ShortsRepliesResult } from '@/services/shorts';

// table 批量选择
type SeletRowState = {
    selects: string[];
    selectedRows: any[];
};

/**
 * Constants
 */
const getDefaultTodayDateTime = () => {
    const today = dayjs();
    return [today.startOf('d'), today.endOf('d')];
};

/**
 * State
 */
type State = {
    dataSource: ShortsRepliesResult[];
};

const PageContentShortsReplies: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    const [formSearch] = Form.useForm();

    const { formatMessage } = useIntl();

    const searchRef = useRef<HTMLDivElement>(null);

    const tableRef = useRef<ActionType>();

    const intl = useIntl();

    /**
     * States
     */
    const [state, setState] = useSetState<State>({
        dataSource: [],
    });

    const [tableCellLoading, setTableCellLoading] = useSetState<Record<string, boolean>>({});

    const [selectState, setSelectState] = useSetState<SeletRowState>({
        selects: [],
        selectedRows: [],
    });

    /**
     * Paginations
     */
    const [pagination, setPagination] = useSetState<PaginationProps>({
        current: 1,
        pageSize: 20,
        onChange: (page, pageSize) => {
            setPagination({
                current: page,
                pageSize,
            });
        },
    });

    /**
     * Payloads
     */
    const [payload, setPayload] = useState<FeShortsRepliesParams>({
        create_start: getDefaultTodayDateTime()[0].format(),
        create_end: getDefaultTodayDateTime()[1].format(),
    });

    /**
     * Request
     */
    const { run: runBatchDelete, loading: loadingBatchDelete } = useRequest(batchDeleteShortReplies, {
        manual: true,
        onSuccess: ({ data: { code, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            refreshShortsReplies();
        },
    });

    const {
        loading: loadingShortsReplies,
        refresh: refreshShortsReplies,
        run: runShortsReplies,
    } = useRequest(gShortsReplies, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setState({
                dataSource: data.list,
            });

            setSelectState({
                selects: [],
                selectedRows: [],
            });

            setPagination({
                total: data.meta.total,
            });
        },
    });
    /**
     * ChildrenProps
     */

    const formSearchProps: FormProps<FeShortsRepliesParams> = {
        form: formSearch,
        layout: 'inline',
        onFinish: (values) => {
            if (values.create_date) {
                values.create_start = values.create_date[0].format();
                values.create_end = values.create_date[1].format();
                values.create_date = undefined;
            }

            if (values.operation_date) {
                values.updated_start = values.operation_date[0].startOf('d').format();
                values.updated_end = values.operation_date[1].endOf('d').format();
                values.operation_date = undefined;
            }

            if (values._types) {
                values.types = [values._types];
                values._types = undefined;
            }

            if (values.fe_is_deleted) {
                values.is_deleted = values.fe_is_deleted === 'normal' ? 'false' : 'true';
            } else {
                values.is_deleted = undefined;
            }

            if (values.description) {
                values.description = trim(values.description);
            }

            values.fe_is_deleted = undefined;

            setPagination({
                current: 1,
            });
            setPayload({
                ...omitBy(values, isEmpty),
            });
        },
    };

    const proTableProps: ProTableProps<ShortsRepliesResult, any> = {
        actionRef: tableRef,
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                fixed: 'left',
                title: 'No.',
                width: 50,
                render: (_, __, index) => index + 1,
            },
            {
                dataIndex: 'comment_id',
                ellipsis: true,
                title: <FormattedMessage id="rm.t.c.commentId" />,
                width: 200,
            },
            {
                dataIndex: 'user_id',
                ellipsis: true,
                title: <FormattedMessage id="rm.t.c.postAccUid" />,
                width: 200,
            },
            {
                dataIndex: 'video_id',
                ellipsis: true,
                title: <FormattedMessage id="cp.t.c.shortsId" />,
                width: 200,
            },

            {
                dataIndex: 'body',
                ellipsis: true,
                title: <FormattedMessage id="rm.t.c.content" />,
            },
            {
                dataIndex: '_shorts',
                title: <FormattedMessage id="cp.f.image" />,
                render: (_, entity) => {
                    const picList = get(entity, 'materials', []);
                    if (!picList || !picList.length) {
                        return '-';
                    }
                    return (
                        <Space wrap={true}>
                            {picList.map((v, index) => {
                                return <Image src={v} width={80} key={index} />;
                            })}
                        </Space>
                    );
                },
                width: 120,
            },
            {
                dataIndex: 'deleted_at',
                title: <FormattedMessage id="t.c.status" />,
                render: (_text, entity) =>
                    entity.deleted_at
                        ? intl.formatMessage({ id: 'cp.f.deleted' })
                        : intl.formatMessage({ id: 'rm.b.normal' }),
                width: 120,
            },
            {
                dataIndex: 'created_at',
                title: <FormattedMessage id="cp.t.c.publishAt" />,
                valueType: 'dateTime',
                width: 200,
            },
            {
                dataIndex: ['operator_account', 'name'],
                title: <FormattedMessage id="t.c.operatedBy" />,
                width: 200,
            },
            {
                dataIndex: 'admin_updated_at',
                title: <FormattedMessage id="t.c.operatedAt" />,
                valueType: 'dateTime',
                width: 200,
            },
            {
                dataIndex: '_menu',
                valueType: 'option',
                width: 200,
                fixed: 'right',
                title: <FormattedMessage id="common.operation" />,
                hideInSearch: true,
                render: (_, entity) => [
                    !entity.deleted_at && (
                        <Permission permission="Content_Shorts-Comments_Delete" key="delete">
                            <Spin spinning={!!tableCellLoading[`${entity.comment_id}${entity.video_id}`]} size="small">
                                <Popconfirm
                                    title={intl.formatMessage({ id: 'c.areyousure' })}
                                    onConfirm={() => {
                                        setTableCellLoading({
                                            [`${entity.comment_id}${entity.video_id}`]: true,
                                        });
                                        dShortReplies({
                                            user_id: entity.user_id,
                                            comment_id: entity.comment_id,
                                            video_id: entity.video_id,
                                        })
                                            .then(({ data: { code, msg } }) => {
                                                if (code === 0) {
                                                    message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
                                                    refreshShortsReplies();
                                                } else {
                                                    message.error(msg || 'Error');
                                                }
                                                setTableCellLoading({
                                                    [`${entity.comment_id}${entity.video_id}`]: false,
                                                });
                                            })
                                            .catch((e) => {
                                                message.error(get(e, 'response.data.msg', 'Error'));
                                                setTableCellLoading({
                                                    [`${entity.comment_id}${entity.video_id}`]: false,
                                                });
                                            });
                                    }}
                                >
                                    <Typography.Link type="danger">
                                        <FormattedMessage id="b.delete" />
                                    </Typography.Link>
                                </Popconfirm>
                            </Spin>
                        </Permission>
                    ),
                ],
            },
        ],
        dataSource: state.dataSource,
        loading: loadingShortsReplies,
        options: {
            reload: refreshShortsReplies,
        },
        pagination,
        rowKey: (entity) => `${entity.comment_id}${entity.video_id}`,
        rowSelection: {
            columnWidth: 50,
            selectedRowKeys: selectState.selects,
            getCheckboxProps: (record) => {
                return {
                    disabled: loadingBatchDelete || !!record.deleted_at,
                };
            },
            onChange: (selectedRowKeys, selectedRows) => {
                setSelectState({
                    selects: selectedRowKeys as string[],
                    selectedRows,
                });
            },
        },
        scroll: {
            x: 1890,
            y: `calc(100vh - 280px - ${searchRef.current?.clientHeight}px - 8px)`,
        },
        tableAlertRender: false,
        toolBarRender: () => {
            if (!selectState.selects.length || loadingShortsReplies) {
                return [];
            }
            return [
                <Permission permission="Content_Shorts-Comments_Delete" key="bulkDelete">
                    <Button
                        type="primary"
                        loading={loadingBatchDelete}
                        onClick={() => {
                            Modal.confirm({
                                title: intl.formatMessage({ id: 'c.areyousureBulk' }),
                                onOk: async () => {
                                    const comments = selectState.selectedRows.map((v) => {
                                        return {
                                            user_id: v.user_id,
                                            comment_id: v.comment_id,
                                            video_id: v.video_id,
                                        };
                                    });
                                    await runBatchDelete({ comments });
                                },
                            });
                        }}
                    >
                        <FormattedMessage id="b.bulkDelete" />
                    </Button>
                </Permission>,
            ];
        },
        search: false,
    };

    /**
     * Effects
     */
    useEffect(() => {
        runShortsReplies({
            ...payload,
            page: pagination.current as any,
            limit: pagination.pageSize as any,
        });
    }, [pagination.current, pagination.pageSize, payload]);

    return (
        <>
            <ProCard className="mb-16" ref={searchRef}>
                <Form {...formSearchProps}>
                    <Flex gap={16} wrap={true}>
                        <Form.Item name="comment_id" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'rm.t.c.commentId' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="user_id" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'rm.t.c.postAccUid' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="video_id" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'cp.t.c.shortsId' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="body" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'rm.t.c.commentContent' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="_types" style={{ marginRight: 0 }}>
                            <Select
                                // mode="multiple"
                                allowClear={true}
                                showSearch={true}
                                optionFilterProp="label"
                                options={[
                                    {
                                        label: intl.formatMessage({ id: 'cp.f.image' }),
                                        value: 'image',
                                    },
                                    // {
                                    //     label: intl.formatMessage({ id: 'cp.f.video' }),
                                    //     value: 'video',
                                    // },
                                    // {
                                    //     label: intl.formatMessage({ id: 'cp.f.audio' }),
                                    //     value: 'audio',
                                    // },
                                    // {
                                    //     label: intl.formatMessage({ id: 'cp.f.nft' }),
                                    //     value: 'nft',
                                    // },
                                ]}
                                placeholder={<FormattedMessage id="cp.f.type" />}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="fe_is_deleted" style={{ marginRight: 0 }}>
                            <Select
                                allowClear={true}
                                showSearch={true}
                                optionFilterProp="label"
                                options={[
                                    {
                                        label: intl.formatMessage({ id: 'rm.b.normal' }),
                                        value: 'normal',
                                    },
                                    {
                                        label: intl.formatMessage({ id: 'cp.f.deleted' }),
                                        value: 'deleted',
                                    },
                                ]}
                                placeholder={<FormattedMessage id="t.c.status" />}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item
                            label={formatMessage({ id: 'cp.t.c.publishAt' })}
                            name="create_date"
                            style={{ marginRight: 0 }}
                            initialValue={getDefaultTodayDateTime()}
                        >
                            <DatePicker.RangePicker
                                onChange={(rangeDate) => {
                                    if (!rangeDate || !rangeDate[0] || !rangeDate[1]) {
                                        return;
                                    }
                                    const newStart = dayjs(rangeDate[0].format('YYYY-MM-DD HH:00:00'));
                                    const newDnd = dayjs(rangeDate[1].format('YYYY-MM-DD HH:59:59'));
                                    formSearch.setFieldValue('create_date', [newStart, newDnd]);
                                }}
                                showTime={{ format: 'HH' }}
                                allowClear={true}
                            />
                        </Form.Item>

                        <Form.Item name="operator_account_id" style={{ marginRight: 0 }}>
                            <ComponentSearchFormSelectOperator model="Content_Shorts-Comments" />
                        </Form.Item>

                        <Form.Item
                            label={formatMessage({ id: 't.c.operatedAt' })}
                            name="operation_date"
                            style={{ marginRight: 0 }}
                        >
                            <DatePicker.RangePicker allowClear={true} />
                        </Form.Item>

                        <Button
                            loading={loadingShortsReplies}
                            type="primary"
                            onClick={() => {
                                formSearch.submit();
                            }}
                        >
                            <FormattedMessage id="b.search" />
                        </Button>

                        <Button
                            onClick={() => {
                                formSearch.resetFields();
                            }}
                        >
                            <FormattedMessage id="b.reset" />
                        </Button>
                    </Flex>
                </Form>
            </ProCard>

            <ProTable {...proTableProps} />
        </>
    );
};

export default PageContentShortsReplies;
