/**
 * Permission
 */
export default {
    pm: {
        Add: '添加',
        Edit: '编辑',
        Delete: '删除',
        Export: '导出',
        Users: '用户管理',
        Users_Users: '用户管理',
        Users_Usernames: '用户名管理',
        Users_Usernames_Add: '添加',
        Users_Usernames_Import: '导入',
        Users_Usernames_Delete: '删除',
        'Users_Usernames_Bulk-Delete': '批量删除',
        Users_Users_Details: '详情',
        Users_Users_Details_Mute: '禁言',
        Users_Users_Details_Disable: '封号',
        Users_Users_Details_Unmute: '解除禁言',
        Users_Users_Details_Enable: '解除封号',
        'Users_Users_Details_Manage-Profile': '管理资料',
        Users_Users_Export: '导出',
        'Users_Sanction-History': '用户处理记录',
        'Users_Identity-Verification-History': '真人认证记录',
        'Users_Identity-Verification-History_Export': '导出',
        Content: '内容管理',
        Content_Posts: '帖子管理',
        Content_Posts_Delete: '删除',
        'Content_Posts-Comments': '帖子评论',
        'Content_Posts-Comments_Delete': '删除',
        Content_Shorts: '短视频管理',
        Content_Shorts_Delete: '删除',
        'Content_Shorts-Comments': '短视频评论',
        'Content_Shorts-Comments_Delete': '删除',
        Content_Live: '辩论直播管理',
        Content_Live_End: '关播',
        Content_Live_Delete: '删除',
        Ambassadors: '大使计划',
        Ambassadors_Applications: '大使申请',
        'Ambassadors_Applications_Public-Pool': '申请公海池',
        'Ambassadors_Applications_Public-Pool_Assign': '分配',
        'Ambassadors_Applications_Private-Pool': '申请私海池',
        'Ambassadors_Applications_Private-Pool_All-Data': '所有数据',
        'Ambassadors_Applications_Private-Pool_Personal-Data': '个人数据',
        'Ambassadors_Applications_Review-Records': '审核记录',
        Ambassadors_Ambassadors: '大使管理',
        'Ambassadors_Ambassadors_Public-Pool': '大使公海池',
        'Ambassadors_Ambassadors_Public-Pool_Assign': '分配',
        Ambassadors_Ambassadors_Ambassadors: '大使',
        'Ambassadors_Ambassadors_Ambassadors_All-Data': '所有数据',
        'Ambassadors_Ambassadors_Ambassadors_Personal-Data': '个人数据',
        Ambassadors_Data: '数据管理',
        'Ambassadors_Data_Ambassador-Data': '大使任务数据',
        'Ambassadors_Data_Ambassador-Data_Export': '导出',
        Ambassadors_Configurations: '配置管理',
        'Ambassadors_Configurations_Mkt-Member-Config': '运营人员管理',
        'Ambassadors_Configurations_Mkt-Member-Config_Group': 'Group',
        'Ambassadors_Configurations_Mkt-Member-Config_Group_Add': '添加',
        'Ambassadors_Configurations_Mkt-Member-Config_Group_Edit': '编辑',
        'Ambassadors_Configurations_Mkt-Member-Config_Group_Sort': '排序',
        'Ambassadors_Configurations_Mkt-Member-Config_Member': '人员',
        'Ambassadors_Configurations_Mkt-Member-Config_Member_Add': '添加',
        'Ambassadors_Configurations_Mkt-Member-Config_Member_Edit': '编辑',
        'Ambassadors_Configurations_Mkt-Member-Config_Member_Transfer': '转组',
        'Ambassadors_Configurations_Mkt-Member-Config_Member_Sort': '排序',
        'Ambassadors_Configurations_Mkt-Member-Config_Member_Delete': '删除',
        Incentives: '激励计划',
        'Incentives_Incentive-Seasons': '激励计划活动列表',
        'Incentives_Incentive-Seasons_Add': '创建',
        'Incentives_Incentive-Seasons_Edit': '编辑',
        'Incentives_Incentive-Seasons_Delete': '删除',
        'Incentives_Task-Config_Add': '创建',
        'Incentives_Task-Config_Edit': '编辑',
        'Incentives_Task-Config_Delete': '删除',
        'Incentives_Task-Config_Sort': '排序',
        'Super-Users': '超级账号',
        'Super-Users_Behavior': '行为管理',
        'Super-Users_Behavior_Edit': '编辑',
        'Super-Users_Benefit': '权益管理',
        'Super-Users_Benefit_Add': '添加',
        'Super-Users_Benefit_Edit': '编辑',
        'Super-Users_Benefit_Delete': '删除',
        Creator_Center: '创作者中心',
        'Creator_Center-Topic': '话题管理',
        'Creator_Center-Creator_Hub': '创作学院',
        'Creator_Center-Top_Posts': '推荐帖子',
        'Client-Config': '客户端配置',
        'Client-Config_Plaza-Config': '数字文明广场',
        'Client-Config_Super-Users': '超级账号',
        'Client-Config_Super-Users_Add': '添加',
        'Client-Config_Super-Users_Edit': '编辑',
        'Client-Config_Super-Users_Delete': '删除',
        'Client-Config_Airdrop-Whitelist': '空投白名单',
        'Client-Config_Airdrop-Whitelist_Add': '添加',
        'Client-Config_Airdrop-Whitelist_Edit': '编辑',
        'Client-Config_Airdrop-Whitelist_Delete': '删除',
        'Client-Config_Domain': '域名管理',
        'Client-Config_App-Version': 'App版本管理',
        'Client-Config_Channel-Code': '渠道码管理',
        IM_Messages: '站内通知',
        Marketplace: '交易市场',
        Marketplace_NFT: 'NFT',
        'Marketplace_NFT_NFT-Collection': 'NFT合集管理',
        Marketplace_NFT_NFT: 'NFT管理',
        'Marketplace_NFT_NFT-Marketplace': 'NFT交易市场',
        Marketplace_Token: '代币',
        'Marketplace_Token_Token-Config': '代币配置',
        'Marketplace_Token_Token-Config_Edit': '编辑',
        'Marketplace_Token_Token-Config_Sort': '排序',
        'Marketplace_Token_Top-Token': '热门代币配置',
        'Marketplace_Token_Top-Token_Add': '添加',
        'Marketplace_Token_Top-Token_Sort': '排序',
        'Marketplace_Token_Top-Token_Delete': '删除',
        Marketplace_Meme_Memecoin_Delist: '下架',
        Airdrop: '空投管理',
        'Airdrop_Airdrop-Seasons': '官方空投活动列表',
        'Airdrop_Airdrop-Seasons_Create': '创建',
        'Airdrop_Airdrop-Seasons_Edit': '编辑',
        'Airdrop_Airdrop-Seasons_Delete': '删除',
        'Airdrop_Airdrop-Seasons_Distribute': '发放',
        'Airdrop_Airdrop-Config': '官方空投参数配置',
        'Airdrop_Airdrop-Config_Edit': '编辑',
        Marketplace_Token_Pool: '流动性池',
        Marketplace_Token_Pool_Create: '添加',
        Marketplace_Token_Pool_Edit: '编辑',
        'System-Management': '系统管理',
        'System-Management_Accounts': '账号管理',
        'System-Management_Accounts_Create': '创建',
        'System-Management_Accounts_Edit': '编辑',
        'System-Management_Accounts_Delete': '删除',
        'System-Management_Roles': '角色管理',
        'System-Management_Roles_Create': '创建',
        'System-Management_Roles_Edit': '编辑',
        'System-Management_Roles_Delete': '删除',
        'System-Management_Permissions': '权限管理',
        'System-Management_Logs': '系统日志',
        'System-Management_Exports': '下载管理',
        'System-Management_Exports_Exports': '导出',
        'System-Management_Exports_Exports_All-Data': '所有数据',
        'System-Management_Exports_Exports_Personal-Data': '个人数据',
    },
};
