import { useEffect, useRef, useState } from 'react';
import { ProCard, ProTable } from '@ant-design/pro-components';
import { App, Button, Flex, Form, Input, DatePicker } from 'antd';
import { isEmpty, omitBy } from 'lodash';
import { useCountDown, useRequest, useSetState } from 'ahooks';
import Permission from '@/components/permission';
import { FormattedMessage, useIntl } from 'react-intl';
import { Pagination } from '@repo/ui/pagination';

/**
 * APIs
 */
import { userExport, userListApi } from '@/services/user';

/**
 * Types
 */
import type { FormProps } from 'antd';
import type { User, UserListParams } from '@/services/user';
import type { ProTableProps } from '@ant-design/pro-components';
import type { PaginationProps } from '@repo/ui/pagination';

type State = {
    dataSource: User[];
    selectedRowKeys: React.Key[];
    searchAfter: string | undefined;
    hasMore: boolean;
};

/**
 * Constants
 */
const defaultPayload = {
    username: undefined,
    nickname: undefined,
    created_start: undefined,
    created_end: undefined,
    inviter_id: undefined,
    id: undefined,
};

const PageAccountManagement: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    const [formSearch] = Form.useForm();

    const { formatMessage } = useIntl();

    const searchRef = useRef<HTMLDivElement>(null);

    const [target, setTarget] = useState(0);

    const [countdown] = useCountDown({
        targetDate: target,
    });

    const [state, setState] = useSetState<State>({
        dataSource: [],
        selectedRowKeys: [],
        hasMore: false,
        searchAfter: undefined,
    });

    /**
     * Paginations
     */
    const [pagination, setPagination] = useSetState<PaginationProps>({
        current: 1,
        hasMore: false,
        searchAfter: '',
        size: 20,
        total: 0,
        onChange: ({ page, searchAfter, size }) => {
            setPagination({
                current: page,
                size: size,
                searchAfter,
            });
        },
    });

    /**
     * Payloads
     */
    const [payload, setPayload] = useSetState<UserListParams>({});

    /**
     * Requests
     */
    const {
        loading,
        refresh: refreshUserList,
        run: runUserList,
    } = useRequest(userListApi, {
        manual: true,
        onSuccess: ({ data: { code, data, msg, meta } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setPagination({
                size: meta.size,
                total: meta.total,
                hasMore: meta.has_more,
                searchAfter: meta.search_after || '',
            });

            setState({
                dataSource: data,
            });
        },
    });

    /**
     * ChildrenProps
     */
    const formSearchProps: FormProps = {
        form: formSearch,
        layout: 'inline',
        onFinish: (values) => {
            if (values.date && values.date.length > 0) {
                values.created_start = values.date[0].startOf('d').format();
                values.created_end = values.date[1].endOf('d').format();
                delete values.date;
            }

            setPagination({
                current: 1,
                size: pagination.size,
                searchAfter: '',
            });

            setPayload({
                ...defaultPayload,
                ...omitBy(values, isEmpty),
            });
        },
    };

    const proTableProps: ProTableProps<User, any> = {
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                valueType: 'index',
                title: 'No.',
                width: 50,
                fixed: 'left',
            },
            {
                dataIndex: 'id',
                width: 200,
                title: <FormattedMessage id="p.uu.columns.uid" />,
            },
            {
                dataIndex: 'name',
                width: 200,
                title: <FormattedMessage id="p.uu.columns.userName" />,
                render: (_, record) => {
                    return <span>@{record.username}</span>;
                },
            },
            {
                dataIndex: 'nickname',
                width: 200,
                title: <FormattedMessage id="p.uu.columns.nickname" />,
            },
            {
                dataIndex: 'bio',
                title: <FormattedMessage id="p.uu.columns.bio" />,
            },
            {
                dataIndex: ['stat_relation', 'inviter_id'],
                width: 200,
                title: <FormattedMessage id="p.uu.columns.parentUid" />,
            },
            {
                dataIndex: 'created_at',
                width: 200,
                title: <FormattedMessage id="p.uu.columns.registrationTime" />,
                valueType: 'dateTime',
            },
            {
                fixed: 'right',
                key: 'option',
                title: <FormattedMessage id="t.c.action" />,
                valueType: 'option',
                width: 200,
                render: (_, record) => [
                    <Permission permission="Users_Users_Details">
                        <a target="_blank" href={`/users/detail?uid=${record.id}`}>
                            <FormattedMessage id="b.details" />
                        </a>
                    </Permission>,
                ],
            },
        ],
        dataSource: state.dataSource,
        loading,
        pagination: false,
        options: {
            reload: refreshUserList,
        },
        rowKey: 'id',
        scroll: {
            x: 1470,
            y: `calc(100vh - 224px - ${searchRef.current?.clientHeight || 64}px - 16px - 48px - 16px)`,
        },
        search: false,
        tableAlertRender: false,
    };

    /**
     * Effects
     */
    useEffect(() => {
        runUserList({
            // page: pagination.current,
            size: pagination.size,
            search_after: pagination.searchAfter,
            ...payload,
        });
    }, [pagination.current, pagination.size, payload]);

    return (
        <>
            <ProCard className="mb-16" ref={searchRef}>
                <Form {...formSearchProps}>
                    <Flex gap={16} wrap={true}>
                        <Form.Item name="id" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={formatMessage({ id: 'p.uu.columns.uid' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>
                        <Form.Item name="username" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={formatMessage({ id: 'p.uu.columns.userName' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>
                        <Form.Item name="nickname" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={formatMessage({ id: 'p.uu.columns.nickname' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>
                        <Form.Item name="inviter_id" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={formatMessage({ id: 'p.uu.columns.parentUid' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>
                        <Form.Item
                            label={formatMessage({ id: 'p.uu.columns.registrationTime' })}
                            name="date"
                            style={{ marginRight: 0 }}
                        >
                            <DatePicker.RangePicker allowClear={true} />
                        </Form.Item>
                        <Button
                            loading={loading}
                            type="primary"
                            onClick={() => {
                                formSearch.submit();
                            }}
                        >
                            <FormattedMessage id="b.search" />
                        </Button>

                        <Button
                            onClick={() => {
                                formSearch.resetFields();
                            }}
                        >
                            <FormattedMessage id="b.reset" />
                        </Button>

                        <Permission permission="Users_Users_Export">
                            <Button
                                disabled={countdown !== 0}
                                onClick={async () => {
                                    const values = formSearch.getFieldsValue();
                                    if (values.date && values.date.length > 0) {
                                        values.created_start = values.date[0].startOf('d').format();
                                        values.created_end = values.date[1].endOf('d').format();
                                        delete values.date;
                                    }
                                    delete values.page;
                                    delete values.limit;
                                    const { data } = await userExport({
                                        ...omitBy(values, isEmpty),
                                    });
                                    if (data.code !== 0) {
                                        return message.error(data.msg);
                                    }
                                    message.success(formatMessage({ id: 'm.exportTips' }));

                                    setTarget(Date.now() + 1000 * 10);
                                }}
                            >
                                <FormattedMessage id="b.export" />
                                {countdown === 0 ? null : `(${Math.round(countdown / 1000)}s)`}
                            </Button>
                        </Permission>
                    </Flex>
                </Form>
            </ProCard>

            <ProTable {...proTableProps} />

            <Pagination {...pagination} isLoading={loading} />
        </>
    );
};

export default PageAccountManagement;
