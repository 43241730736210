import React, { useEffect, useRef, useState } from 'react';
import { Image } from 'antd';
import Player from 'xgplayer';
import HlsPlugin from 'xgplayer-hls';
// import Mp4Plugin from 'xgplayer-mp4';
import ShakaPlugin from 'xgplayer-shaka';

import 'xgplayer/dist/index.min.css';

/**
 * Constants
 */
const DEFAULT_COVER = '/video.png';
const PLAY_ICON_URL = '/play.png';

const CreatePlayer = (el: HTMLElement, url: string, type: videoType = 'hls') => {
    return new Player({
        el,
        autoplay: true,
        playsinline: true,
        url,
        plugins: type === 'mov' ? [] : type === 'hls' ? [HlsPlugin] : [ShakaPlugin],
        loop: false,
        fitVideoSize: 'auto' as any,
        videoFillMode: 'contain',
    });
};

/**
 * Types
 */
type videoType = 'hls' | 'mp4' | 'mov';
type Props = {
    src: string;
    cover?: string;
    width?: number;
    height?: number;
};

const VideoPlayer: React.FC<Props> = ({ src, width = 80, cover }) => {
    const videoRef = useRef<HTMLDivElement>(null);
    const videoPlayer = useRef<Player | undefined>(undefined);
    const [videoCoverUrl, setVideoCoverUrl] = useState<string>(DEFAULT_COVER);

    useEffect(() => {
        if (src && cover) {
            setVideoCoverUrl(cover);
            return;
        }
        // 创建 canvas 元素
        let canvas = document.createElement('canvas');
        // 创建 video 元素
        let video = document.createElement('video');
        // 播放图标
        let playImgIcon = document.createElement('img');

        video.src = src;
        video.crossOrigin = 'anonymous';
        video.preload = 'auto';

        // 监听 canplay 事件
        video.addEventListener('canplay', () => {
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            const ctx = canvas.getContext('2d');
            playImgIcon.src = PLAY_ICON_URL;
            playImgIcon.onload = () => {
                if (ctx) {
                    // 将视频的第一帧绘制到 canvas 上
                    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

                    // 播放宽度为视频宽度的30% 高度按比例缩放
                    const playIconWidth = canvas.width * 0.3;
                    const playIconHeight = (playImgIcon.height / playImgIcon.width) * playIconWidth;
                    // 绘制播放图标
                    ctx.drawImage(
                        playImgIcon,
                        (canvas.width - playIconWidth) / 2,
                        (canvas.height - playIconHeight) / 2,
                        playIconWidth,
                        playIconHeight,
                    );

                    const dataURL = canvas.toDataURL('image/jpeg');

                    setVideoCoverUrl(dataURL);
                }
                playImgIcon.onload = null;
                video.removeEventListener('canplay', () => {});
                video = undefined as any;
                canvas = undefined as any;
                playImgIcon = undefined as any;
            };
        });
        // 组件卸载时 清除副作用
        return () => {
            if (playImgIcon) {
                playImgIcon.onload = null;
            }
            if (video) {
                video.removeEventListener('canplay', () => {});
            }
            video = undefined as any;
            canvas = undefined as any;
            playImgIcon = undefined as any;
        };
    }, [src, cover]);

    return (
        <div
            style={{
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                width,
                height: (16 / 9) * width,
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Image
                src={videoCoverUrl}
                preview={{
                    destroyOnClose: true,
                    imageRender: () => (
                        <div
                            ref={videoRef}
                            style={{ objectFit: 'revert', maxHeight: '96vh', width: '80%', height: '100vh' }}
                        ></div>
                    ),
                    toolbarRender: () => null,
                    onVisibleChange: (show) => {
                        if (videoPlayer.current) {
                            videoPlayer.current.destroy();
                        }
                        if (!show) {
                            return;
                        }
                        setTimeout(() => {
                            let type: videoType = 'mp4';
                            if (src.indexOf('.m3u8') > 0) {
                                type = 'hls';
                            } else if (src.indexOf('.mov') > 0) {
                                type = 'mov';
                            }
                            videoPlayer.current = CreatePlayer(videoRef.current as HTMLElement, src, type);
                            videoPlayer.current.play();
                        }, 50);
                    },
                }}
                style={{
                    width,
                    height: 'auto',
                }}
                width={width}
            />
        </div>
    );
};

export default VideoPlayer;
