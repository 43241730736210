export default {
    im: {
        t: {
            c: {
                usersCovered: 'Users Covered',
                totalUsers: 'Total Users',
            },
        },
    },
};
