import axios from './index';

/**
 * APIs 渠道码
 */

// 获取渠道码列表
export const channelCode = (params: ChannelCodeParams) => {
    return axios<PageResType<ChannelCodeResult>>('/v1/channels', {
        method: 'get',
        params,
    });
};

// 添加渠道码列表
export const addChannelCode = (data: ChannelCodeBody) => {
    return axios<PageResType<ChannelCodeResult>>('/v1/channels', {
        method: 'post',
        data,
    });
};

// 编辑渠道码列表
export const editChannelCode = (id: IdType, data: ChannelCodeBody) => {
    return axios<PageResType<ChannelCodeResult>>(`/v1/channels/${id}`, {
        method: 'put',
        data,
    });
};

// 导出渠道码
export const exportChannelCode = (params: ChannelCodeParams) => {
    return axios<ResType<string[]>>(`/v1/channels/export`, {
        method: 'get',
        params,
    });
};

// 渠道类型
export const channelCodeType = () => {
    return axios<ResType<string[]>>(`/v1/channels/types`, {
        method: 'get',
        params: {
            language: 'en',
        },
    });
};

/**
 * Types
 */
import type { OperatorAccount, PageResType, ResType } from '@repo/typings/common';
import type { IdType } from './creator-academy';

export type ChannelCodeParams = {
    /**
     * 渠道码
     */
    channel?: string;
    /**
     * 名称
     */
    name?: string;
    /**
     * 状态
     */
    status?: string;
    /**
     * 类型
     */
    type?: string;

    page?: number;
    limit?: number;
};

export type ChannelCodeBody = {
    /**
     * 备注
     */
    comment?: string;
    /**
     * 名称
     */
    name: string;
    /**
     * 状态
     */
    status?: boolean;
    /**
     * 类型，DEFAULT, CHANNEL, AMBASSADOR, PROJECT, KOL
     */
    type?: string;

    updated_at?: string;

    fe_status?: boolean;
};

export type ChannelCodeResult = {
    channel_code: string;
    channel_id: string;
    comment: string;
    created_at: string;
    deleted_at: string;
    id: string;
    invite_count: number;
    link: string;
    name: string;
    operator_account?: OperatorAccount;
    status: boolean;
    type: string;
    updated_at: string;
};
